import axios from 'axios'

export const postLogin = async ({payload}) => {
  return await axios.post('api/login',payload)
}

export const postLogout = async () => {
  return await axios.post('api/logout')
}

export const postLogoutFromOtherLocations = async ({payload}) => {
  return await axios.post('api/logout-from-other-locations',payload)
}

export const getPing = async () => {  
  return await axios.get('api/ping')
}

export const getAdminPing = async () => {  
  return await axios.get('api/admin/ping')
}

export const postForgotPassword = async ({ email }) => {
  if(!email){
    throw new Error('No email specified in postForgotPassword endpoint' )
  }
  return await axios.post('api/forgot-password',{ email })
}

export const postResetPassword = async (payload) => {

  if(!payload.email){
    throw new Error('No email specified in postResetPassword endpoint' )
  }
  if(!payload.token){
    throw new Error('No token specified in postResetPassword endpoint' )
  }
  if(!payload.password){
    throw new Error('No password specified in postResetPassword endpoint' )
  }
  if(!payload.password_confirmation){
    throw new Error('No password_confirmation specified in postResetPassword endpoint' )
  }

  return await axios.post('api/reset-password',payload)
}

export const getProfile = async () => {  
  return await axios.get('api/profile')
}

export const putProfile = async (payload) => {  
  return await axios.put('api/profile', payload)
}

export const putPassword = async (payload) => {  
  return await axios.put('api/profile/password', payload)
}

export const getCompanies = async () => {  
  return await axios.get('api/companies')
}



export const getPlans = async () => {  
  return await axios.get('api/subscription/plans')
}

export const getSubscriptions = async () => {  
  return await axios.get('api/profile/subscription')
}

export const getUpdatePaymentMethod = async (subscription_id) => {  
  return await axios.get(`api/profile/${subscription_id}/payment_method`)
}

export const getAddons = async () => {  
  return await axios.get('api/profile/addons')
}

export const putSubscription = async (item_price_id, quantity) => {  
  return await axios.put('api/profile/subscription',{ item_price_id, quantity })
}

export const putSubscriptionConfirmation = async (hosted_page_id) => {  
  return await axios.put('api/profile/subscription_confirmation',{ hosted_page_id })
}

export const getImpersonate = async (id) => {  
  return await axios.get(`api/admin/companies/${id}/impersonate`)
}

export const getImpersonateProfile = async (id) => {  
  return await axios.get(`api/profile/companies/${id}/impersonate`)
}

export const getUsers = async (id) => {  
  return await axios.get(`api/profile/companies/${id}/users`)
}

export const enableUser = async (company_id, user_id) => {  
  return await axios.get(`api/profile/companies/${company_id}/users/${user_id}/enable`)
}

export const disableUser = async (company_id, user_id) => {  
  return await axios.get(`api/profile/companies/${company_id}/users/${user_id}/disable`)
}

export const getSubscription = async (company_id) => {  
  return await axios.get(`api/profile/companies/${company_id}/subscription`)
}

export const putCompany = async (company) => {  
  return await axios.put('api/profile/companies/'+company + '/default')
}

export const putCompanyNull = async (company) => {  
  return await axios.put('api/profile/companies/'+company + '/empty_default')
}


export const postUser = async (company_id, payload) => {  
  return await axios.post('api/profile/companies/' + company_id + '/user', payload)
}

export const getUser = async () => {
  return await axios.get('api/user')
}

export const postReactivate = async () => {  
  return await axios.post('api/profile/subscription/reactivate')
}

export const getQboConnect = async (company_id ) => {  
  return await axios.get('api/profile/companies/' + company_id + '/qbo_connect')
}

export const postQbo = async (payload ) => {
  payload.full=1
  return await axios.post('api/profile/qbo_save', payload)
}

export const getQboSync = async (company_id, po_export, po_import) => {  
  return await axios.get(`api/profile/companies/${company_id}/qbo_sync?full=1&po_export=${po_export}&po_import=${po_import}`)
}

export const getQboErrors = async (company_id ) => {  
  return await axios.get('api/profile/companies/' + company_id + '/qbo_errors')
}

export const getQboDisconnect = async (company_id ) => {  
  return await axios.get('api/profile/companies/' + company_id + '/qbo_disconnect?full=1')
}

export const postQboSyncOptions = async (company_id, payload ) => {
  payload.full=1
  return await axios.post('api/profile/companies/' + company_id + '/qbo_sync_options', payload)
}

export const getCompany = async (company_id ) => {  
  return await axios.get('api/profile/companies/' + company_id + '/refresh?full=1')
}

export const postQboItemOptions = async (company_id, payload ) => {
  payload.full=1
  return await axios.post('api/profile/companies/' + company_id + '/qbo_item_options', payload)
}

export const postQboInvoiceOptions = async (company_id, payload ) => {
  payload.full=1
  return await axios.post('api/profile/companies/' + company_id + '/qbo_invoice_options', payload)
}

export const postQboAccountOptions = async (company_id, payload ) => {
  payload.full=1
  return await axios.post('api/profile/companies/' + company_id + '/qbo_account_options', payload)
}

export const postGoogle2FA = async (google_2fa_code) => {
  return await axios.post('api/2fa', { google_2fa_code } )
}

export const getGoogle2FA = async (otp_code) => {
  return await axios.get('api/2fa?otp_code=' + otp_code )
}