/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import {  Transition } from '@headlessui/react'
import { dispatch, adminSubscriptionActions } from '@app/store'
import { useForm } from "react-hook-form";
import {  ActionButton, FormInput } from '@app/components/form';
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

export default function BindCompany({
  subscription,
  closeModal
}) {

    const {
      register,
      handleSubmit,    
      formState: { 
        errors,
        isValid
      },
      setValue,
      watch,
      reset
    } = useForm({ mode: 'onChange' });

    

    const onSubmit = ({ company_id }) => {            
      //dispatch(adminSubscriptionActions.sagaBind(subscription.id, company_id, closeModal));
    }

    const selectCompany=(company_id)=>{
      reset()
      setFilteredCompanies([])
      dispatch(adminSubscriptionActions.sagaBind(subscription.id, company_id, closeModal));
    }

    const location_text = watch('location_text', '')

    const { companies } = useSelector(state=> state.admin_subscription)
    

    const [filtered_companies, setFilteredCompanies] = useState([])

    useEffect(()=>{

      if(companies.length>0 && location_text && location_text.length>=3 && filtered_companies.length<=0){
        
        const _ = companies.filter(({id, name})=> 
              (id && String(id).toLowerCase().includes(location_text.toLowerCase())) 
          ||  (name && name.toLowerCase().includes(location_text.toLowerCase())) 
        )
        
        setFilteredCompanies(_)        

      }else if (filtered_companies.length>0){
        //setFilteredCompanies([])
      }

    },[filtered_companies, location_text])

    useEffect(()=>{
      if (filtered_companies.length>0){
        setFilteredCompanies([])
      }
    },[location_text])
    

  return (
    <Transition.Root show={ subscription ? true : false } as={Fragment}>
      
      <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 ">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">{subscription ? subscription.name : ''}</h3>
                    </div>


                    <FormInput
                        field="location_text"
                        errors={errors}
                        register={register}
                        required={true}
                        >
                        Location
                    </FormInput>
                    <div  className='mt-3 font-medium-- text-blue-700 cursor-pointer' >
                      {
                        filtered_companies.map(({id, name})=>{
                          return (<div className='cursor-pointer' onClick={()=>selectCompany(id)} key={id}>{name} 
                            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              {id}
                            </span>
                          </div>)
                        })
                      }
                    </div>
                    
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  {/*<ActionButton
                        type="submit"
                        isValid={isValid}
                        testid='continue-single-session'                        
                    >Update</ActionButton>*/}
                  
                  <button
                    type="button"
                    className="mt-3 mr-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeModal}
                    
                    data-testid='cancel-single-session'
                  >
                    Cancel
                  </button>

                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      
      </div>
    </Transition.Root>
  )
}
