import { useHistory, useLocation } from 'react-router'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const LeftNav = ({
  navigation=[]
})=>{

    const location = useLocation()

    const history = useHistory();
        const go = (where) =>{
        history.push(where)
    }

    return (
        <nav className="space-y-1">
          {
            navigation.map((item) => {

              const isCurrent = item.historyPush === location.pathname
              return (
                <div
                  key={item.name}
                  onClick={()=>go(item.historyPush)}
                  className={classNames(
                    isCurrent
                      ? 'bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white'
                      : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                    'group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      isCurrent
                        ? 'text-indigo-500 group-hover:text-indigo-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  <span className="truncate">{item.name}</span>
                </div>
              )

            })
          }
        </nav>
    )
}

export default LeftNav