import { call, put } from 'redux-saga/effects'
//import {  generalActions, authActions } from '@app/store'
import generalActions from '@app/store/general/actions'
import authActions from '@app/store/auth/actions'
import adminParametersActions from '@app/store/admin_parameters/actions'
import adminSubscriptionActions from '@app/store/admin_subscription/actions'
import registerActions from '@app/store/register/actions'
import subscriptionActions from '@app/store/subscription/actions'
import errorParser from '@app/util/laravel_error_parser';
import { postLogout } from './api'
import axios from 'axios'

export function* doLogout(){
  yield put(authActions.reset());
  yield put(generalActions.reset());
  yield put(adminParametersActions.reset());
  yield put(adminSubscriptionActions.reset());
  yield put(registerActions.reset());
  yield put(subscriptionActions.reset());
  axios.defaults.headers.common['Authorization'] = null;
  axios.defaults.headers.common['x-v2f'] = false;
}

function* logout(){
    yield put(generalActions.isLoading())
    try{      
        //console.debug('doLogout')
        yield call(postLogout)
        yield call(doLogout)
        yield put(generalActions.sagaGetConfig())
        window.sessionStorage.clear()
        window.localStorage.clear()
        
        //yield call(window.location.reload())
        
        
    }catch(err){      
      if(err.response?.status === 401){
        yield call(doLogout)
      }else{
        yield put(generalActions.setError(errorParser(err)))
      }      
    }    
}





export default logout;