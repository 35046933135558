const ProfileDropdownMobile = ({
    profile,
    config
})=>{
    return profile.map(({label, onClick}) => (
        <div
          key={`movil-${label}`}
          onClick={onClick}
          className={`block px-3 py-2 rounded-md text-base font-medium text-${config.theme}-logo-over hover:text-white hover:bg-gray-700 cursor-pointer`}
        >
          {label}
        </div>
      ))
}


export default ProfileDropdownMobile;