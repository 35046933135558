/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef } from 'react'
import {  Transition } from '@headlessui/react'

import { useSelector } from 'react-redux'
import { dispatch, adminSubscriptionActions } from '@app/store'

import { useForm } from "react-hook-form";
import {  ActionButton, FormSelect, FormInputPlus } from '@app/components/form';
import {  useConfig } from "@app/hooks";

export default function AllowedUsers() {

    const { current_plan_index, plans } = useSelector(state=>state.admin_subscription)
    const { error_message, success_message } = useSelector(state=>state.general)  
    const cancelButtonRef = useRef(null)
    const plan = plans.filter(plan=> plan.status==='active')[current_plan_index]
    const options = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,20,25,30,40,50]

    const {
      theme 
    } = useConfig()

    const {
      register,
      handleSubmit,    
      formState: { 
        errors,
        isValid
      },
      setValue,
      control
    } = useForm({ mode: 'onChange' });

    const closeModal = ()=>{  
      dispatch(adminSubscriptionActions.modalAllowedUsers(false))
    }

    const onSubmit = ({ allowed_users, allowed_companies, qbd, qbo, offer_individually, mandatory_plans }) => {      
      dispatch(adminSubscriptionActions.sagaAllowedUsers(allowed_users, allowed_companies, qbo, qbd));
      //console.debug('offer_individually', offer_individually)
      //console.debug('mandatory_plans', mandatory_plans)          
    }

    useEffect(() => {      
      if(success_message || current_plan_index===false){
        closeModal()
      }
    },[success_message, current_plan_index]);

    useEffect(() => {
      if(plan){
        setValue('allowed_users', plan.allowed_users)
        setValue('allowed_companies', plan.allowed_companies)
        setValue('qbo', plan.qbo)
        setValue('qbd', plan.qbd)
      }
    }, [plan, setValue]);
        

  return (
    <Transition.Root show={ current_plan_index!==false } as={Fragment}>
      
      <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 mb-5">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title"><span className="text-sm font-normal">Plan: </span> {plan ? plan.name : ''}</h3>
                    </div>

                    { theme ==='trxio' ? (
                      <>
                      <FormSelect
                        field="allowed_users"
                        errors={errors}
                        register={register}
                        required={true}
                        options={options}
                        >
                        Allowed users
                      </FormSelect>

                      <div className="mt-5"></div>

                      <FormSelect
                        field="allowed_companies"
                        errors={errors}
                        register={register}
                        required={true}
                        options={options}
                        >
                        Allowed network locations
                      </FormSelect>

                      <div className="mt-5"></div>

                      <FormInputPlus 
                          control={control}
                          input={{ key: 'qbo', type: 'toggle', label: 'QBO'}}
                          register={register}
                          setValue={setValue}
                      />

                      <div className="mt-5"></div>

                      <FormInputPlus 
                          control={control}
                          input={{ key: 'qbd', type: 'toggle', label: 'QBD'}}
                          register={register}
                          setValue={setValue}
                      />
                      </>
                    ) : null}

                    { theme ==='mortrack--' ? (
                      <>
                        <FormInputPlus 
                            control={control}
                            input={{ key: 'offer_individually', type: 'toggle', label: 'Offer individually'}}
                            register={register}
                            setValue={setValue}
                        />

                        <div className="mt-5"></div>

                        <FormSelect
                          field="mandatory_plans"
                          errors={errors}
                          control={control}
                          multiple={true}
                          required={true}
                          options={plans.map( ({id, name}) => {
                            return {
                               label: name,
                               value: id
                            }
                          })}
                          >
                          Mandatory Plans
                        </FormSelect>

                        <div className="mb-20"></div>
                        
                      </>
                    ) : null}

                    
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  {
                    !error_message ? (
                      <ActionButton
                        type="submit"
                        isValid={isValid}
                        testid='continue-single-session'                        
                      >Update</ActionButton>
                      
                    ) : null
                  }
                  
                  <button
                    type="button"
                    className="mt-3 mr-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeModal}
                    ref={cancelButtonRef}
                    data-testid='cancel-single-session'
                  >
                    Cancel
                  </button>

                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      
      </div>
    </Transition.Root>
  )
}
