
import { useForm } from "react-hook-form";
import { FormInput, ActionButton } from '@app/components/form';
import { useEffect } from "react";
import { dispatch, authActions } from '@app/store'


export default function Personal() {

  const setUserValues = ({
    first_name,
    last_name,
    email,
    phone
  }) => {    
    setValue('first_name', first_name)
    setValue('last_name', last_name)
    setValue('email', email)
    setValue('phone', phone)
  }

  const {
    register,
    handleSubmit,    
    formState: { 
      errors,
      isValid
    },
    setValue
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    dispatch(authActions.sagaGetProfile(setUserValues))
  }, []);


  const onSubmit = (payload) => {            
    dispatch(authActions.sagaPutProfile(payload));    
  }
  
  
  return (
    
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Personal Information</h3>
                <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can recieve mail.</p>
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <FormInput
                      field="first_name"
                      errors={errors}
                      register={register}
                      required={true}
                      >
                      First name
                  </FormInput>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <FormInput
                      field="last_name"
                      errors={errors}
                      register={register}
                      required={true}
                      >
                      Last name
                  </FormInput>
                </div>

              
                <div className="col-span-6 sm:col-span-3">
                  <FormInput
                      field="email"
                      errors={errors}
                      register={register}
                      required={true}
                      >
                      Email 
                  </FormInput>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <FormInput
                        field="phone"
                        errors={errors}
                        register={register}
                        required={false}
                        >
                        Phone number 
                    </FormInput>
                </div>
                
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <ActionButton
                    type="submit"
                    isValid={isValid}
                    testid="update-your-information"
                >
                    Update your information
                </ActionButton>
            </div>
          </div>
        </form> 
      
  )
}