import { dispatch, authActions } from '@app/store'

const Logout = () => {

    dispatch(authActions.sagaLogout())

    return <>
    </>

}

export default Logout