import types from './types'

const isLoading = () => {
    return {
        type: types.IS_LOADING,        
    }
}

const isNotLoading = (clear_success_message=false) => {
    return {
        type: types.IS_NOT_LOADING,
        clear_success_message
    }
}

const setError = (error_message) => {
    return {
        type: types.SET_ERROR,
        error_message
    }
}

const setSuccess = (success_message) => {
    return {
        type: types.SET_SUCCESS,
        success_message
    }
}

const setAlert = (alert_message) => {
    return {
        type: types.SET_ALERT,
        alert_message
    }
}

const reset = () => {
    return {
        type: types.RESET_GENERAL,        
    }
}

const cleanError = () => {
    return {
        type: types.CLEAN_ERROR,        
    }
}

const cleanSuccess = () => {
    return {
        type: types.CLEAN_SUCCESS,        
    }
}

const sagaGetConfig = () => {
    return {
        type: types.GENERAL_SAGAS_GET_CONFIG,        
    }
}

const setConfig = (config) => {
    return {
        type: types.GENERAL_SET_CONFIG,
        config
    }
}

const actions = {
    isLoading,
    isNotLoading,
    setError,
    setSuccess,
    setAlert,
    reset,
    cleanError,
    cleanSuccess,
    sagaGetConfig,
    setConfig
};

export default actions;