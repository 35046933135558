import axios from 'axios'

export const getPlans = async () => {
  return await axios.get('api/admin/subscription/plans')
}

export const putPlan = async (id, allowed_users, allowed_companies, qbd, qbo) => {
  return await axios.put('api/admin/subscription/plans/' + id,{ allowed_users, allowed_companies, qbd, qbo })
}

export const getSubscriptions = async () => {
  return await axios.get('api/admin/subscription')
}

export const putFreeInformation = async (param) => {
  return await axios.put('api/admin/subscription/free_information', param)
}

export const getCompanies = async () => {
  return await axios.get('api/admin/companies')
}

export const syncSubscription = async (id) => {
  return await axios.get(`api/admin/subscription/${id}/retrieve`)
}

export const postPause = async (id, payload) => {
  return await axios.post(`api/admin/subscription/${id}/pause`, payload)
}

export const postRemoveScheduledPause = async (id) => {
  return await axios.post(`api/admin/subscription/${id}/remove_scheduled_pause`)
}

export const postResume = async (id, payload) => {
  return await axios.post(`api/admin/subscription/${id}/resume`, payload)
}

export const postCancel = async (id, payload) => {
  return await axios.post(`api/admin/subscription/${id}/cancel`, payload)
}

export const postRemoveScheduledCancellation = async (id) => {
  return await axios.post(`api/admin/subscription/${id}/remove_scheduled_cancellation`)
}

export const postReactivate = async (id) => {
  return await axios.post(`api/admin/subscription/${id}/reactivate`)
}

export const postBind = async (id, company_id) => {
  return await axios.post(`api/admin/subscription/${id}/bind`, {company_id})
}

export const getLog = async () => {
  return await axios.get(`api/admin/log`)
}