/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from 'react'
import {  Transition } from '@headlessui/react'

import { dispatch, adminSubscriptionActions } from '@app/store'

import { useForm, Controller } from "react-hook-form";
import {  ActionButton, FormSelect } from '@app/components/form';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function SubscriptionCancel({
  open,
  close,
  subscription
}) {
  
    const cancelButtonRef = useRef(null)    
    const options = [
    {
      id: 'end_of_term',
      name: 'End of term'
    },
    {
      id: 'cancel_at',
      name: 'Specific date'
    }
  ]

    const {
      register,
      control,
      handleSubmit,    
      formState: { 
        errors,
        isValid
      },
      setValue,
      watch
    } = useForm({ mode: 'onChange' });

    const watchCancelOption = watch("cancel_option", false); // you can supply default value as second argument


    const closeModal = ()=>{  
      close()
    }

    const onSubmit = ({ cancel_option, cancel_at }) => {    
      const end_of_term = cancel_option === 'end_of_term' ? 'true' : 'false'

      dispatch(adminSubscriptionActions.sagaCancel(subscription.id, { end_of_term, cancel_at }, closeModal))
    }

  return (
    <Transition.Root show={ open} as={Fragment}>
      
      <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:max-w-4xl-- sm:w-full ">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4  ${watchCancelOption ==='cancel_at' ? 'h-96' : ''}`}>
                                    
                    

                  <FormSelect
                      field="cancel_option"
                      errors={errors}
                      register={register}
                      required={true}
                      options={options}
                      >
                      Cancel Option
                  </FormSelect>

                  {
                    watchCancelOption ==='cancel_at' ? (
                      <>
                      <label className="mt-5 block text-sm font-medium text-gray-700">
                        <span className="mr-1 text-red-600">*</span>
                        Cancel at
                      </label>
                      <Controller
                        control={control}
                        name="cancel_at"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <ReactDatePicker
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                          />
                        )}
                      />  

                      </>
                    ) : null
                  }
                    
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <ActionButton
                    type="submit"
                    isValid={isValid}
                    testid='continue-single-session'                        
                  >Cancel subscription</ActionButton>
                  
                  <button
                    type="button"
                    className="mt-3 mr-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeModal}
                    ref={cancelButtonRef}
                    data-testid='cancel-single-session'
                  >
                    Close
                  </button>

                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      
      </div>
    </Transition.Root>
  )
}
