import types from './types'

const INITIAL_STATE = {
    new_subscription: {},
}

const subscriptionReducer = (state = INITIAL_STATE, action) =>{    
    switch (action.type){
        
        case types.SUBSCRIPTION_RESET: { return INITIAL_STATE }
        default: return state

        
    }
}


export default subscriptionReducer;