import { call, put, select } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
//import { getImpersonate, getImpersonateProfile } from './api'
import errorParser from '@app/util/laravel_error_parser';
import { goToApplication } from '@app/util/functions'

const readFromStore = (state) => state.auth

function* sagaGetImpersonate({ id, from_profile }){
    yield put(generalActions.isLoading())
    try{              
      //const api_call = from_profile ? getImpersonateProfile : getImpersonate
      //const response = yield call(api_call, id);          
      const { session_id } = yield select(readFromStore)
      goToApplication(session_id, id)
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default sagaGetImpersonate;