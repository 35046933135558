import { call, put } from 'redux-saga/effects'
import { getAddons } from './api'
import authActions from '@app/store/auth/actions'

function* sagaGetAddons(){    
    try{              
        const response = yield call(getAddons);
        yield put(authActions.setAddons(response.data.data))
    }catch(err){
      
    }    
}

export default sagaGetAddons;