import NewCompanySteps from "./NewCompanySteps"

import { useSelector } from "react-redux";
import { Route, useParams } from 'react-router-dom'
import Step_01 from "@app/containers/register/Step_01"
import Step_02 from "@app/containers/register/Step_02"
import Step_04 from "@app/containers/register/Step_04"
import { useEffect } from 'react'
import { dispatch, registerActions } from '@app/store'
import Subscription from "./EditSubscription";

const NewCompany = ()=>{

    const { id } = useParams();
    const { subscriptions } = useSelector(state=> state.admin_subscription)
    const { subscription: user_subscription } = useSelector(state=> state.auth)

    let name
    let subscription = subscriptions.filter(e=> parseInt(e.id)===parseInt(id))[0]
    if(subscription) {
        name = subscription.name
    }else{
        name = user_subscription.name
    }

    useEffect(() => {
        dispatch(registerActions.getCountries())
        dispatch(registerActions.getUsaStates())
    },[]);

    return (
        <>        
            <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
                
                <div className="px-3 py-3 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{name}</h3>
                    <div className="text-xs mt-1">Create Network Location</div>
                </div>
                {
                    !user_subscription ? (
                        <NewCompanySteps
                            subscription_id={id}
                            user_subscription={user_subscription}
                        />
                    ) : null
                }

                

                <div className="max-w-7xl mx-auto py-8 px-4 sm:py-16 sm:px-6 lg:px-8 lg:flex lg:justify-center">
            
                    <Route  exact path="/panel/admin/subscription/*/new_company">
                        <Step_01 subscription_id={id} />
                    </Route>

                    <Route exact path="/panel/admin/subscription/*/new_company/step_2">
                        <Step_02 subscription_id={id} />
                    </Route>

                    <Route exact path="/panel/admin/subscription/*/new_company/step_3">
                        <Step_04 subscription_id={id} />
                    </Route>
                    

                    
                    <Route exact path="/panel/profile/new_company/confirmation">
                        <Step_04 user_subscription={user_subscription} />
                    </Route>
                    <Route exact path="/panel/profile/new_company">
                        <Step_02 user_subscription={user_subscription} />
                    </Route>
                    

                </div>
            </div>
        </>
      )
}

export default NewCompany