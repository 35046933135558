import { dispatch, authActions } from '@app/store'
import { useHistory } from "react-router"

const Missing = () => {

    const history= useHistory()
    history.push('/panel/profile')

    return <></>
}

export default Missing