import { RefreshIcon } from "@heroicons/react/outline"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useConfig } from "@app/hooks";

const ActionButton = ({    
    isValid=true,
    onClick,
    children,
    id,
    testid=false,
    type='button',
    className,
    classNameEnabled='',
    classNameDisabled='',
    classNameAdditional,
    asLink=false
})=>{

    const [isEnabled, setIsEnabled] = useState(isValid)
    const { is_loading } = useSelector(state => state.general)

    const { theme } = useConfig()

    const handleClick = (event)=>{
        event.preventDefault();
        if(!isEnabled) return;
        if(!asLink)setIsEnabled(false);
        onClick();
    }

    useEffect(()=>{
        if(is_loading===false){
            setIsEnabled(true)
        }else{
            setIsEnabled(false)
        }
    },[is_loading])

    useEffect(()=>{        
        setIsEnabled(isValid)
    },[isValid])

    

    let _props = {
        type: type,        
        disabled:  !isEnabled ,
        className:  className ? `${className} ${isEnabled ? classNameEnabled : classNameDisabled }` : `w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${isEnabled ? `bg-${theme}-link` : 'bg-gray-400'} text-base font-medium text-white hover:bg-trxio-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${theme}-link  sm:w-auto sm:text-sm ${classNameAdditional}`,
    }

    if(type==='button'){
        _props.onClick = handleClick
    }

    if(id){
        _props.id = id
    }    

    return (
        <button
            data-testid={testid ? testid : id}
            {..._props}
        >            
            {is_loading && <RefreshIcon className="h-4 mr-2 animate-spin" /> }            
            {children}
        </button>
    )

}


export default ActionButton