import { call, put } from 'redux-saga/effects'

import generalActions from '@app/store/general/actions'
import { postValidateUser } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* validateUser({payload, callback}){
    yield put(generalActions.cleanError())
    try{                    
      yield call(postValidateUser,payload)
      if(callback){
        callback()
      }
      
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }
}

export default validateUser;