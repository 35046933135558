import { useEffect } from 'react'
import { dispatch, generalActions } from '@app/store'

/**
 * Clean messages when component is clean up 
 */
const useCleanUpMessages = ()=>{    

    useEffect(()=>{
        return ()=> dispatch(generalActions.cleanError())
    },[]);    

}

export default useCleanUpMessages