import { takeLatest, all } from "redux-saga/effects";
import types from "../types";
import getCountries from "./getCountries";
import getUsaStates from "./getUsaStates";
import postValidateUser from "./postValidateUser";
import postValidateCompany from "./postValidateCompany";
import postConfirmSubscription from "./postConfirmSubscription";


//Watchers
function* watchGetList() {
    yield takeLatest(types.REGISTER_SAGA_GET_COUNTRIES, getCountries);
    yield takeLatest(types.REGISTER_SAGA_GET_USA_STATES, getUsaStates);
    yield takeLatest(types.REGISTER_SAGA_VALIDATE_USER, postValidateUser);
    yield takeLatest(types.REGISTER_SAGA_VALIDATE_COMPANY, postValidateCompany);
    yield takeLatest(types.REGISTER_SAGA_CONFIRM_SUBSCRIPTION, postConfirmSubscription);
}
  
  /**
   * Exporting all watchers
   */
export default function* rootSaga() {
    yield all([watchGetList()]);
}