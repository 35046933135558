import { dispatch, authActions } from '@app/store'

export function titleize(slug) {
    var words = slug.split("_");
    return words.map(function(word) {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    }).join(' ');
}


function getAppUrl(){
  let url
  switch(process.env.REACT_APP_ENV){
    default:
        url = process.env.REACT_APP_TRXIO_URL_LOCAL          
  }

  return url
}
export function goToApplication(session_id, company_id, globalUserID){

  let 
    url = getAppUrl(), 
    company_query='',
    global_query='';

  if(company_id) company_query = `&company_id=${company_id}`
  if(globalUserID) global_query = `&globalUserID=${globalUserID}`
  window.location.href = `${url}?session_id=${session_id}${company_query}${global_query}`
}

export function goToPermissions(user_id){

  let url = getAppUrl();
  
  window.location.href = `${url}/permissions/manage_users?globalUserID=${user_id}`
}

export function handleImpersonate(company_id, from_profile=false){
  dispatch(authActions.sagaImpersonateCompany(company_id, from_profile))
}