import { call, put } from 'redux-saga/effects'
import { putSubscription } from './api'
import generalActions from '@app/store/general/actions'

function* sagaPutSubscription({ callback, quantity, item_price_id}){
    yield put(generalActions.isLoading())
    try{              
        const response = yield call(putSubscription, item_price_id, quantity);
        //yield put(authActions.setSubscriptions(response.data.data))
        if(callback){
            callback(response.data)
        }
    }catch(err){
      
    }finally{
        yield put(generalActions.isNotLoading())
    }
}

export default sagaPutSubscription;