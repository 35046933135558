import { useSelector } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom'

const useRegisterSteps = (subscription_id) => {

    const location = useLocation();
    const history = useHistory();

    const {

        step_1_valid,
        step_2_valid,
        hosted_page_id

    } = useSelector(state=> state.register)
        
    switch(location.pathname){

        case '/register':
            if(hosted_page_id){
                history.push('/register/account')
            }
            break;

        case '/register/business':
            if(hosted_page_id){
                history.push('/register/account')
            }else if(!step_1_valid) {
                history.push('/register')
            }
            break;

        case '/register/billing':
            if(hosted_page_id){
                history.push('/register/account')
            }else if(!step_2_valid) {
                history.push('/register/business')
            }            
            break;
        
        case '/register/account':                
            if(!hosted_page_id) history.push('/register/billing')
            break;

        case `/panel/admin/subscription/${subscription_id}/new_company/step_2`:            
            if(!step_1_valid){
                history.push(`/panel/admin/subscription/${subscription_id}/new_company`)
            }
            break

        case `/panel/admin/subscription/${subscription_id}/new_company/step_3`:            
            if(!step_2_valid){
                history.push(`/panel/admin/subscription/${subscription_id}/new_company/step_2`)
            }
            break
        
        default:

    }

}

export default useRegisterSteps