/* This example requires Tailwind CSS v2.0+ */
import { useEffect } from 'react';
import { dispatch, authActions, adminSubscriptionActions } from '@app/store'
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ActionButton, FormSelect } from '@app/components/form';
import { useQuery, useConfig } from '@app/hooks';
import { useHistory } from 'react-router';
import SubscriptionStatus from '@app/components/util/SubscriptionStatus';

export default function CurrentPlans() {

  const query = useQuery();
  const history = useHistory();
  const {     
    theme 
  } = useConfig()

  useEffect(() => {
    
    dispatch(authActions.sagaGetAddons())

    const id = query.get('id')
    const state = query.get('state')
        
    if(id && state==='succeeded'){        
        dispatch(authActions.putSubscriptionConfirmation(id))
    }else{
      dispatch(authActions.getSubscriptions())
    }

  }, []);

  const {
    register,
    handleSubmit,    
    formState: { 
      errors,
      isValid,
    },
    setValue,
    watch
  } = useForm({ mode: 'onChange' });

  const { subscription, addons: _addons } = useSelector(state=> state.auth)

  const callback = (response) => {
    window.location.href = response.url
  }

  const handleUpdatePaymentMethod = ()=>{
    dispatch(authActions.paymentUpdateMethod(callback))
  }

  const onSubmit = ({ quantity=1, addon }) => {            
    dispatch(authActions.putSubscription(addon, quantity, callback));    
  }

  const handleUpdateQuantity=(item_price_id, quantity)=>{
    dispatch(authActions.putSubscription(item_price_id, quantity, callback));
  }

  const handleReactivate = () => {    
    dispatch(authActions.sagaReactivate())
  }  

  const quantities = []
  for (let i = 1; i < 11; i++) {
    quantities.push({id: i, name: i.toString()})
  }

  const selected_addon = watch('addon')
  const addon = _addons.find(e=> e.id===selected_addon)

  /* Show only addons different from currently selected */  
  const addons = _addons.map(e=>{
    return {
      ...e,
      name: `${e.name} - $${e.price} ${e.currency}` /* - ${e.allowed_users} user(s)*/
    }
  }).filter(e => {
    let found = false
    
    for (const _subscription of subscription.json_data) {          
        for (const {item_price_id} of _subscription.subscription_items) {          
          if(item_price_id===e.id) found=true
        }  
      }
    
    return !found
  })
  
  const is_valid_subscription = subscription.provider_status!=='cancelled' && subscription.provider_status!=='paused'


  return (

    <div className="bg-white shadow overflow-hidden sm:rounded-lg" >
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Subscription Information</h3>
        {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">Created on {subscription.created_at}</p>*/}
      </div>
      
          {subscription.json_data.map(({ id, status, trial_start, trial_end, subscription_items, meta_data, currency_code, name, created_at, item_tiers }) => (

                <div className="border-t border-gray-200 px-4 py-5 sm:p-0" key={`subscription-${id}`}>
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Plan ID</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{id} </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Created at</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{created_at}</dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Status</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex">
                      <SubscriptionStatus status={status} /> 
                      {subscription.provider_status==='cancelled' ? <ActionButton classNameAdditional="ml-5" onClick={handleReactivate}>Reactivate</ActionButton>  : null }
                    </dd>
                  </div>

                  {
                    status==='in_trial' ? (
                      <>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Trial start</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{trial_start}</dd>
                      </div>

                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Trial end</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{trial_end}</dd>
                      </div>                  
                      </>
                      
                    ) : null
                  }

                  { theme=== 'trxio' ? (
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Users detail</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {parseInt(subscription.subscription_users) + parseInt(subscription.free_users)} Allowed<br />
                          {subscription.free_to_use_users} Free to use</dd>
                    </div>
                  ) : null}
                  
                  { theme=== 'trxio' ? (
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Network Locations detail</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {parseInt(subscription.subscription_companies) + parseInt(subscription.free_companies)} Allowed<br />
                          {subscription.free_to_use_companies} Free to use <br />
                          {subscription.free_to_use_companies>0 && is_valid_subscription ? (
                            <div className="cursor-pointer text-blue-600" onClick={()=>history.push('/panel/profile/new_company')}>Create location</div>
                          ) : null}
                        </dd>
                    </div>
                  ) : null}
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Subscription Items</dt>
                    <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {
                      subscription_items.map(({ item_price_id, item_type, amount, quantity, unit_price })=>
                        <div className="border-b border-gray-200 px-4 py-5 mb-1" key={`item_price_id-${item_price_id}`}>
                          <div className="py-4 sm:py-1 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Name</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{item_price_id}</dd>
                          </div>
                          
                          <div className="py-1 sm:py-1 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Type</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{item_type}</dd>
                          </div>
                          {
                            quantity ? (
                              <div className="py-1 sm:py-1 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt className="text-sm font-medium text-gray-500">Quantity</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{quantity}</dd>
                              </div>
                            ) : null
                          }

                          {
                            unit_price && !amount ? (
                              <div className="py-1 sm:py-1 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt className="text-sm font-medium text-gray-500">{item_tiers ? 'Starting at' : 'Price per request'}</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{unit_price} {currency_code}</dd>
                              </div>
                            ) : (
                              <div className="py-1 sm:py-1 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dt className="text-sm font-medium text-gray-500">Amount</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{amount} {currency_code}</dd>
                              </div>
                            )
                            
                          }
                          
                          {
                            item_type ==='addon' && subscription.provider_status!=='cancelled' ? (
                              <div className="py-1 sm:py-1 sm:grid sm:grid-cols-3 sm:gap-4 mt-2">
                                <dt className="text-sm font-medium text-gray-500"></dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><ActionButton onClick={()=>handleUpdateQuantity(item_price_id, quantity)}>Update quantity</ActionButton></dd>
                              </div>
                            ) : null
                          }
                        </div>
                      
                      )
                    }
                    </div>
                  </div>
                  
                            
                  
                </dl>
                </div> 
            
          ))}
                   
          
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        
        <dl className="sm:divide-y sm:divide-gray-200">
        
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Payment Method</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><ActionButton onClick={handleUpdatePaymentMethod}>Update</ActionButton></dd>
          </div>
        
          

          {
            addons && addons.length >0 && is_valid_subscription ? (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Additional features</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <form onSubmit={handleSubmit(onSubmit)} className="flex">
                  <div className="grid grid-cols-7 gap-6">
                    { addon && addon.pricing_model==='per_unit' ? <div className="col-span-2">
                      <FormSelect
                        field="quantity"
                        errors={errors}
                        register={register}
                        required={true}
                        options={quantities}
                      >
                        Quantity
                      </FormSelect>
                    </div> : null}
                    <div className={`${addon && addon.pricing_model==='per_unit' ? 'col-span-4' : 'col-span-6'}`}>
                      <FormSelect
                        field="addon"
                        errors={errors}
                        register={register}
                        required={true}
                        options={addons}                      
                      >
                        Addon
                      </FormSelect>
                    </div>
                  
                    <div className="col-span-1">
                      <div>&nbsp;</div>
                      <ActionButton type="submit" isValid={isValid}>Add</ActionButton>
                    </div>
                  
                  </div>
                  </form>
                </dd>
              </div>
            ) : null
          }
          



        </dl>
      </div>
      
    </div>
  )
}
