import { FormInput, ActionButton } from "@app/components/form"
import { dispatch, authActions } from '@app/store'
import { useSelector } from "react-redux"
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

const Google2FA = () => {

    const history = useHistory()
    const { user } = useSelector(state=> state.auth)    

    const {
        register,
        handleSubmit,    
        formState: { 
          errors,
          isValid
        },
        watch,
        reset
      } = useForm({ mode: 'onChange' });

    const onComplete = () => {
        dispatch(authActions.sagaGoogle2FARegister(user.google_2fa_code))
    }

    const onSubmit = ({ code }) => {
        const callback = ()=>{
            history.push('/')
        }
        dispatch(authActions.sagaGoogle2Verify(code, callback))
    }

    return (
        <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6">
                    Google 2FA Authenticator
                </div>
                <div className="px-4 py-5 sm:p-6 text-gray-500 font-md text-center">
                { user.google_2fa_ask_otp ? (

                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex justify-center">

                                <div className="col-span-6 sm:col-span-3">
                                    <FormInput
                                        field="code"
                                        type="text"
                                        errors={errors}
                                        register={register}
                                        required={true}
                                        >
                                        Code
                                    </FormInput>
                                </div>

                                <div className="px-4 pt-5 bg-gray-50- text-right sm:px-6">
                                    <ActionButton
                                        type="submit"
                                        isValid={isValid}
                                        testid="update-your-password"
                                    >
                                        Login
                                    </ActionButton>
                                </div>

                            </div>
                            
                        </form>                    
                    </>

                    ) : (
                    <>
                    <div>Set up your two factor authentication by scanning the barcode below. Alternatively, you can use the code: </div>
                            <div className="mt-5">
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
                                {user.google_2fa_code ? user.google_2fa_code : 'Loading...'}
                                </span>
                            </div>

                            <div className="flex justify-center mt-5">
                                {
                                    user.google_2fa_qr ?
                                    <div dangerouslySetInnerHTML={{__html: user.google_2fa_qr.replace('<?xml version="1.0" encoding="UTF-8"?> ','')}} />
                                    
                                    : null
                                }
                                
                            </div>

                            <div className="mt-5 ">
                                You must set up your Google Authenticator app before clicking "Complete Registration" or else you will be unable to log-in.
                            </div>

                            <div className="flex justify-center mt-5">
                                {
                                    user.google_2fa_code && user.google_2fa_qr ? (
                                        <ActionButton
                                            onClick={onComplete}
                                            >Complete Registration</ActionButton>
                                    ) : null
                                }                    
                            </div>  
                    </>
                    )}
                </div>
            </div>
        
        
    )
}

export default Google2FA