import ForgotPasswordLayout from "@app/components/layout/ForgotPasswordLayout";
import { useParams } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { FormInput, ActionButton } from '@app/components/form';
import { Message } from '@app/components/util';
import { dispatch, authActions } from '@app/store'
import { useRedirectAfterSuccess } from '@app/hooks'

const ForgotPasswordSendLink = ({
  token
}) => {

  const params = useParams()
  useRedirectAfterSuccess()

  const {
    register,
    handleSubmit,    
    formState: { 
      errors,
      isValid
    },
    reset,
    watch
  } = useForm({ mode: 'onChange' });  

  const onSubmit = ({email, password, password_confirmation}) => {
    const payload = {
      email,
      password,
      password_confirmation,
      token: params.token ? params.token : token
    }
    const callback = ()=>{
      reset()
    }
    dispatch(authActions.sagaResetPassword(payload, callback));
    
  }

    return (
        <ForgotPasswordLayout title='Recover your access'>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>            
              <div>
                <FormInput
                    field="email"
                    errors={errors}
                    register={register}
                    required={true}
                    >
                    Email address
                </FormInput>
              </div>

              <div>
                <FormInput
                    field="password"
                    errors={errors}
                    register={register}
                    required={true}
                    >
                    New password
                </FormInput>
              </div>

              <div>
                <FormInput
                    field="password_confirmation"
                    errors={errors}
                    register={register}
                    required={true}
                    watch={watch}
                    >
                    Password confirmation
                </FormInput>
              </div>

              <div>
                <ActionButton
                    type="submit"
                    isValid={isValid}
                    testid="create-new-password"
                >
                    Update your password
                </ActionButton>
              </div>

              <Message />

            </form>
        </ForgotPasswordLayout>
    )
}

export default ForgotPasswordSendLink