import { takeLatest, all } from "redux-saga/effects";
import types from "../types";
import sagaGetConfig from "./sagaGetConfig";


//Watchers
function* watchGetList() {
    yield takeLatest(types.GENERAL_SAGAS_GET_CONFIG, sagaGetConfig);
    
}
  
  /**
   * Exporting all watchers
   */
export default function* rootSaga() {
    yield all([watchGetList()]);
}