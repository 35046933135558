
import { Route } from 'react-router-dom'
import LeftNav from '@app/components/layout/common/LeftNav'
import { CreditCardIcon, CogIcon, UserGroupIcon, HomeIcon, ViewListIcon } from '@heroicons/react/outline'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useHistory } from 'react-router'

import Parameters from '@app/containers/admin/Parameters'
import Plans from '@app/containers/admin/subscription/Plans'
import Customers from '@app/containers/admin/subscription/Customers'
import EditCompany from '@app/containers/common/EditCompany'
import NewCompany from '@app/containers/admin/subscription/NewCompany'
import EditSubscription from '@app/containers/admin/subscription/EditSubscription'
import Companies from '@app/containers/admin/subscription/Companies'
import Log from '@app/containers/admin/subscription/Log'
import { useConfig } from "@app/hooks";

export default function AdminLayout() {

  const history = useHistory();

  const {
    theme 
  } = useConfig()
  
  const navigation = [
    {
      name: 'Subscriptions',
      historyPush: '/panel/admin/subscription/customers',
      icon: UserGroupIcon
    },
    {
      name: 'Locations',
      historyPush: '/panel/admin/companies',
      icon: HomeIcon
    },
    {
      name: 'Plans',
      historyPush: '/panel/admin/subscription/plans',
      icon: CreditCardIcon
    },
    {
      name: 'Parameters',
      historyPush: '/panel/admin/parameters',
      icon: CogIcon
    },
    {
      name: 'SA Change Log',
      historyPush: '/panel/admin/log',
      icon: ViewListIcon
    }

  ]

  const { user } = useSelector(state=> state.auth)

  useEffect(()=>{
    if(!user.isa) history.push('/')
  },[user, history])

  return (
    <>
        <div className={`lg:grid lg:grid-cols-12 lg:gap-x-5 ${theme === 'trxio' ? 'bg-blue-100' : 'bg-yellow-50'} p-5 rounded-lg`}>
        <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
          <LeftNav navigation={navigation} />
        </aside>

      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">      
                    
        <Route path="/panel/admin/subscription/plans">
            <Plans />
        </Route>

        <Route path="/panel/admin/subscription/customers">
            <Customers />
        </Route>

        <Route path="/panel/admin/companies">
            <Companies />
        </Route>

        <Route path="/panel/admin/subscription/:id/edit">
            <EditSubscription />
        </Route>

        <Route path="/panel/admin/subscription/:id/:company_id/company">
            <EditCompany />
        </Route>

        <Route path="/panel/admin/subscription/:id/new_company">
            <NewCompany />
        </Route>

        <Route path="/panel/admin/parameters">
            <Parameters />
        </Route>

        <Route path="/panel/admin/log">
            <Log />
        </Route>


                    
      </div>
    </div>
    </>    
  )
}