const ADMIN_PARAMETERS_RESET = 'ADMIN_PARAMETERS_RESET'
const ADMIN_PARAMETERS_SAGA_GET = 'ADMIN_PARAMETERS_SAGA_GET'
const ADMIN_PARAMETERS_SET = 'ADMIN_PARAMETERS_SET'
const ADMIN_PARAMETERS_SAGA_UPDATE = 'ADMIN_PARAMETERS_SAGA_UPDATE'


const _default = {
    ADMIN_PARAMETERS_SAGA_GET,
    ADMIN_PARAMETERS_SET,
    ADMIN_PARAMETERS_SAGA_UPDATE,
    ADMIN_PARAMETERS_RESET
}

export default _default