import { dispatch, authActions } from '@app/store'

import { useCleanUpMessages, useRedirectAfterLogin } from '@app/hooks';
import { useForm } from "react-hook-form";

import Message from '@app/components/util/Message';
import ActionButton from '@app/components/form/ActionButton';
import FormInput from '@app/components/form/FormInput';
import SingleSession from '@app/components/modals/SingleSession';
import Link from '@app/components/util/Link';
import { useQuery, useConfig } from "@app/hooks";
import { useState } from 'react';
import { useSelector } from "react-redux";
import { Cairnstack } from '@app/App';

export default function Login() {

    const query = useQuery();
    const token = query.get('t')
    const session_id = query.get('s')
    const { 
      show_google_one_tap 
    } = useSelector(state=> state.auth)    
    
    const { 
      login_background_image,
      logo,
      theme 
    } = useConfig()

    const {
        register,
        handleSubmit,
        getValues,
        formState: { 
          errors,
          isValid
        }    
    } = useForm({ mode: 'onChange' });  
        
    useCleanUpMessages();
    useRedirectAfterLogin('/panel/profile/companies');

    const onSubmit = ({email, password}) => {      
      dispatch(authActions.sagaLogin({ email, password}));
    }; // your form submit function which will invoke after successful validation

    useState(()=>{
      if(token && session_id){
        dispatch(authActions.sagaGetUserWithToken(token, session_id));
      }
    },[token, session_id])  


    if(token && session_id) return <Cairnstack />

    return (
      <div className={`min-h-screen bg-${theme}-logo flex`}>
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-24 w-auto"
                src={process.env.PUBLIC_URL + `/${theme}.png`}
                alt="Cairnstack"
              />
              <h2 className={`mt-6 text-3xl font-extrabold text-${theme}-logo-over`}>Sign in to your account</h2>
              <div className={`mt-2 text-sm text-${theme}-logo-over`}>
                Or{' '}
                <Link endpoint="/register">start your 14-day free trial</Link>
              </div>
            </div>
  
            <div className="mt-8">
              <div>
                <div>
                  <p className={`text-sm font-medium text-${theme}-logo-over`}>Sign in with</p>
  
                  <div className="mt-1 grid grid-cols-3 gap-3">
                    <div>
                      <a
                        href={process.env.REACT_APP_REDIRECT_GOOGLE}
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with Google</span>
                        <svg className="w-5 h-5" viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                          <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                            <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
                            <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
                            <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
                            <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
                          </g>
                        </svg>
                        {/*<svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            fillRule="evenodd"
                            d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                            clipRule="evenodd"
                          />
                        </svg>*/} 
                      </a>
                    </div>
  
                    {/*<div>
                      <a
                        href="#"
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with Twitter</span>
                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </a>
                    </div>*/}
  
                    {/*<div>
                      <a
                        href="#"
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Sign in with GitHub</span>
                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            fillRule="evenodd"
                            d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>*/}
                  </div>
                </div>
  
                <div className="mt-6 relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Or continue with</span>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-6 space-y-6">
                
                  <div>
                    <FormInput
                        field="email"
                        errors={errors}
                        register={register}
                        required={true}
                        withTheme={true}
                        >
                        Email address
                    </FormInput>
                  </div>
  
                  <div className="space-y-1">
                    <FormInput 
                        field="password"
                        errors={errors}
                        register={register}
                        required={true}
                        withTheme={true}
                        >
                        Password
                    </FormInput>
                  </div>
  
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      
                    </div>
  
                    <div className="text-sm">
                      <Link endpoint="/forgot-password">Forgot your password?</Link>
                    </div>
                  </div>
  

                  <div>
                    <ActionButton
                        type="submit"
                        isValid={isValid}
                        testid="login"
                    >
                        Sign in
                    </ActionButton>
                  </div>
                  <Message />
                  <div class="div_eula text-sm" >
                    <div>Continuing beyond this login screen you are agreeing to the</div>
                    <div> terms and conditions of the EULA that can be accessed <a title="End User License Agreement" class="link" href="http://www.cairnstack.com/eula"><span class="cursor-pointer font-medium  text-trxio-link hover:text-trxio-link-over">here</span></a>.</div>
                  </div>
                
              </div>
              </form>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={login_background_image}
            alt=""
          />
        </div>

        <SingleSession
          email={getValues('email')}
          password={getValues('password')}
          />

        { process.env.REACT_APP_ONE_TAP_CLIENT_ID && show_google_one_tap ? (
          <div id="g_id_onload"
            data-client_id={process.env.REACT_APP_ONE_TAP_CLIENT_ID}
            data-login_uri={process.env.REACT_APP_ONE_TAP_LOGIN_URI}
          >
            </div>
        ) : null }
 

        
      </div>
    )
  }
  