import axios from 'axios'

export const getParameters = async () => {
  return await axios.get('api/admin/parameters')
}

export const putParameter = async (key, value) => {
  return await axios.put('api/admin/parameters/' + key,{ value })
}

