import { useHistory } from 'react-router'
import { Route } from 'react-router-dom'
import Steps from '@app/containers/register/Steps'
import Step_01 from '@app/containers/register/Step_01'
import Step_02 from '@app/containers/register/Step_02'
import Step_03 from '@app/containers/register/Step_03'
import Step_04 from '@app/containers/register/Step_04'
import { useEffect } from 'react'
import { dispatch, registerActions } from '@app/store'
import { useConfig } from "@app/hooks";


export default function RegisterLayout() {

    const history = useHistory()
    const goHome = ()=>{
        history.push('/')
    }

    const {
        theme 
    } = useConfig()

    useEffect(() => {
        dispatch(registerActions.getCountries())
        dispatch(registerActions.getUsaStates())
    },[]);

    

    return (
        <>
            <div className={`bg-${theme}-logo `}>
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between">
                
                <div className="max-w-xl">
                    <img
                        className="h-24 w-auto cursor-pointer"
                        src={process.env.PUBLIC_URL + `/${theme}.png`}
                        alt="Cairnstack"
                        onClick={goHome}
                    />
                {/*<h2 className="text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
                    Pricing Plans
                    </h2>*/}
                <p className={`mt-5 text-xl text-${theme}-logo-over`}>
                    Start building for free, then add a site plan to go live. Account plans unlock additional features.
                </p>
                </div>
                <div className="mt-10 w-full max-w-xs">
                    <button
                        type="button"
                        className={`inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-${theme}-link hover:bg-${theme}-link-over focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${theme}-link`}
                        onClick={goHome}
                    >
                        Login
                    </button>
                
                </div>
            </div>
            </div>
            <Steps theme={theme}/>

            <div className="max-w-7xl mx-auto py-8 px-4 sm:py-16 sm:px-6 lg:px-8 lg:flex lg:justify-center">
                <Route exact path="/register">
                    <Step_01 />
                </Route>

                <Route exact path="/register/business">
                    <Step_02 />
                </Route>

                <Route exact path="/register/billing">
                    <Step_03 />
                </Route>

                <Route exact path="/register/account">
                    <Step_04 />
                </Route>
            </div>
            
        </>
    )
}
