import { call, put } from 'redux-saga/effects'
//import {  generalActions, authActions } from '@app/store'
import generalActions from '@app/store/general/actions'
import authActions from '@app/store/auth/actions'
import errorParser from '@app/util/laravel_error_parser';
import { postLogoutFromOtherLocations } from './api'
import axios from 'axios'

function* logoutFromOtherLocations(action){
    yield put(generalActions.isLoading())
    try{      
        //console.debug('doLogout')
        const response = yield call(postLogoutFromOtherLocations, action),        
          {user, token, session_id} = response.data;          
        
          if(!user || !token || !session_id){
            console.error('Missing user', user, "Missing token", token, "Missing session_id", session_id)
            throw new Error('Missing user || token || session_id from postLogoutFromOtherLocations')
          }

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        yield put(authActions.setUser(user,token, session_id));
        yield put(generalActions.isNotLoading())
        yield put(authActions.withoutSingleSessionWarning())
        //console.log('doLogout response',response.data)
    }catch(err){         
      yield put(generalActions.setError(errorParser(err)))
    }
}

export default logoutFromOtherLocations;