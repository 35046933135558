import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import axios from 'axios'
let api_url
if(process.env.REACT_APP_ENV==='production'){
  api_url = process.env.REACT_APP_API_BACKEND_PRODUCTION
}else if(process.env.REACT_APP_ENV==='development'){
  api_url = process.env.REACT_APP_API_BACKEND_DEVELOPMENT
}else{
  api_url = process.env.REACT_APP_API_BACKEND_LOCAL
}
axios.defaults.baseURL          = api_url
console.debug('AXIOS baseURL',api_url)

ReactDOM.render(
  <React.StrictMode>    
      <App />    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
