import { call, put } from 'redux-saga/effects'
import { disableUser, getSubscription } from './api'
import authActions from '@app/store/auth/actions'
import generalActions from '@app/store/general/actions'
import errorParser from '@app/util/laravel_error_parser';

function* sagaDisableUser({ company_id, user_id }){    
    yield put(generalActions.isLoading())
    try{              
        const response = yield call(disableUser, company_id, user_id );
        yield put(authActions.putUser(response.data.data))        
        const response2 = yield call(getSubscription, company_id);
        yield put(authActions.setSubscription(response2.data.data))
    }catch(err){
        const parsed_message = errorParser(err)
        yield put(generalActions.setError(parsed_message))
    }finally{
        yield put(generalActions.isNotLoading())
    }
}

export default sagaDisableUser;