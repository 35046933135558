import { useEffect } from "react";
import { dispatch, authActions } from '@app/store'
import { useSelector } from "react-redux";
import CompaniesTable from "@app/containers/common/CompaniesTable";

export default function Company() {

  const { companies, user } = useSelector(state=> state.auth)
  const handleGetCompanies = ()=>{
    dispatch(authActions.sagaGetCompanies())
  }

  useEffect(() => {
    handleGetCompanies()
  }, []);

  return (
    <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            {user.subscription_status==='cancelled' ? 

            (<h2 className="mt-5 text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-500 sm:text-4xl">
            Subscription {user.subscription_status}
          </h2>) : 

            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <CompaniesTable
                companies={companies}
                show_chargebee={false}
                from_profile={true}
                set_default={true}
                callback_after_set_default={handleGetCompanies}
                />
            </div>

}
                
                  
                    
                
            </div>
        </div>
    </div>
  )  
}