const REGISTER_RESET = 'REGISTER_RESET'
const REGISTER_SAGA_GET_COUNTRIES = 'REGISTER_SAGA_GET_COUNTRIES'
const REGISTER_SET_COUNTRIES = 'REGISTER_SET_COUNTRIES'
const REGISTER_SAGA_GET_USA_STATES = 'REGISTER_SAGA_GET_USA_STATES'
const REGISTER_SET_USA_STATES = 'REGISTER_SET_USA_STATES'
const REGISTER_SET_USER = 'REGISTER_SET_USER'
const REGISTER_SET_COMPANY = 'REGISTER_SET_COMPANY'
const REGISTER_STEP_1_INVALID = 'REGISTER_STEP_1_INVALID'
const REGISTER_STEP_2_INVALID = 'REGISTER_STEP_2_INVALID'
const REGISTER_STEP_3_INVALID = 'REGISTER_STEP_3_INVALID'
const REGISTER_SAGA_VALIDATE_USER = 'REGISTER_SAGA_VALIDATE_USER'
const REGISTER_SAGA_VALIDATE_COMPANY = 'REGISTER_SAGA_VALIDATE_COMPANY'
const REGISTER_SAGA_CONFIRM_SUBSCRIPTION = 'REGISTER_SAGA_CONFIRM_SUBSCRIPTION'
const REGISTER_SET_HOSTED_PAGE = 'REGISTER_SET_HOSTED_PAGE'

const _default = {
    REGISTER_RESET,
    REGISTER_SAGA_GET_COUNTRIES,
    REGISTER_SET_COUNTRIES,
    REGISTER_SAGA_GET_USA_STATES,
    REGISTER_SET_USA_STATES,
    REGISTER_SET_USER,
    REGISTER_SET_COMPANY,
    REGISTER_STEP_1_INVALID,
    REGISTER_STEP_2_INVALID,
    REGISTER_STEP_3_INVALID,
    REGISTER_SAGA_VALIDATE_USER,
    REGISTER_SAGA_VALIDATE_COMPANY,
    REGISTER_SAGA_CONFIRM_SUBSCRIPTION,
    REGISTER_SET_HOSTED_PAGE
}

export default _default