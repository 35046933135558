const RESET_GENERAL = 'RESET_GENERAL'
const IS_LOADING        = 'IS_LOADING'
const IS_NOT_LOADING    = 'IS_NOT_LOADING'
const SET_ERROR         = 'SET_ERROR'
const SET_SUCCESS       = 'SET_SUCCESS'
const SET_ALERT         = 'SET_ALERT'
const CLEAN_ERROR       = 'CLEAN_ERROR'
const CLEAN_SUCCESS       = 'CLEAN_SUCCESS'
const GENERAL_SAGAS_GET_CONFIG       = 'GENERAL_SAGAS_GET_CONFIG'
const GENERAL_SET_CONFIG       = 'GENERAL_SET_CONFIG'

const _default = {
    IS_LOADING,
    IS_NOT_LOADING,
    SET_ERROR,
    SET_SUCCESS,
    SET_ALERT,
    RESET_GENERAL,
    CLEAN_ERROR,
    CLEAN_SUCCESS,
    GENERAL_SAGAS_GET_CONFIG,
    GENERAL_SET_CONFIG
}

export default _default