

const SubscriptionStatus = ({
    status
})=>{

    let statusClass
    switch(status){

        case 'cancelled':
            statusClass='bg-red-100 text-red-800'
            break;

        case 'active':
            statusClass='bg-green-100 text-green-800'
            break;

        default:
            statusClass='bg-yellow-100 text-yellow-800'
            
    }

    return (<span className={`capitalize inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${statusClass}`}>
        {status}
      </span>)

}

export default SubscriptionStatus