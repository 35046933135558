import { call, put } from 'redux-saga/effects'
import { postUser } from './api'

import generalActions from '@app/store/general/actions'
import errorParser from '@app/util/laravel_error_parser';

function* sagaGetUsers({ company_id, payload, success_callback, error_callback }){    
    yield put(generalActions.isLoading())
    try{              
        const response = yield call(postUser, company_id, payload);
        yield put(generalActions.setSuccess(response.data.message))
        if(success_callback) success_callback()
    }catch(err){
        const parsed_message = errorParser(err)
        yield put(generalActions.setError(parsed_message))
        if(error_callback) error_callback()
    }finally{
        yield put(generalActions.isNotLoading())
    }
}

export default sagaGetUsers;