const ADMIN_SUBSCRIPTION_SAGA_GET_PLANS = 'ADMIN_SUBSCRIPTION_SAGA_GET_PLANS'
const ADMIN_SUBSCRIPTION_SET_PLANS = 'ADMIN_SUBSCRIPTION_SET_PLANS'
const ADMIN_SUBSCRIPTION_RESET = 'ADMIN_SUBSCRIPTION_RESET'
const ADMIN_SUBSCRIPTION_MODAL_ALLOWED_USERS = 'ADMIN_SUBSCRIPTION_MODAL_ALLOWED_USERS'
const ADMIN_SUBSCRIPTION_SAGA_ALLOWED_USERS = 'ADMIN_SUBSCRIPTION_SAGA_ALLOWED_USERS'
const ADMIN_SUBSCRIPTION_UPDATE_ALLOWED_USERS = 'ADMIN_SUBSCRIPTION_UPDATE_ALLOWED_USERS'
const ADMIN_SUBSCRIPTION_SAGA_GET_SUBSCRIPTIONS = 'ADMIN_SUBSCRIPTION_SAGA_GET_SUBSCRIPTIONS'
const ADMIN_SUBSCRIPTION_SET_SUBSCRIPTIONS = 'ADMIN_SUBSCRIPTION_SET_SUBSCRIPTIONS'
const ADMIN_SUBSCRIPTION_SAGA_PUT_FREE_USERS = 'ADMIN_SUBSCRIPTION_SAGA_PUT_FREE_USERS'
const ADMIN_SUBSCRIPTION_SET_FREE_USERS = 'ADMIN_SUBSCRIPTION_SET_FREE_USERS'
const ADMIN_SUBSCRIPTION_SAGA_GET_COMPANIES = 'ADMIN_SUBSCRIPTION_SAGA_GET_COMPANIES'
const ADMIN_SUBSCRIPTION_SET_COMPANIES = 'ADMIN_SUBSCRIPTION_SET_COMPANIES'
const ADMIN_SUBSCRIPTION_SAGA_SYNC = 'ADMIN_SUBSCRIPTION_SAGA_SYNC'
const ADMIN_SUBSCRIPTION_UPDATE = 'ADMIN_SUBSCRIPTION_UPDATE'
const ADMIN_SUBSCRIPTION_SAGA_PAUSE = 'ADMIN_SUBSCRIPTION_SAGA_PAUSE'
const ADMIN_SUBSCRIPTION_SAGA_RESUME = 'ADMIN_SUBSCRIPTION_SAGA_RESUME'
const ADMIN_SUBSCRIPTION_SAGA_REMOVE_SCHEDULED_PAUSE = 'ADMIN_SUBSCRIPTION_SAGA_REMOVE_SCHEDULED_PAUSE'
const ADMIN_SUBSCRIPTION_SAGA_CANCEL = 'ADMIN_SUBSCRIPTION_SAGA_CANCEL'
const ADMIN_SUBSCRIPTION_SAGA_REMOVE_SCHEDULED_CANCELLATION = 'ADMIN_SUBSCRIPTION_SAGA_REMOVE_SCHEDULED_CANCELLATION'
const ADMIN_SUBSCRIPTION_SAGA_REACTIVATE = 'ADMIN_SUBSCRIPTION_SAGA_REACTIVATE'
const ADMIN_SUBSCRIPTION_SAGA_BIND = 'ADMIN_SUBSCRIPTION_SAGA_BIND'
const ADMIN_SAGA_GET_LOG = 'ADMIN_SAGA_GET_LOG'
const ADMIN_SET_LOG = 'ADMIN_SET_LOG'
const ADMIN_SUBSCRIPTION_UPDATE_COMPANY = 'ADMIN_SUBSCRIPTION_UPDATE_COMPANY'

const _default = {
    ADMIN_SUBSCRIPTION_SAGA_GET_PLANS,  
    ADMIN_SUBSCRIPTION_SET_PLANS,
    ADMIN_SUBSCRIPTION_RESET,
    ADMIN_SUBSCRIPTION_MODAL_ALLOWED_USERS,
    ADMIN_SUBSCRIPTION_SAGA_ALLOWED_USERS,
    ADMIN_SUBSCRIPTION_UPDATE_ALLOWED_USERS,
    ADMIN_SUBSCRIPTION_SAGA_GET_SUBSCRIPTIONS,
    ADMIN_SUBSCRIPTION_SET_SUBSCRIPTIONS,
    ADMIN_SUBSCRIPTION_SAGA_PUT_FREE_USERS,
    ADMIN_SUBSCRIPTION_SET_FREE_USERS,
    ADMIN_SUBSCRIPTION_SAGA_GET_COMPANIES,
    ADMIN_SUBSCRIPTION_SET_COMPANIES,
    ADMIN_SUBSCRIPTION_SAGA_SYNC,
    ADMIN_SUBSCRIPTION_UPDATE,
    ADMIN_SUBSCRIPTION_SAGA_PAUSE,
    ADMIN_SUBSCRIPTION_SAGA_RESUME,
    ADMIN_SUBSCRIPTION_SAGA_REMOVE_SCHEDULED_PAUSE,
    ADMIN_SUBSCRIPTION_SAGA_CANCEL,
    ADMIN_SUBSCRIPTION_SAGA_REMOVE_SCHEDULED_CANCELLATION,
    ADMIN_SUBSCRIPTION_SAGA_REACTIVATE,
    ADMIN_SUBSCRIPTION_SAGA_BIND,
    ADMIN_SAGA_GET_LOG,
    ADMIN_SET_LOG,
    ADMIN_SUBSCRIPTION_UPDATE_COMPANY
    
}

export default _default