import types from './types'

const setUser = (user, token, session_id) => {
    return {
        type: types.SET_USER,
        user,
        token,
        session_id
    }
}

const sagaLogin = (payload) => {
    return {
        type: types.SAGA_LOGIN,
        payload
    }
}

const sagaLogout = () => {
    return {
        type: types.SAGA_LOGOUT,
    }
}

const reset = () => {
    return {
        type: types.RESET,        
    }
}

const sagaResetLink = (email) => {
    return {
        type: types.SAGA_RESET_LINK,
        email
    }
}

const sagaPasswordSubmit = (email, code, password) => {
    return {
        type: types.SAGA_PASSWORD_SUBMIT,
        email,
        code,
        password
    }
}

const sagaSignup = (name, email, password) => {
    return {
        type: types.SAGA_SIGNUP,
        name,
        email,
        password
    }
}

const createUser = (user, userConfirmed, userSub) => {
    return {
        type: types.CREATE_USER,
        user,
        userConfirmed,
        userSub
    }
}

const withSingleSessionWarning = () => {
    return {
        type: types.WITH_SINGLE_SESSION_WARNING,        
    }
}

const withoutSingleSessionWarning = () => {
    return {
        type: types.WITHOUT_SINGLE_SESSION_WARNING
    }
}

const sagaLogoutFromOtherLocations = (email, password) => {    
    return {
        type: types.SAGA_LOGOUT_FROM_OTHER_LOCATIONS,
        payload: { email, password}        
    }
}

const sagaPing = () => {
    return {
        type: types.SAGA_PING,
    }
}

const sagaSendRecoverLink = (email, callback)=>{
    return {
        type: types.SAGA_SEND_RECOVER_LINK,
        email,
        callback
    }
}

const sagaResetPassword = (payload, callback)=>{
    return {
        type: types.SAGA_RESET_PASSWORD,
        payload,
        callback
    }
}

const sagaGetProfile = (callback)=>{
    return {
        type: types.SAGA_GET_PROFILE,        
        callback
    }
}

const sagaPutProfile = (payload)=>{
    return {
        type: types.SAGA_PUT_PROFILE,        
        payload
    }
}

const sagaPutPassword = (payload, callback)=>{
    return {
        type: types.SAGA_PUT_PASSWORD,        
        payload,
        callback
    }
}

const sagaGetCompanies = ()=>{
    return {
        type: types.SAGA_GET_COMPANIES
    }
}

const setCompanies = (companies)=>{
    return {
        type: types.SET_COMPANIES,
        companies
    }
}

const setCompanyDefault = (company_default)=>{
    return {
        type: types.SET_COMPANY_DEFAULT,
        company_default
    }
}

const sagaPutCompany = (company, callback)=>{
    return {
        type: types.SAGA_PUT_COMPANY,
        company,
        callback
    }
}
const sagaPutCompanyNull = (company, callback)=>{
    return {
        type: types.SAGA_PUT_COMPANY_NULL,
        company,
        callback
    }
}

const sagaAdminPing = (callback)=>{
    return {
        type: types.SAGA_ADMIN_PING,
        callback
    }
}

const sagaProfileGetPlans = () => {
    return {
        type: types.SAGA_PROFILE_GET_PLANS,
        
    }
}

const getSubscriptions = () => {
    return {
        type: types.SAGA_PROFILE_GET_SUBSCRIPTIONS,
        
    }
}

const setSubscription = (subscription) => {
    return {
        type: types.PROFILE_SET_SUBSCRIPTIONS,
        subscription
    }
}

const paymentUpdateMethod = (subscription_id, callback) => {
    return {
        type: types.SAGA_PROFILE_GET_PAYMENT_UPDATE_METHOD,
        subscription_id,
        callback        
    }
}

const sagaGetAddons = () => {
    return {
        type: types.SAGA_PROFILE_GET_ADDONS,                
    }
}

const setAddons = (addons) => {
    return {
        type: types.PROFILE_SET_ADDONS,
        addons
    }
}

const putSubscription = (item_price_id, quantity, callback) => {
    return {
        type: types.SAGA_PROFILE_PUT_SUBSCRIPTION,
        callback,
        item_price_id,
        quantity
    }
}

const putSubscriptionConfirmation = (id) => {
    return {
        type: types.SAGA_PROFILE_PUT_SUBSCRIPTION_CONFIRMATION,
        id
    }
}

const sagaImpersonateCompany = (id, from_profile) => {
    return {
        type: types.SAGA_IMPERSONATE_COMPANY,
        id,
        from_profile
    }
}

const getUsers = (company_id, callback) => {
    return {
        type: types.PROFILE_SAGA_GET_USERS,
        company_id,
        callback
    }
}
const setUsers = (users) => {
    return {
        type: types.PROFILE_SET_USERS,
        users
    }
}

const enableUser = (company_id, user_id) => {
    return {
        type: types.PROFILE_SAGA_ENABLE_USER,
        company_id, 
        user_id
    }
}

const disableUser = (company_id, user_id) => {
    return {
        type: types.PROFILE_SAGA_DISABLE_USER,
        company_id, 
        user_id
    }
}

const putUser = (user) => {
    return {
        type: types.PROFILE_PUT_USER,
        user
    }
}

const getSubscription = (company_id) => {
    return {
        type: types.PROFILE_SAGA_GET_SUBSCRIPTION,
        company_id
    }
}

const resetSubscription = () => {
    return {
        type: types.PROFILE_RESET_SUBSCRIPTION,        
    }
}

const sagaCreateUser = (company_id, payload, success_callback, error_callback) => {
    return {
        type: types.PROFILE_SAGA_CREATE_USER,
        company_id,
        payload,
        success_callback,
        error_callback
    }
}

const sagaGetUserWithToken = (token, session_id) => {
    return {
        type: types.PROFILE_SAGA_GET_USER_WITH_TOKEN,
        token,
        session_id
    }
}

const hideGoogleOneTap = () => {
    return {
        type: types.PROFILE_HIDE_GOOGLE_ONE_TAP,        
    }
}

const showGoogleOneTap = () => {
    return {
        type: types.PROFILE_SHOW_GOOGLE_ONE_TAP,        
    }
}

const sagaReactivate = () => {
    return {
        type: types.PROFILE_SAGA_REACTIVATE_SUBSCRIPTION,        
    }
}

const sagaQboConnect = (company_id) => {
    return {
        type: types.PROFILE_SAGA_QBO_REDIRECT_CONNECT,
        company_id
    }
}

const sagaQboSave = (code, state, realmId) => {
    return {
        type: types.PROFILE_SAGA_QBO_SAVE,
        code,
        state,
        realmId
    }
}

const updateCompany = (company) => {
    return {
        type: types.PROFILE_UPDATE_COMPANY,
        company
    }
}

const sagaQboSync = (company_id, po_export=false, po_import=false, callback) => {
    return {
        type: types.PROFILE_SAGA_QBO_SYNC,
        company_id,
        po_export,
        po_import,
        callback
    }
}

const sagaQboErrors = (company_id, callback) => {
    return {
        type: types.PROFILE_SAGA_QBO_ERRORS,
        company_id,
        callback
    }
}

const sagaQboDisconnect = (company_id) => {
    return {
        type: types.PROFILE_SAGA_QBO_DISCONNECT,
        company_id
    }
}

const sagaQboSyncOptions = (company_id,payload, callback) => {
    return {
        type: types.PROFILE_SAGA_QBO_UPDATE_SYNC_OPTIONS,
        company_id,
        callback,
        payload
    }
}

const sagaGetCompany = (company_id, callback, with_loading=true) => {
    return {
        type: types.PROFILE_SAGA_GET_COMPANY,
        company_id,
        callback,
        with_loading
    }
}

const sagaQboItemOptions = (company_id,payload, callback) => {
    return {
        type: types.PROFILE_SAGA_QBO_UPDATE_ITEM_OPTIONS,
        company_id,
        callback,
        payload
    }
}

const sagaQboInvoiceOptions = (company_id,payload, callback) => {
    return {
        type: types.PROFILE_SAGA_QBO_UPDATE_INVOICE_OPTIONS,
        company_id,
        callback,
        payload
    }
}

const sagaQboAccountOptions = (company_id,payload, callback) => {
    return {
        type: types.PROFILE_SAGA_QBO_UPDATE_ACCOUNT_OPTIONS,
        company_id,
        callback,
        payload
    }
}

const sagaGoogle2FARegister = (google_2fa_code) => {
    return {
        type: types.GOOGLE2FA_SAGA_POST,
        google_2fa_code
    }
}

const sagaGoogle2Verify = (otp_code, callback) => {
    return {
        type: types.GOOGLE2FA_SAGA_VERIFY,
        otp_code,
        callback
    }
}


const actions = {
    setUser,
    sagaLogin,
    sagaLogout,
    reset,
    sagaResetLink,
    sagaPasswordSubmit,
    sagaSignup,
    createUser,
    withSingleSessionWarning,
    withoutSingleSessionWarning,
    sagaLogoutFromOtherLocations,
    sagaPing,
    sagaSendRecoverLink,
    sagaResetPassword,
    sagaGetProfile,
    sagaPutProfile,
    sagaPutPassword,
    sagaGetCompanies,
    setCompanies,
    setCompanyDefault,
    sagaPutCompany,
    sagaPutCompanyNull,
    sagaAdminPing,
    sagaProfileGetPlans,
    getSubscriptions,
    setSubscription,
    paymentUpdateMethod,
    sagaGetAddons,
    setAddons,
    putSubscription,
    putSubscriptionConfirmation,
    sagaImpersonateCompany,
    getUsers,
    setUsers,
    enableUser,
    disableUser,
    putUser,
    getSubscription,
    resetSubscription,
    sagaCreateUser,
    sagaGetUserWithToken,
    hideGoogleOneTap,
    showGoogleOneTap,
    sagaReactivate,
    sagaQboConnect,
    sagaQboSave,
    updateCompany,
    sagaQboSync,
    sagaQboErrors,
    sagaQboDisconnect,
    sagaQboSyncOptions,
    sagaGetCompany,
    sagaQboItemOptions,
    sagaQboInvoiceOptions,
    sagaQboAccountOptions,
    sagaGoogle2FARegister,
    sagaGoogle2Verify
};

export default actions;