
import { Route } from 'react-router-dom'
import Personal from '@app/containers/profile/Personal'
import Password from '@app/containers/profile/Password'
import Company from '@app/containers/profile/Company'
import Google2FA from '@app/containers/profile/Google2FA'
import CurrentPlans from '@app/containers/profile/CurrentPlans'
import QuickbooksSave from '@app/containers/profile/QuickbooksSave'
import LeftNav from './common/LeftNav'
import EditCompany from '@app/containers/common/EditCompany'
import NewCompany from '@app/containers/admin/subscription/NewCompany'
import { useSelector } from "react-redux";
import { useQuery } from "@app/hooks";
import { dispatch, generalActions } from '@app/store'
import { useEffect } from 'react'

import { CreditCardIcon, KeyIcon, UserCircleIcon, UserGroupIcon } from '@heroicons/react/outline'

export default function ProfileLayout() {    
  const { user } = useSelector(state=> state.auth)
  const query = useQuery();
  const error = query.get('error')

  useEffect(()=>{
    if(error) dispatch(generalActions.setError(error))
  },[error])

  useEffect(()=>{
    if(user.subscription_status==='cancelled') dispatch(generalActions.setError('Your subscription has been cancelled. Plase review your billing details.'  ))
  },[user])

  const navigation = [
    {
      name: 'Personal',
      historyPush: '/panel/profile',
      icon: UserCircleIcon
    },
    {
      name: 'Password',
      historyPush: '/panel/profile/password',
      icon: KeyIcon
    },
    
    
  ]
  if(user /*&& !user.isa*/){
    navigation.push({
      name: 'My Network Locations',
      historyPush: '/panel/profile/companies',
      icon: UserGroupIcon
    })
  }

  /*if(user && user.iso){
    navigation.push({
      name: 'Plan & Billing',
      historyPush: '/panel/profile/plan',
      icon: CreditCardIcon
    })
  }*/

  return (
    <>
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5 bg-gray-100 p-5 rounded-lg">
      <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
        <LeftNav navigation={navigation} />
      </aside>

      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">      

       <Route exact path="/panel/profile/companies">
           <Company />
       </Route>

       <Route exact path="/panel/profile/password">
           <Password />
       </Route>

       <Route exact path="/panel/profile/plan">
           <CurrentPlans />
       </Route>

       <Route exact path="/panel/profile">
           <Personal />
       </Route>

       <Route exact path="/panel/profile/:company_id/company">
           <EditCompany />
       </Route>

       <Route path="/panel/profile/new_company">
            <NewCompany />
        </Route>

        <Route path="/panel/profile/quickbooks">
            <QuickbooksSave />
        </Route>

        <Route path="/panel/profile/2-factor-authentication">
          <Google2FA />
        </Route>
       

      </div>
    </div>
    </>    
  )
}