import { Fragment } from 'react'
import {  Menu, Transition } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const ProfileActions = ({
    profile,
    name,
    image
})=>{
  
  
    return (

        <Menu as="div" className="ml-3 relative">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="max-w-xs text-gray-800 bg-gray-400 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full"
                    src={image}
                    alt=""
                  />
                  <span className='ml-2 mr-2'>{name}</span>
                </Menu.Button>
                
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                
                <Menu.Items
                static
                className="text-left origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                {profile.map(({label, onClick}) => (
                    <Menu.Item key={label}>
                    {({ active }) => (
                        <div
                        onClick={onClick}
                        className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                        )}
                        >
                        {label}
                        </div>
                    )}
                    </Menu.Item>
                ))}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>


        
    )

}

export default ProfileActions