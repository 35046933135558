import { call, put } from 'redux-saga/effects'
import { putSubscriptionConfirmation } from './api'
import authActions from '@app/store/auth/actions'
import generalActions from '@app/store/general/actions'

function* sagaPutSubscriptionConfirmation({ id }){
    yield put(generalActions.isLoading())
    try{              
        yield call(putSubscriptionConfirmation, id);
        yield put(authActions.getSubscriptions())
        
    }catch(err){
      
    }finally{
        yield put(generalActions.isNotLoading())
    }
}

export default sagaPutSubscriptionConfirmation;