import { Fragment, useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import { useSelector } from 'react-redux';
import {dispatch, generalActions} from '@app/store'

export default function Notification() {
    const [show, setShow] = useState(false)

    const closeNotification = ()=>{        
        dispatch(generalActions.reset())
        setShow(false)
    }

    const { 
        error_message=false,
        success_message=false,        
    } = useSelector(state => state.general)

    useEffect(() => {
        if(success_message){
            setShow({
                title: 'Success!',
                icon: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
                message: success_message
            })
        }else if(error_message){
            setShow({
                title: 'Error!',
                icon: <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
                message: error_message
            })
        }else if(show){
          setShow(false)
        }
    }, [success_message, error_message]);
    
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show!==false}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {show!==false ? show.icon : null}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className={`text-sm font-medium ${error_message ? 'text-red-600' : 'text-gray-900'}`}>{show!==false ? show.title : null}</p>
                    <p className={`mt-1 text-sm ${error_message ? 'text-gray-700' : 'text-gray-500'} whitespace-pre-line`}>{show!==false ? show.message : null}</p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={closeNotification}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
    }