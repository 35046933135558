import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import authActions from '@app/store/auth/actions'
import { getQboConnect } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* sagaGetQboRedirect({company_id}){
    yield put(generalActions.isLoading())
    try{              
      const response = yield call(getQboConnect, company_id);
      window.location.href = response.data.url
      //console.debug('response', response)
      
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default sagaGetQboRedirect;