import { useHistory } from "react-router"
import { useForm } from "react-hook-form";
import { FormInput, ActionButton, FormSelect } from '@app/components/form';
import { useSelector } from "react-redux";
import { useRegisterSteps } from "@app/hooks";
import { dispatch, registerActions } from "@app/store";
import Notification from '@app/components/modals/Notification';

export default function Step_02({
    subscription_id,
    user_subscription
}) {

    useRegisterSteps(subscription_id)
    const history= useHistory()    
    const onSubmit = (payload) => {

        const callback = ()=>{
            dispatch(registerActions.setCompany(payload))
            if(user_subscription){
                history.push(`/panel/profile/new_company/confirmation`)
            } else if(subscription_id){
                history.push(`/panel/admin/subscription/${subscription_id}/new_company/step_3`)
            } else {
                history.push('/register/billing')
            }
            
        }

        dispatch(registerActions.validateCompany(payload, callback))
    }

    const { 
        countries, 
        usa_states,

        company_name,
        country,
        address_line_1,
        address_line_2,
        city,
        state,
        province,
        zip,
        company_email,
        company_phone,
        url,
        ein,
        gs1_prefix,
    } = useSelector(state=> state.register)

    const {
        register,
        handleSubmit,    
        formState: { 
          errors,
          isValid
        },
        setValue,
        watch
      } = useForm({ mode: 'onChange' });

      const countryWatch = watch('country')
            
      let show_state=false      
      if(countryWatch!==undefined){
        if(countryWatch ==='840') show_state=true
      }else{
        if(country ==='840') show_state=true
      }
      
                
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="md:w-6/12">

                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 ">
                        <FormInput
                            field="company_name"
                            errors={errors}
                            register={register}
                            required={true}
                            value={company_name}
                            setValue={setValue}
                            >
                            Company name
                        </FormInput>
                    </div>
                    

                    <div className="col-span-6 ">
                        <FormInput
                            field="address_line_1"
                            errors={errors}
                            register={register}
                            required={true}
                            value={address_line_1}
                            setValue={setValue}
                            >
                            Address Line 1
                        </FormInput>
                    </div>

                    <div className="col-span-6 ">
                        <FormInput
                            field="address_line_2"
                            errors={errors}
                            register={register}
                            required={false}
                            value={address_line_2}
                            setValue={setValue}
                            >
                            Address Line 2
                        </FormInput>
                    </div>

                    <div className="col-span-6 md:col-span-3 ">
                        <FormSelect
                            field="country"
                            errors={errors}
                            register={register}
                            required={true}
                            options={countries}
                            value={country}
                            setValue={setValue}
                            >
                            Country
                        </FormSelect>                        
                    </div>

                    <div className="col-span-6 md:col-span-3 ">
                        { show_state  ? (
                            <FormSelect
                                field="state"
                                errors={errors}
                                register={register}
                                required={true}
                                options={usa_states}
                                value={state}
                                setValue={setValue}
                                >
                                State
                            </FormSelect> 
                        ) : (
                            <FormInput
                                field="province"
                                errors={errors}
                                register={register}
                                required={false}
                                value={province}
                                setValue={setValue}
                                >
                                Province
                            </FormInput>
                        ) }                        
                    </div>

                    <div className="col-span-6 md:col-span-3 ">
                        <FormInput
                            field="city"
                            errors={errors}
                            register={register}
                            required={true}
                            value={city}
                            setValue={setValue}
                            >
                            City
                        </FormInput>
                    </div>

                    <div className="col-span-6 md:col-span-3 ">
                        <FormInput
                            field="zip"
                            errors={errors}
                            register={register}
                            required={true}
                            value={zip}
                            setValue={setValue}
                            >
                            Zip code
                        </FormInput>
                    </div>


                    <div className="col-span-6 ">
                        <FormInput
                            field="company_email"
                            errors={errors}
                            register={register}
                            required={false}
                            value={company_email}
                            setValue={setValue}
                            >
                            Email
                        </FormInput>
                    </div>

                    <div className="col-span-6 md:col-span-3 ">
                        <FormInput
                            field="company_phone"
                            errors={errors}
                            register={register}
                            required={false}
                            value={company_phone}
                            setValue={setValue}
                            >
                            Phone
                        </FormInput>
                    </div>

                    <div className="col-span-6 md:col-span-3 ">
                        <FormInput
                            field="url"
                            errors={errors}
                            register={register}
                            required={false}
                            value={url}
                            setValue={setValue}
                            >
                            Url
                        </FormInput>
                    </div>

                    <div className="col-span-6 md:col-span-3 ">
                        <FormInput
                            field="ein"
                            errors={errors}
                            register={register}
                            required={false}
                            value={ein}
                            setValue={setValue}
                            >
                            EIN
                        </FormInput>
                    </div>

                    <div className="col-span-6 md:col-span-3 ">
                        <FormInput
                            field="gs1_prefix"
                            errors={errors}
                            register={register}
                            required={false}
                            value={gs1_prefix}
                            setValue={setValue}
                            >
                            GS1 Prefix
                        </FormInput>
                    </div>


                    <div className="col-span-6 ">
                        <ActionButton
                            type="submit"
                            isValid={isValid}
                            testid="update-your-information"
                        >
                            Next
                        </ActionButton>
                    </div>
                </div>
            </form>
            <Notification />

        
        </>
    )
}