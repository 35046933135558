import { Toggle, FormSelect, Datepicker } from "."


const FormInputPlus = ({
    control,
    register,
    input,
    errors,
    setValue
})=>{

    let render=null
    const { key, value, label, type, options=[] } = input

    if(type==='toggle'){

        render = (
            <Toggle
              field={key}
              control={control}
              value={value}
              >
                {label}
            </Toggle>
        )
    }

    if(type==='select'){
        render = (
            <FormSelect
                field={key}
                errors={errors}
                register={register}
                required={true}
                options={options}
                value={value}
                setValue={setValue}
                >
                {label}
            </FormSelect>  
        )
    }

    if(type==='datepicker'){        

        render = (
            <Datepicker
              field={key}
              control={control}
              value={value}
              >
                {label}
            </Datepicker>
        )
    }

    return render
}

export default FormInputPlus