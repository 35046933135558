import { takeLatest, all } from "redux-saga/effects";
import types from "../types";
import createSubscription from "./createSubscription";


//Watchers
function* watchGetList() {
    yield takeLatest(types.SUBSCRIPTION_SAGA_CREATE, createSubscription);    
}
  
  /**
   * Exporting all watchers
   */
export default function* rootSaga() {
    yield all([watchGetList()]);
}