import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import adminSubscriptionActions from '@app/store/admin_subscription/actions'
import authActions from '@app/store/auth/actions'
import { getCompany } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* sagaGetCompany({company_id, callback, with_loading=true }){
    
  if(with_loading) yield put(generalActions.isLoading())

  try{              
    const response = yield call(getCompany, company_id);
    const company = response.data.company
    company.subscription = response.data.subscription ? response.data.subscription : false
    
    yield put(authActions.updateCompany(company)) //updating in the owner profile
    yield put(adminSubscriptionActions.updateCompany(company)) //updating if is a superadmin
    if(callback) callback(company)
    //yield put(generalActions.setSuccess('Quickbooks sync process is starting.'))
  }catch(err){      
    const parsed_message = errorParser(err)
    yield put(generalActions.setError(parsed_message))
  }finally{
    if(with_loading) yield put(generalActions.isNotLoading())
  }
}

export default sagaGetCompany;