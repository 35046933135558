import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios'

const VerifyGoogle2Fa = () => {

    const history = useHistory();
    const { user } = useSelector(state => state.auth)

    useEffect(()=>{      
      /*if(!user.google_2fa_ask_otp && !axios.defaults.headers.common['x-v2f']){
        axios.defaults.headers.common['x-v2f'] = true;
        history.push('/')        
      }else{*/
        history.push('/panel/profile/2-factor-authentication')        
      //}
      
    },[]);

    return null
}

export default VerifyGoogle2Fa