import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { goToApplication } from '@app/util/functions';

const useRedirectAfterLogin = (where='/')=>{

    const history = useHistory();
    const { user, session_id } = useSelector(state => state.auth)

    useEffect(()=>{      
        if(typeof user === 'object'){
          
          if(user.default_company_id && session_id && process.env.REACT_APP_REDIRECT_TO_TRXIO==='true'){
            goToApplication(session_id, user.default_company_id)
          }else if(user.isa===true){
            history.push('/panel/admin/companies')
          }else{
            history.push(where)
          }
          
        }
    },[user, history, where]);
}

export default useRedirectAfterLogin