
import { Loading } from "@app/components/util"
import { dispatch, adminSubscriptionActions } from '@app/store'
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { UserIcon, ExclamationIcon   } from '@heroicons/react/solid'
import AllowedUsers from "@app/components/modals/AllowedUsers";
import { useCheckIfAdmin, useConfig } from "@app/hooks";

const Plans = () => {

    const {
        theme 
    } = useConfig()

    useEffect(()=>{
        dispatch(adminSubscriptionActions.sagaGetPlans())
    },[])

    const { plans } = useSelector(state=> state.admin_subscription)

    const handleModal=(index) => {
        dispatch(adminSubscriptionActions.modalAllowedUsers(index))
    }

    useCheckIfAdmin();

    const content = (
        <>
            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Name
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    In Checkout
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Monthly Price
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Yearly Price
                                </th>

                                {theme === 'trxio' ? (
                                    <>
                                        <th
                                        scope="col"
                                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >                                    
                                            <Loading contentWhenFinish={(<>Users</>)} />
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >                                    
                                            <Loading contentWhenFinish={(<>Net Loc</>)} />
                                        </th>

                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >                                    
                                            <Loading contentWhenFinish={(<>QBO</>)} />
                                        </th>

                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >                                    
                                            <Loading contentWhenFinish={(<>QBD</>)} />
                                        </th>
                                    </>
                                ) : null}


                                { theme === 'mortrack' ? (
                                    <>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >                                    
                                            <Loading contentWhenFinish={(<>Metered</>)} />
                                        </th>
                                        
                                    </>
                                ) : null}
                                
                                
                            </tr>
                        </thead>
                        <tbody>
                            {plans.filter(plan=> plan.status==='active').map((item, itemIdx) => (
                            <tr key={item.id} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                
                                <td className="px-6 py-4 whitespace-nowrap- text-sm font-medium text-gray-900">
                                    {item.name}
                                    <div className="text-xs font-normal text-gray-700">
                                        {item.description}
                                    </div>

                                    <span className={`mt-3 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${item.status==='active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                        {item.type}
                                    </span>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap- text-sm text-gray-500">
                                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${item.meta.plan.enabled_for_checkout ? 'bg-yellow-100 text-yellow-800' : 'bg-gray-200 text-gray-800'}`}>
                                            {item.meta.plan.enabled_for_checkout ? 'Yes' :  'No'}
                                    </span>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500 text-right">
                                    { item.monthly_price ? item.monthly_price + ' ' + item.currency : null }
                                    { item.monthly_price_volume ? item.monthly_price_volume + ' ' + item.currency : null }
                                </td>

                                <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500 text-right">
                                    { item.yearly_price ? item.yearly_price + ' ' + item.currency : null }
                                    { item.yearly_price_volume ? item.yearly_price_volume + ' ' + item.currency : null }
                                </td>
                                
                                {theme === 'trxio' ? (
                                    <>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            type="button"
                                            className={`inline-flex items-center p-1 border border-transparent rounded -full- shadow-sm text-white ${item.allowed_users >0 ? 'bg-indigo-600' : 'bg-indigo-300'} hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                                            onClick={()=>handleModal(itemIdx)}
                                        >
                                            
                                            <UserIcon className="h-5 w-5" aria-hidden="true" />
                                            {item.allowed_users}
                                        </button>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            type="button"
                                            className={`inline-flex items-center p-1 border border-transparent rounded -full- shadow-sm text-white ${item.allowed_companies >0 ? 'bg-indigo-600' : 'bg-indigo-300'} hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                                            onClick={()=>handleModal(itemIdx)}
                                        >
                                            
                                            <UserIcon className="h-5 w-5" aria-hidden="true" />
                                            {item.allowed_companies}
                                        </button>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            type="button"
                                            className={`inline-flex items-center p-1 border border-transparent rounded -full- shadow-sm text-white ${item.qbo ? 'bg-green-600' : 'bg-gray-300'} hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                                            onClick={()=>handleModal(itemIdx)}
                                        >                                                                            
                                            {item.qbo ? 'Yes' : 'No'}
                                        </button>
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            type="button"
                                            className={`inline-flex items-center p-1 border border-transparent rounded -full- shadow-sm text-white ${item.qbd ? 'bg-green-600' : 'bg-gray-300'} hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                                            onClick={()=>handleModal(itemIdx)}
                                        >                                                                            
                                            {item.qbd ? 'Yes' : 'No'}
                                        </button>
                                    </td>
                                    </>
                                ) : null}


                                {theme === 'mortrack' ? (
                                    <>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium ${item.meta.plan.metered ? 'bg-yellow-100 text-yellow-800' : 'bg-gray-100 text-gray-800' } `}> 
                                        {item.meta.plan.metered ? 'Yes' : 'No'}
                                        </span>
                                        
                                    </td>
                                    </>

                                ) : null}
                                
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
            <AllowedUsers />
            
            { theme === 'mortrack' ? (
            <div className="bg-red-50 border-l-4 border-red-400 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                        Metered plans will be offered as secondary plan for customers. 
                    </p>
                    <p className="text-sm text-yellow-700">
                        Those plans can't be offered in the signup process. Can be added later in the user's profile.
                    </p>
                    <p className="text-sm text-yellow-700">
                        Metered plans are required in order to use MorTrack system.
                    </p>
                    </div>
                </div>
            </div>
            ) : null }
        </>    
    )

    return content

}

export default Plans