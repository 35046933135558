import { useEffect } from 'react'
//import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dispatch, authActions } from '@app/store';

const useCheckIfAdmin = (where='/')=>{

    const history = useHistory();
    //const { user } = useSelector(state => state.auth)

    useEffect(()=>{      
      //
      const callback = ()=>{
        history.push(where)
      }
      dispatch(authActions.sagaAdminPing(callback))
    },[history, where]);
}

export default useCheckIfAdmin