import { useForm } from "react-hook-form";
import { FormInput, ActionButton, FormSelect } from '@app/components/form';
import { useEffect } from "react";
import { dispatch, adminParametersActions } from '@app/store'
import { useSelector } from "react-redux";
import { titleize } from '@app/util/functions'
import { useCheckIfAdmin } from "@app/hooks";

const Parameters = () => {

    const {
        register,
        handleSubmit,    
        formState: { 
          errors,
          isValid
        },
        setValue
    } = useForm({ mode: 'onChange' });


    const onSubmit = (payload) => {
        console.debug('payload', payload)
        dispatch(adminParametersActions.sagaUpdate(payload));    
    }

    useEffect(()=>{
        dispatch(adminParametersActions.sagaGet())
    },[])

    const { parameters=[] } = useSelector(state=> state.admin_parameters)

    useCheckIfAdmin();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
              {/*<div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Personal Information</h3>
                <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can recieve mail.</p>
              </div>*/}

              <div className="grid grid-cols-6 gap-6">
                {
                  parameters.map( parameter => {

                    let input = <></>

                    switch(parameter.type){
                      case 'multiple':
                        input = <FormSelect
                        field={parameter.key}
                          errors={errors}
                          register={register}
                          required={true}
                          options={parameter.options}
                          value={parameter.value}
                          setValue={setValue}
                          >
                          { parameter.label ?? titleize(parameter.key)}
                        </FormSelect>
                        break;

                      default:
                        input = <FormInput
                          field={parameter.key}
                          errors={errors}
                          register={register}
                          required={true}
                          value={parameter.value}
                          setValue={setValue}
                          >
                            { parameter.label ?? titleize(parameter.key)}
                        </FormInput>
                    }
                    

                    return (
                      <div className="col-span-6 -sm:col-span-3" key={parameter.key}>
                        {input}
                      </div>
                    )
                    
                  })
                }                
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <ActionButton
                    type="submit"
                    isValid={isValid}
                    testid="update-your-information"
                >
                    Update your information
                </ActionButton>
            </div>
          </div>
        </form> 
    )

}

export default Parameters