
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { handleImpersonate } from '@app/util/functions'
import UsersTable from "./UsersTable";
import { useEffect, useState } from "react";
import { dispatch, authActions } from "@app/store";
import { ActionButton } from "@app/components/form";
import UsersForm from "@app/components/modals/UsersForm";
import ModalQboErrors from "@app/components/modals/ModalQboErrors";
import ModalQboOptions from "@app/components/modals/ModalQboOptions";
import ModalQboPurchaseOrder from "@app/components/modals/ModalQboPurchaseOrder";
import { useHistory } from 'react-router';
import { useConfig } from "@app/hooks";
import SubscriptionTable from "@app/components/subscription/SubscriptionTable";

const Company = () => {
  
  const { company_id } = useParams();
  const { companies: admin_companies } = useSelector(state=> state.admin_subscription)
  let { user, companies: user_companies, users, session_id, subscription } = useSelector(state=> state.auth)  
  const [modalUser, setModalUser] = useState(false)
  const [modalErrors, setModalErrors] = useState(false)
  const [modalSyncOptions, setModalSyncOptions] = useState(false)
  const [modalItemOptions, setModalItemOptions] = useState(false)
  const [modalInvoiceOptions, setModalInvoiceOptions] = useState(false)
  const [modalAccountOptions, setModalAccountOptions] = useState(false)
  const [modalPOOptions, setModalPOOptions] = useState(false)
  const history=useHistory()  
  const { auth_s, theme } = useConfig()

  let company
  if(user.isa /*&& subscription.id*/){
    company = admin_companies.filter(e => parseInt(e.id) === parseInt(company_id))[0]  
  }else{
    company = user_companies.filter(e => parseInt(e.id) === parseInt(company_id))[0]    
  }
  if(company.subscription) subscription = company.subscription

  const has_valid_subscription = company && user && (company.has_valid_subscription || user.isa)

  useEffect(()=>{
    if(user.gid!==3) history.push('/panel/profile/companies')
  },[user])

  useEffect(()=>{
    dispatch(authActions.getUsers(company_id))
    if(auth_s) dispatch(authActions.getSubscription(company_id))
    dispatch(authActions.sagaGetCompany(company_id))

    const timer = setInterval(() => {
      if(company /*&& company.has_qbo*/) dispatch(authActions.sagaGetCompany(company_id, false, false))      
    }, 7000);
    return () => clearInterval(timer);

  },[company_id, auth_s])

  const success_callback_after_create_user = ()=>{
    dispatch(authActions.getUsers(company_id))
    dispatch(authActions.getSubscription(company_id))
  }
  

  const handleConnectQbo = () => {
    dispatch(authActions.sagaQboConnect(company_id))
  }

  const handleQboSync = ()=>{
    dispatch(authActions.sagaQboSync(company_id))
  }

  const handleQboDisconnect = ()=>{
    dispatch(authActions.sagaQboDisconnect(company_id))
  }

  const handleSaveSyncOptions = (payload)=>{
    dispatch(authActions.sagaQboSyncOptions(company.id, payload, ()=>{
      setModalSyncOptions(false)
    }));      
  }

  const handleSaveItemOptions = (payload)=>{
    dispatch(authActions.sagaQboItemOptions(company.id, payload, ()=>{
      setModalItemOptions(false)
    }));      
  }

  const handleSaveInvoiceOptions = (payload)=>{
    dispatch(authActions.sagaQboInvoiceOptions(company.id, payload, ()=>{
      setModalInvoiceOptions(false)
    }));      
  }

  const handleSaveAccountOptions = (payload)=>{
    dispatch(authActions.sagaQboAccountOptions(company.id, payload, ()=>{
      setModalAccountOptions(false)
    }));      
  }
  
    
    return (

      <>
        {subscription ? (
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">              
              <SubscriptionTable subscription={subscription} />
            </div>
          </div>
        ): null}

        <div className="shadow sm:rounded-md sm:overflow-hidden">

          
          

          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          
          

      <div className="mt-5 border-t border-gray-200">
        <dl className="divide-y divide-gray-200">

          
          
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Network location name</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{company?.name} (ID: {company?.id})</span>
              
            </dd>
          </div>

          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Network location licence</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{company?.license}</span>              
            </dd>
          </div>


          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">{theme==='trxio' ? "User's Detail" : ''} </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">

                {theme==='trxio' ? (

                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">                                    
                    <div className="ml-4 flex-shrink-0 flex space-x-4">                  
                      <div className="">
                        <dt className="text-sm font-medium text-gray-500">Subscription</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-center">
                          <span className="flex-grow">{subscription.subscription_users}</span>              
                        </dd>
                      </div>

                      <div className="">
                        <dt className="text-sm font-medium text-gray-500">Additional</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-center">
                          <span className="flex-grow">{subscription.free_users}</span>              
                        </dd>
                      </div>

                      <div className="">
                        <dt className="text-sm font-medium text-gray-500">Active here</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-center">
                          <span className="flex-grow">{users.filter(u=>u.active===1).length}</span>              
                        </dd>
                      </div>

                      <div className="">
                        <dt className="text-sm font-medium text-gray-500">Active Total</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-center">
                          <span className="flex-grow">{subscription.total_active_users}</span>              
                        </dd>
                      </div>
                      
                      <div className="">
                        <dt className="text-sm font-medium text-gray-500">Free to use</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-center">
                          <span className="flex-grow">{subscription.free_to_use_users}</span>              
                        </dd>
                      </div>                                    
                    </div>
                  </li>

                ) : null}
                

                {
                  has_valid_subscription ? (
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      
                      <div className="ml-4 flex-shrink-0 flex space-x-4">
                      <div className="">
                        <ActionButton
                          onClick={()=>handleImpersonate(company_id)}
                        >
                        Login
                        </ActionButton>
                        
                        <ActionButton
                          onClick={()=>setModalUser(company_id)}
                          asLink={true}
                          classNameAdditional='ml-2'
                        >
                        Add user
                        </ActionButton>
                        
                      </div>
                      </div>
                    </li>
                  ) : null
                }
                

                
              </ul>
            </dd>
          </div>
          

                     
        </dl>
      </div>

      
      </div>

      
      </div>

      <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-3 px-4 space-y-6 sm:p-6">

          <UsersTable
            users={users}
            company_id={company_id}
            session_id={session_id}
            has_valid_subscription={has_valid_subscription}
          />
          <UsersForm
            open={modalUser}
            onClose={()=>setModalUser(false)}
            company_id={company_id}
            successCallback={success_callback_after_create_user}
          />
          </div>
      </div>


      {
        company && company.can_qbo && !company.has_qbo && has_valid_subscription ? (
          <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white py-3 px-4 space-y-6 sm:p-6">
                <img 
                  className="w-full lg:w-2/6 cursor-pointer" 
                  src={process.env.PUBLIC_URL + `/C2QB_green_btn_tall_default.svg`}
                  onMouseOver={(e)=> e.target.src=process.env.PUBLIC_URL + `/C2QB_green_btn_tall_hover.svg`}
                  onMouseOut={(e)=> e.target.src=process.env.PUBLIC_URL + `/C2QB_green_btn_tall_default.svg`}
                  onClick={handleConnectQbo}
                  />
              </div>
          </div>
        ) : null
      }

{
        company && company.can_qbo && company.has_qbo && has_valid_subscription ? (
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">QuickBooks Online Settings</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Queue Data Sync Now</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {
                company.is_qbo_sync_running ? (
                  <div className="font-medium text-yellow-600">Sync process is running</div>
                ) : (
                  <ActionButton
                    onClick={handleQboSync}                    
                  >
                  Run
                  </ActionButton>
                )
              }
              
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Errors</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ActionButton
                onClick={()=>setModalErrors(true)}
              >
                View Errors
              </ActionButton>
              <ModalQboErrors
                open={modalErrors}
                setOpen={setModalErrors}
                company_id={company_id}
                />
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Sync Options</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ActionButton
                onClick={()=>setModalSyncOptions(true)}
                asLink={true}
              >
                Edit
              </ActionButton>
            
            <ModalQboOptions 
              open={modalSyncOptions}
              closeModal={()=>setModalSyncOptions(false)}
              options={company.qbo_sync_options}
              title='Quickbooks Sync options'
              submit={handleSaveSyncOptions}
            />
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Item Options</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ActionButton
                onClick={()=>setModalItemOptions(true)}
                asLink={true}
              >
                Edit
              </ActionButton>
            
            <ModalQboOptions 
              open={modalItemOptions}
              closeModal={()=>setModalItemOptions(false)}
              company={company}
              options={company.qbo_item_options}
              title='Quickbooks Item options'
              submit={handleSaveItemOptions}
            />
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Invoice & Estimate Options	</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

              <ActionButton
                onClick={()=>setModalInvoiceOptions(true)}
                asLink={true}
              >
                Edit
              </ActionButton>
            
            <ModalQboOptions 
              open={modalInvoiceOptions}
              closeModal={()=>setModalInvoiceOptions(false)}
              company={company}
              options={company.qbo_invoice_options}
              title='Quickbooks Invoice options'
              submit={handleSaveInvoiceOptions}
              footer={<div className="mt-32"></div>}
            />
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">QuickBooks Item Accounts	</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ActionButton
                onClick={()=>setModalAccountOptions(true)}
                asLink={true}
              >
                Edit
              </ActionButton>
            
            <ModalQboOptions 
              open={modalAccountOptions}
              closeModal={()=>setModalAccountOptions(false)}
              company={company}
              options={company.qbo_account_options}
              title='Quickbooks Item Account'
              submit={handleSaveAccountOptions}
              
            />
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Purchase Order Sync	</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ActionButton
                onClick={()=>setModalPOOptions(true)}
                asLink={true}
              >
                Edit
              </ActionButton>
            
            <ModalQboPurchaseOrder
              company={company}
              open={modalPOOptions}
              closeModal={()=>setModalPOOptions(false)}
            />
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">If you disconnect from QuickBooks Online, you will NOT be able to sync data to or from TRXiO.	</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <ActionButton
              onClick={handleQboDisconnect}
              className="font-medium bg-red-600 text-white hover:text-red-300 full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2"
              
            >
              Disconnect
            </ActionButton>
            
            </dd>
          </div>
        </dl>
      </div>
    </div>
        ) : null
      }
        
      </>
    )
}

export default Company