
import { Provider as ReduxProvider } from "react-redux";
import Routes from "@app/Routes";
import store, { persistor, dispatch, generalActions } from "@app/store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router} from 'react-router-dom';
import { useConfig } from "@app/hooks";
import { useState } from "react";

export const Cairnstack = ()=>{
  return  (
    <div className="flex h-screen">

      <div className="border border-blue-300 shadow rounded-md p-4 max-w-lg w-full m-auto">
            <div className="animate-pulse flex space-x-4">

              <div>
                <img src="https://image4.owler.com/logo/cairnstack_owler_20160302_230959_original.png" />
              </div>
                  
            </div>
      </div>
    </div>    
  )
}

const Preloader = ({
  children
})=>{

  const { 
    is_ready
  } = useConfig()

  useState(()=>{
    dispatch(generalActions.sagaGetConfig());
  })
  
  if(is_ready) return children;

  return <Cairnstack />
}

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Preloader>
            <Routes />
          </Preloader>
        </PersistGate>
      </ReduxProvider>
    </Router>
  );
}

export default App;
