import { call, put } from 'redux-saga/effects'
import { getSubscriptions } from './api'
import authActions from '@app/store/auth/actions'

function* sagaGetSubscriptions(){    
    try{              
        const response = yield call(getSubscriptions);
        yield put(authActions.setSubscription(response.data.data))
    }catch(err){
      
    }    
}

export default sagaGetSubscriptions;