import { call, put } from 'redux-saga/effects'
import registerActions from '../actions'
import generalActions from '@app/store/general/actions'
import { getCountries } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* sagaGetCountries(){    
    try{                    
      const response = yield call(getCountries)
      yield put(registerActions.setCountries(response.data.data))                  
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }
}

export default sagaGetCountries;