import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import authActions from '@app/store/auth/actions'
import { getProfile } from './api'
import errorParser from '@app/util/laravel_error_parser';
import { doLogout } from './sagaLogout'
function* readProfile({callback}){
    yield put(generalActions.isLoading())
    try{              
      const response = yield call(getProfile);
      //yield put(generalActions.setSuccess(response.data.message))
      yield put(authActions.setUser(response.data.data))
      if(callback){        
        callback(response.data.data)
      }
    }catch(err){      

      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
      
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default readProfile;