import { useRegisterSteps } from "@app/hooks";
import ModalMessage from "@app/components/modals/ModalMessage";
import { useEffect, useState } from "react";
import { dispatch, registerActions, generalActions} from "@app/store";
import { useHistory } from "react-router";
import { useSelector } from 'react-redux';

export default function Step_04({
    subscription_id,
    user_subscription
}) {
        
    useRegisterSteps(subscription_id);
    const { 
        error_message=false,
        success_message=false,        
    } = useSelector(state => state.general)

    const [open, setOpen] = useState(false)
    const history = useHistory()
    
    const callback = ()=>{
        dispatch(registerActions.reset())
        dispatch(generalActions.cleanError())
        dispatch(generalActions.cleanSuccess())

        if(user_subscription){
            history.push('/panel/profile/companies')
        }else if(subscription_id){
            history.push('/panel/admin/subscription/customers')
        }else{
            history.push('/')
        }
        
    }

    const successMessage = ()=>{
        return (
            <ModalMessage 
                open={open}
                setOpen={setOpen}
                type='success'
                title='Account created'
                message={(<>Your account was created successfully. Please login to your new account.</>)}
                actionAfterAccept={callback}
            />
        )
    }

    const errorMessage = ()=>{
        return (
            <ModalMessage 
                open={open}
                setOpen={setOpen}
                type='error'
                title='Account error'
                message={(<>
                    There was an issue with your subscription process:
                    <br /><br />
                    <span className='text-gray-700 mt-5'>{error_message}</span>
                    </>)}
                actionAfterAccept={callback}
            />
        )
    }

    useEffect(()=>{
        if(!open && (error_message || success_message)) setOpen(true);
    },[error_message, success_message, open])

    useEffect(()=>{
        const _id = user_subscription ? user_subscription.id : subscription_id
        dispatch(registerActions.confirmSubscription(_id))        
    },[])

    return (
        <>
            <main className="sm:flex mt-10">
                <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                </p>
                <div className="sm:ml-6 animate-pulse">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                    <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Please wait</h1>
                    <p className="mt-1 text-base text-gray-500">We are creating your new account ...</p>
                    <p className="mt-1 text-base text-red-500">Do not refresh this page!</p>
                </div>
                
                </div>
            </main>
            {error_message ? errorMessage() : null}
            {success_message ? successMessage() : null}
        </>
    )
}