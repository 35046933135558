
import FormErrors from '@app/components/form/FormErrors';
import { useEffect } from "react";
import { useConfig } from "@app/hooks";

const FormInput = ({
    children,
    field,
    errors,
    register,
    required = false,
    type='text',
    strong=false,
    dataTestid=false,
    watch,
    value,
    setValue,
    withTheme=false
}) => {


    const { theme } = useConfig()

    if(field==='password' || field==='password_confirmation'){
        type='password'
    }

    let options = {}
    switch(field){
        case 'email':
            options = {
                required,
                maxLength: 80,
                pattern: /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
            }
            break;
        case 'password':
        case 'password_confirmation':
            options = {
                required,
                maxLength: 20,                
            }
            if(strong){
                options.pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
            }
            if(field==='password_confirmation'){                
                options.validate = (value) => value === watch('password')
            }
            break;
        default:
            if(required){
                options.required=required
            }
    }

    useEffect(()=>{
        if(setValue && value){
            setValue(field, value)
        }
    },[setValue, value, field])

    

    return (
        <>
            <label htmlFor={field} className={`block text-sm font-medium ${withTheme ? `text-${theme}-logo-over` : 'text-gray-700'}`}>
                {required ? (<span className="mr-1 text-red-600">*</span>) : null} 
                {children}
            </label>

            <div className="mt-1">
                <input
                    type={type}
                    data-testid={dataTestid ? dataTestid : field}
                    {...register(field, options)}
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-${theme}-link focus:border-${theme}-link sm:text-sm`}
                    
                />
                <FormErrors errors={errors} field={field} label={children} />
            </div>
        </>
    )

}

export default FormInput