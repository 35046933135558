import { call, put } from 'redux-saga/effects'
import authActions from '@app/store/auth/actions'
import { getPing } from './api'
import axios from 'axios'

function* ping(){    
    try{              
        yield call(getPing);
    }catch(err){
      axios.defaults.headers.common['Authorization'] = null;
      yield put(authActions.reset());      
    }    
}

export default ping;