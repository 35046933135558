import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import authActions from '@app/store/auth/actions'
import adminSubscriptionActions from '@app/store/admin_subscription/actions'
import { getQboDisconnect } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* sagaGetQboDisconnect({company_id, callback }){
    yield put(generalActions.isLoading())
    try{              
      const response = yield call(getQboDisconnect, company_id);      
      yield put(authActions.updateCompany(response.data.data)) //updating in the owner profile
      yield put(adminSubscriptionActions.updateCompany(response.data.data)) //updating if is a superadmin
      if(callback) callback(response.data.data)
      yield put(generalActions.setSuccess('Quickbooks disconnected successfully.'))
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default sagaGetQboDisconnect;