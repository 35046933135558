const _ =  ( error ) => {


    let returnMessage=''
    if(!error.response) return error;
    
    switch(error.response.status){
        case 400:{            
            let messages = []
            const keys = Object.keys(error.response.data[0])            
            for (let i=0; i < keys.length ; i++) {                
                messages.push(error.response.data[0][keys[i]].join('\n'))
            }
            returnMessage = messages//messages.join('\n')
            break
        }
        case 422:{            
            if(Array.isArray(error.response.data.errors)){
                return error.response.data.errors
            }
            let messages = []
            for (let [, error_array] of Object.entries(error.response.data.errors)) {
                //console.log(`${key}: ${value}`);            
                messages.push(error_array.join('\n'))
            }
            //console.debug('fer', messages)
            //returnMessage = messages//messages.join('\n')
            returnMessage = messages.join('\n')
            break
        
        
        }
        
        case 401:
            returnMessage = error.response.data.message
            window.location.href='/logout'
            break;
        case 402:
            window.location.href=`${process.env.PUBLIC_URL}/panel/profile/2-factor-authentication`
            break;
        case 406:
            returnMessage = error.response.data.message            
            break;
        case 500:
            returnMessage = 'Service unavailable. Please try again later.'
            break;
        default:
            returnMessage= error.toString()
    }
    

    return returnMessage
    
}

export default _