/* This example requires Tailwind CSS v2.0+ */

import { Switch } from '@headlessui/react'
import { Controller } from "react-hook-form";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  const Toggle = ({  
    field,
    control,
    children,
    value: defaultValue,  
  })=> {
    
    
  
    return (
  
      <Controller
          control={control}
          name={field}
          defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Switch.Group as="div" className="flex items-center justify-between">
              <span className="flex-grow flex flex-col">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                  {children}
                </Switch.Label>
                {/*<Switch.Description as="span" className="text-sm text-gray-500">
                  Nulla amet tempus sit accumsan. Aliquet turpis sed sit lacinia.
                </Switch.Description>*/}
              </span>
              <Switch
                checked={value}
                
                onChange={onChange}              
                className={classNames(
                  value ? 'bg-green-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    value ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          )}
        />
        
      
    )
  }


  export default Toggle