import { call, put } from 'redux-saga/effects'
import { postReactivate } from './api'
import authActions from '@app/store/auth/actions'
import generalActions from '@app/store/general/actions'
import errorParser from '@app/util/laravel_error_parser';

function* sagaPostReactivateSubscription(){    
    yield put(generalActions.isLoading())
    yield put(authActions.resetSubscription())
    try{              
        const response = yield call(postReactivate);
        yield put(authActions.setSubscription(response.data.data))
        
    }catch(err){
        const parsed_message = errorParser(err)
        yield put(generalActions.setError(parsed_message))
    }finally{
        yield put(generalActions.isNotLoading())
    }
}

export default sagaPostReactivateSubscription;