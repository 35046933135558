import { useHistory } from "react-router"
import { useConfig } from "@app/hooks";

const Link = ({
    children,
    endpoint
}) => {

    const history = useHistory();
    const { theme } = useConfig()

    return (
        <span onClick={()=>history.push(endpoint)} className={`cursor-pointer font-medium  text-${theme}-link hover:text-${theme}-link-over`}>
            {children}
        </span>
    )
}

export default Link