/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/solid'
import { useHistory, useLocation } from 'react-router'
import { useSelector } from "react-redux";


export default function NewCompanySteps({
  subscription_id
}) {

  const { step_2_valid } = useSelector(state=> state.register)

    const history=useHistory()

    const location = useLocation();

    const steps = [
        /*{ id: '01', name: 'User', href: '#', status: 'complete' },
        { id: '02', name: 'Company', href: '#', status: 'current' },
        { id: '03', name: 'Confirmation', href: '#', status: 'upcoming' },*/
      
        { id: '01', name: 'User', href: '#', status: 'current', onClick: ()=>history.push(`/panel/admin/subscription/${subscription_id}/new_company`) },
        { id: '02', name: 'Company', href: '#', status: 'upcoming', onClick: ()=>history.push(`/panel/admin/subscription/${subscription_id}/new_company/step_2`) },
        { id: '03', name: 'Confirmation', href: '#', status: 'upcoming', onClick: ()=>history.push(`/panel/admin/subscription/${subscription_id}/new_company/step_3`) },
      ]
      
      switch(location.pathname){
        case `/panel/admin/subscription/${subscription_id}/new_company/step_2`:
          steps[0].status='complete'
          steps[1].status='current'
          break;
    
        case `/panel/admin/subscription/${subscription_id}/new_company/step_3`:
          steps[0].status='complete'          
          steps[1].status='complete'
          steps[2].status='current'
          break;
    
        default:
          steps[0].status='current'
          steps[1].status='upcoming'
          steps[2].status='upcoming'          
      }


  return (
    <nav aria-label="Progress">
      <ol role="list" className="border- border-gray-300- rounded-md- divide-y divide-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex-1 md:flex">
            {step.status === 'complete' ? (
              <div onClick={()=> !step_2_valid ? step.onClick(): null} className="cursor-pointer group flex items-center w-full">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                    <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                </span>
              </div>
            ) : step.status === 'current' ? (
              <div onClick={()=> !step_2_valid ? step.onClick(): null}  className="cursor-pointer px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                  <span className="text-indigo-600">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
              </div>
            ) : (
              <div onClick={()=> !step_2_valid ? step.onClick(): null}  className="cursor-pointer group flex items-center">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                </span>
              </div>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  )
}
