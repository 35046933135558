const RESET = 'RESET'
const SET_USER = 'SET_USER'
const SAGA_LOGIN = 'SAGA_LOGIN'
const SAGA_LOGOUT = 'SAGA_LOGOUT'
const SAGA_LOGOUT_FROM_OTHER_LOCATIONS = 'SAGA_LOGOUT_FROM_OTHER_LOCATIONS'
const SAGA_RESET_LINK = 'SAGA_RESET_LINK'
const SAGA_PASSWORD_SUBMIT = 'SAGA_PASSWORD_SUBMIT'
const SAGA_SIGNUP = 'SAGA_SIGNUP'
const CREATE_USER = 'CREATE_USER'
const WITH_SINGLE_SESSION_WARNING = 'WITH_SINGLE_SESSION_WARNING'
const WITHOUT_SINGLE_SESSION_WARNING = 'WITHOUT_SINGLE_SESSION_WARNING'
const SAGA_PING = 'SAGA_PING'
const SAGA_SEND_RECOVER_LINK = 'SAGA_SEND_RECOVER_LINK'
const SAGA_RESET_PASSWORD = 'SAGA_RESET_PASSWORD'
const SAGA_GET_PROFILE = 'SAGA_GET_PROFILE'
const SAGA_PUT_PROFILE = 'SAGA_PUT_PROFILE'
const SAGA_PUT_PASSWORD = 'SAGA_PUT_PASSWORD'
const SAGA_GET_COMPANIES = 'SAGA_GET_COMPANIES'
const SET_COMPANIES = 'SET_COMPANIES'
const SET_COMPANY_DEFAULT = 'SET_COMPANY_DEFAULT'
const SAGA_PUT_COMPANY = 'SAGA_PUT_COMPANY'
const SAGA_PUT_COMPANY_NULL = 'SAGA_PUT_COMPANY_NULL'
const SAGA_ADMIN_PING = 'SAGA_ADMIN_PING'
const SAGA_PROFILE_GET_PLANS = 'SAGA_PROFILE_GET_PLANS'
const SAGA_PROFILE_GET_SUBSCRIPTIONS = 'SAGA_PROFILE_GET_SUBSCRIPTIONS'
const PROFILE_SET_SUBSCRIPTIONS = 'PROFILE_SET_SUBSCRIPTIONS'
const SAGA_PROFILE_GET_PAYMENT_UPDATE_METHOD = 'SAGA_PROFILE_GET_PAYMENT_UPDATE_METHOD'
const SAGA_PROFILE_GET_ADDONS = 'SAGA_PROFILE_GET_ADDONS'
const PROFILE_SET_ADDONS = 'PROFILE_SET_ADDONS'
const SAGA_PROFILE_PUT_SUBSCRIPTION = 'SAGA_PROFILE_PUT_SUBSCRIPTION'
const SAGA_PROFILE_PUT_SUBSCRIPTION_CONFIRMATION = 'SAGA_PROFILE_PUT_SUBSCRIPTION_CONFIRMATION'
const SAGA_IMPERSONATE_COMPANY = 'SAGA_IMPERSONATE_COMPANY'
const PROFILE_SAGA_GET_USERS = 'PROFILE_SAGA_GET_USERS'
const PROFILE_SET_USERS = 'PROFILE_SET_USERS'
const PROFILE_SAGA_ENABLE_USER = 'PROFILE_SAGA_ENABLE_USER'
const PROFILE_SAGA_DISABLE_USER = 'PROFILE_SAGA_DISABLE_USER'
const PROFILE_PUT_USER = 'PROFILE_PUT_USER'
const PROFILE_SAGA_GET_SUBSCRIPTION = 'PROFILE_SAGA_GET_SUBSCRIPTION'
const PROFILE_RESET_SUBSCRIPTION = 'PROFILE_RESET_SUBSCRIPTION'
const PROFILE_SAGA_CREATE_USER = 'PROFILE_SAGA_CREATE_USER'
const PROFILE_SAGA_GET_USER_WITH_TOKEN = 'PROFILE_SAGA_GET_USER_WITH_TOKEN'
const PROFILE_HIDE_GOOGLE_ONE_TAP = 'PROFILE_HIDE_GOOGLE_ONE_TAP'
const PROFILE_SHOW_GOOGLE_ONE_TAP = 'PROFILE_SHOW_GOOGLE_ONE_TAP'
const PROFILE_SAGA_REACTIVATE_SUBSCRIPTION = 'PROFILE_SAGA_REACTIVATE_SUBSCRIPTION'
const PROFILE_SAGA_QBO_REDIRECT_CONNECT = 'PROFILE_SAGA_QBO_REDIRECT_CONNECT'
const PROFILE_SAGA_QBO_SAVE = 'PROFILE_SAGA_QBO_SAVE'
const PROFILE_UPDATE_COMPANY = 'PROFILE_UPDATE_COMPANY'
const PROFILE_SAGA_QBO_SYNC = 'PROFILE_SAGA_QBO_SYNC'
const PROFILE_SAGA_QBO_ERRORS = 'PROFILE_SAGA_QBO_ERRORS'
const PROFILE_SAGA_QBO_DISCONNECT = 'PROFILE_SAGA_QBO_DISCONNECT'
const PROFILE_SAGA_QBO_UPDATE_SYNC_OPTIONS = 'PROFILE_SAGA_QBO_UPDATE_SYNC_OPTIONS'
const PROFILE_SAGA_GET_COMPANY = 'PROFILE_SAGA_GET_COMPANY'
const PROFILE_SAGA_QBO_UPDATE_ITEM_OPTIONS = 'PROFILE_SAGA_QBO_UPDATE_ITEM_OPTIONS'
const PROFILE_SAGA_QBO_UPDATE_INVOICE_OPTIONS = 'PROFILE_SAGA_QBO_UPDATE_INVOICE_OPTIONS'
const PROFILE_SAGA_QBO_UPDATE_ACCOUNT_OPTIONS = 'PROFILE_SAGA_QBO_UPDATE_ACCOUNT_OPTIONS'
const GOOGLE2FA_SAGA_POST = 'GOOGLE2FA_SAGA_POST'
const GOOGLE2FA_SAGA_VERIFY = 'GOOGLE2FA_SAGA_VERIFY'


const _default = {
    SET_USER,
    SAGA_LOGIN,
    SAGA_LOGOUT,
    RESET,
    SAGA_RESET_LINK,
    SAGA_PASSWORD_SUBMIT,
    SAGA_SIGNUP,
    CREATE_USER,
    WITH_SINGLE_SESSION_WARNING,
    WITHOUT_SINGLE_SESSION_WARNING,
    SAGA_LOGOUT_FROM_OTHER_LOCATIONS,
    SAGA_PING,
    SAGA_SEND_RECOVER_LINK,
    SAGA_RESET_PASSWORD,
    SAGA_GET_PROFILE,
    SAGA_PUT_PROFILE,
    SAGA_PUT_PASSWORD,
    SAGA_GET_COMPANIES,
    SET_COMPANIES,
    SET_COMPANY_DEFAULT,
    SAGA_PUT_COMPANY,
    SAGA_PUT_COMPANY_NULL,
    SAGA_ADMIN_PING,
    SAGA_PROFILE_GET_PLANS,
    SAGA_PROFILE_GET_SUBSCRIPTIONS,
    PROFILE_SET_SUBSCRIPTIONS,
    SAGA_PROFILE_GET_PAYMENT_UPDATE_METHOD,
    SAGA_PROFILE_GET_ADDONS,
    PROFILE_SET_ADDONS,
    SAGA_PROFILE_PUT_SUBSCRIPTION,
    SAGA_PROFILE_PUT_SUBSCRIPTION_CONFIRMATION,
    SAGA_IMPERSONATE_COMPANY,
    PROFILE_SAGA_GET_USERS,
    PROFILE_SET_USERS,
    PROFILE_SAGA_ENABLE_USER,
    PROFILE_SAGA_DISABLE_USER,
    PROFILE_PUT_USER,
    PROFILE_SAGA_GET_SUBSCRIPTION,
    PROFILE_RESET_SUBSCRIPTION,
    PROFILE_SAGA_CREATE_USER,
    PROFILE_SAGA_GET_USER_WITH_TOKEN,
    PROFILE_HIDE_GOOGLE_ONE_TAP,
    PROFILE_SHOW_GOOGLE_ONE_TAP,
    PROFILE_SAGA_REACTIVATE_SUBSCRIPTION,
    PROFILE_SAGA_QBO_REDIRECT_CONNECT,
    PROFILE_SAGA_QBO_SAVE,
    PROFILE_UPDATE_COMPANY,
    PROFILE_SAGA_QBO_SYNC,
    PROFILE_SAGA_QBO_ERRORS,
    PROFILE_SAGA_QBO_DISCONNECT,
    PROFILE_SAGA_QBO_UPDATE_SYNC_OPTIONS,
    PROFILE_SAGA_GET_COMPANY,
    PROFILE_SAGA_QBO_UPDATE_ITEM_OPTIONS,
    PROFILE_SAGA_QBO_UPDATE_INVOICE_OPTIONS,
    PROFILE_SAGA_QBO_UPDATE_ACCOUNT_OPTIONS,
    GOOGLE2FA_SAGA_POST,
    GOOGLE2FA_SAGA_VERIFY
}

export default _default