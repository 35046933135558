
import { useForm } from "react-hook-form";
import { FormInput, ActionButton, FormInputPlus } from '@app/components/form';
import { dispatch, authActions } from '@app/store'
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom";



export default function Password() {

  const history = useHistory()
  const { user } = useSelector(state=> state.auth)

  const {
    register,
    handleSubmit,    
    formState: { 
      errors,
      isValid
    },
    watch,
    reset,
    setValue,
    control
  } = useForm({ mode: 'onChange' });

  const onSubmit = (payload) => {
    const callback = ()=>{
      //reset()
      history.push('/logout')
    }      
    dispatch(authActions.sagaPutPassword(payload, callback));    
  }
    
  return (
    
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Update your password</h3>
                <p className="mt-1 text-sm text-gray-500">Use a strong credentials.</p>
              </div>

              
                <div className="col-span-6 sm:col-span-3">
                  <FormInput
                      field="current_password"
                      type="password"
                      errors={errors}
                      register={register}
                      required={false}
                      >
                      Current password
                  </FormInput>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <FormInput
                      field="password"
                      errors={errors}
                      register={register}
                      required={false}
                      >
                      New password
                  </FormInput>
                </div>

              
                <div className="col-span-6 sm:col-span-3">
                  <FormInput
                      field="password_confirmation"
                      errors={errors}
                      register={register}
                      required={false}
                      watch={watch}
                      >
                      New password confirmation 
                  </FormInput>
                </div>

                <div className="col-span-6 sm:col-span-3">
                <FormInputPlus 
                          control={control}
                          input={{ type: 'toggle', key: 'two_factor_reset_code', value: user.google_2fa_active, label: 'Enable Google 2FA' }}
                          register={register}
                          setValue={setValue}
                        />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <ActionButton
                    type="submit"
                    isValid={isValid}
                    testid="update-your-password"
                >
                    Update
                </ActionButton>
            </div>
          
        </form> 
      
  )
}