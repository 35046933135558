const FormErrors = ({
    errors,
    field,
    label
})=>{

    //console.debug('errors', errors)
    const className="mt-2 text-sm text-red-600"
    let patterMessage = 'is invalid.'
    if(field==='password'){
        patterMessage = 'must be stronger (Must include !@#$%^&* character).'
    }

    if(field==='confirm_password'){
        patterMessage = " does not match."
    }

    const errType = errors?.[field]?.type
    let defaultMessage = <p className={className}>{label} { patterMessage}</p>

    if(errType=== "required"){
        defaultMessage = <p className={className}>{label} is required</p>
    }

    return errors?.[field] ===undefined ? null : defaultMessage
}

export default FormErrors