import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import { postResetPassword } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* resetPassword({payload, callback}){
    yield put(generalActions.isLoading())
    try{              
      const response = yield call(postResetPassword, payload);      
      yield put(generalActions.setSuccess(response.data.message))
      if(callback){
        callback()
      }
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default resetPassword;