import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import authActions from '@app/store/auth/actions'
import errorParser from '@app/util/laravel_error_parser';
import { postLogin } from './api'
import axios from 'axios'

function* login(action){
    yield put(generalActions.isLoading())
    try{      
        //console.debug('doLogin')
        const response = yield call(postLogin, action),
          {user, token, session_id} = response.data;
        
        yield put(authActions.setUser(user,token, session_id));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        //console.log('doLogin response',response.data)      
    }catch(err){               
      const parsed_message = errorParser(err)      
      if(parsed_message.search('403')>-1){
        yield put(generalActions.isNotLoading())
        yield put(authActions.withSingleSessionWarning())
      }else{
        yield put(generalActions.setError(parsed_message))
      }
      
    }
}

export default login;