/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef } from 'react'
import {  Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { useSelector } from 'react-redux'
import { dispatch, authActions } from '@app/store'
import ActionButton from '@app/components/form/ActionButton'

export default function SingleSession({
  email, password
}) {

    const { single_session_warning } = useSelector(state=>state.auth)
    const { error_message, success_message } = useSelector(state=>state.general)  
    const cancelButtonRef = useRef(null)

    const closeModal = ()=>{  
      dispatch(authActions.withoutSingleSessionWarning())
    }

    const handleProceedLogout = (email, password) => {
      dispatch(authActions.sagaLogoutFromOtherLocations(email, password))
    }

    useEffect(() => {
      if(success_message){
        closeModal()
      }
    },[success_message]);
    
    if(single_session_warning && (!email || !password) ){      
      throw new Error("Missing email || password")
    }    

  return (
    <Transition.Root show={single_session_warning} as={Fragment}>
      
      <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Multiple Device Logins Detected
                    </h3>
                    
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {error_message ? error_message : "Your username is already signed in on another device. To end the session on your other device, press “proceed”. Proceeding will end the session on your other device and will not save work in progress on that device. Be sure to save your work before changing devices."}                      
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                {
                  !error_message ? (
                    <ActionButton
                      testid='continue-single-session'
                      onClick={ e => { handleProceedLogout(email, password) }}
                    >End my other session and proceed</ActionButton>
                    
                  ) : null
                }
                
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeModal}
                  ref={cancelButtonRef}
                  data-testid='cancel-single-session'
                >
                  Cancel this login
                </button>

              </div>
            </div>
          </Transition.Child>
        </div>
      
      </div>
    </Transition.Root>
  )
}
