import { call, put } from 'redux-saga/effects'
import { getPlans } from './api'
import adminSubscriptionActions from '@app/store/admin_subscription/actions'

function* sagaGetPlans(){    
    try{              
        const response = yield call(getPlans);
        yield put(adminSubscriptionActions.setPlans(response.data))
    }catch(err){
      
    }    
}

export default sagaGetPlans;