import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import authActions from '@app/store/auth/actions'
import { postQbo } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* sagaPostQbo({code, state, realmId, callback }){
    yield put(generalActions.isLoading())
    try{              
      const response = yield call(postQbo, {code, state, realmId});
      yield put(authActions.updateCompany(response.data))
      if(callback) callback(response.data)
      yield put(generalActions.setSuccess('Your Quickbooks account was successfully connected.'))
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default sagaPostQbo;