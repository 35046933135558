import { takeLatest, all } from "redux-saga/effects";
import types from "../types";
import sagaLogin from "./sagaLogin";
import sagaLogout from "./sagaLogout";
import sagaLogoutFromOtherLocations from "./sagaLogoutFromOtherLocations";
import sagaPing from "./sagaPing";
import sagaAdminPing from "./sagaAdminPing";
import sagaForgotPassword from "./sagaSendRecoverPassword";
import sagaResetPassword from "./sagaResetPassword";
import sagaGetProfile from "./sagaGetProfile";
import sagaPutProfile from "./sagaPutProfile";
import sagaPutPassword from "./sagaPutPassword";
import sagaGetCompanies from "./sagaGetCompanies";
import sagaPutCompany from "./sagaPutCompany";
import sagaPutCompanyNull from "./sagaPutCompanyNull";
import sagaGetPlans from "./sagaGetPlans";
import sagaGetSubscriptions from "./sagaGetSubscriptions";
import sagaPaymentMethod from "./sagaPaymentMethod";
import sagaGetAddons from "./sagaGetAddons";
import sagaPutSubscription from "./sagaPutSubscription";
import sagaPutSubscriptionConfirmation from "./sagaPutSubscriptionConfirmation";
import sagaGetImpersonate from "./sagaGetImpersonate";
import sagaGetUsers from "./sagaGetUsers";
import sagaEnableUser from "./sagaEnableUser";
import sagaDisableUser from "./sagaDisableUser";
import sagaGetSubscription from "./sagaGetSubscription";
import sagaPostUser from "./sagaPostUser";
import sagaGetUserWithToken from "./sagaGetUserWithToken";
import sagaPostReactivateSubscription from "./sagaPostReactivateSubscription";
import sagaGetQboRedirect from "./sagaGetQboRedirect";
import sagaPostQbo from "./sagaPostQbo";
import sagaGetQboSync from "./sagaGetQboSync";
import sagaGetQboErrors from "./sagaGetQboErrors";
import sagaGetQboDisconnect from "./sagaGetQboDisconnect";
import sagaPostQboSyncOptions from "./sagaPostQboSyncOptions";
import sagaGetCompany from "./sagaGetCompany";
import sagaPostQboItemOptions from "./sagaPostQboItemOptions";
import sagaPostQboInvoiceOptions from "./sagaPostQboInvoiceOptions";
import sagaPostQboAccountOptions from "./sagaPostQboAccountOptions";
import sagaPostGoogle2FA from "./sagaPostGoogle2FA";
import sagaGetGoogle2FA from "./sagaGetGoogle2FA";

//Watchers
function* watchGetList() {
    yield takeLatest(types.SAGA_LOGIN, sagaLogin);
    yield takeLatest(types.SAGA_LOGOUT, sagaLogout);
    yield takeLatest(types.SAGA_LOGOUT_FROM_OTHER_LOCATIONS, sagaLogoutFromOtherLocations);
    yield takeLatest(types.SAGA_PING, sagaPing);
    yield takeLatest(types.SAGA_ADMIN_PING, sagaAdminPing);
    yield takeLatest(types.SAGA_SEND_RECOVER_LINK, sagaForgotPassword);
    yield takeLatest(types.SAGA_RESET_PASSWORD, sagaResetPassword);
    yield takeLatest(types.SAGA_GET_PROFILE, sagaGetProfile);
    yield takeLatest(types.SAGA_PUT_PROFILE, sagaPutProfile);
    yield takeLatest(types.SAGA_PUT_PASSWORD, sagaPutPassword);
    yield takeLatest(types.SAGA_GET_COMPANIES, sagaGetCompanies);
    yield takeLatest(types.SAGA_PUT_COMPANY, sagaPutCompany);
    yield takeLatest(types.SAGA_PUT_COMPANY_NULL, sagaPutCompanyNull);
    yield takeLatest(types.SAGA_PROFILE_GET_PLANS, sagaGetPlans);
    yield takeLatest(types.SAGA_PROFILE_GET_SUBSCRIPTIONS, sagaGetSubscriptions);
    yield takeLatest(types.SAGA_PROFILE_GET_PAYMENT_UPDATE_METHOD, sagaPaymentMethod);
    yield takeLatest(types.SAGA_PROFILE_GET_ADDONS, sagaGetAddons);
    yield takeLatest(types.SAGA_PROFILE_PUT_SUBSCRIPTION, sagaPutSubscription);
    yield takeLatest(types.SAGA_PROFILE_PUT_SUBSCRIPTION_CONFIRMATION, sagaPutSubscriptionConfirmation);
    yield takeLatest(types.SAGA_IMPERSONATE_COMPANY, sagaGetImpersonate);
    yield takeLatest(types.PROFILE_SAGA_GET_USERS, sagaGetUsers);
    yield takeLatest(types.PROFILE_SAGA_ENABLE_USER, sagaEnableUser);
    yield takeLatest(types.PROFILE_SAGA_DISABLE_USER, sagaDisableUser);
    yield takeLatest(types.PROFILE_SAGA_GET_SUBSCRIPTION, sagaGetSubscription);
    yield takeLatest(types.PROFILE_SAGA_CREATE_USER, sagaPostUser);
    yield takeLatest(types.PROFILE_SAGA_GET_USER_WITH_TOKEN, sagaGetUserWithToken);
    yield takeLatest(types.PROFILE_SAGA_REACTIVATE_SUBSCRIPTION, sagaPostReactivateSubscription);
    yield takeLatest(types.PROFILE_SAGA_QBO_REDIRECT_CONNECT, sagaGetQboRedirect);
    yield takeLatest(types.PROFILE_SAGA_QBO_SAVE, sagaPostQbo);
    yield takeLatest(types.PROFILE_SAGA_QBO_SYNC, sagaGetQboSync);
    yield takeLatest(types.PROFILE_SAGA_QBO_ERRORS, sagaGetQboErrors);
    yield takeLatest(types.PROFILE_SAGA_QBO_DISCONNECT, sagaGetQboDisconnect);
    yield takeLatest(types.PROFILE_SAGA_QBO_UPDATE_SYNC_OPTIONS, sagaPostQboSyncOptions);
    yield takeLatest(types.PROFILE_SAGA_GET_COMPANY, sagaGetCompany);
    yield takeLatest(types.PROFILE_SAGA_QBO_UPDATE_ITEM_OPTIONS, sagaPostQboItemOptions);
    yield takeLatest(types.PROFILE_SAGA_QBO_UPDATE_INVOICE_OPTIONS, sagaPostQboInvoiceOptions);
    yield takeLatest(types.PROFILE_SAGA_QBO_UPDATE_ACCOUNT_OPTIONS, sagaPostQboAccountOptions);
    yield takeLatest(types.GOOGLE2FA_SAGA_POST, sagaPostGoogle2FA);
    yield takeLatest(types.GOOGLE2FA_SAGA_VERIFY, sagaGetGoogle2FA);
}
  
  /**
   * Exporting all watchers
   */
export default function* rootSaga() {
    yield all([watchGetList()]);
}