import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { generalActions, dispatch } from "@app/store";

const useRedirectAfterSuccess = (where='/', clean=false)=>{

    const history = useHistory();
    const { success_message } = useSelector(state => state.general)

    useEffect(()=>{
        if(success_message && success_message.length>0){
          if(clean){
            dispatch(generalActions.cleanSuccess())
          }
          history.push(where)
        }
    },[success_message, history, where, clean]);
}

export default useRedirectAfterSuccess