const FilterBox = ({ 
    filterText, 
    onFilter, 
    onClear,
    children,
    onKeyDown
}) => (
	<>                
        <div className="mt-5 flex rounded-md shadow-sm">
            
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
            
            <input
                type="text"
                name="email"
                id="email"
                className="focus:ring-cuddly-500 focus:border-cuddly-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                placeholder="Filter by"
                value={filterText}
                onChange={onFilter}
                onKeyDown={onKeyDown}
            />
            </div>
            <button
                type="button"
                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-cuddly-500 focus:border-cuddly-500"
                onClick={onClear}
                >
                
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z" />
                </svg>                
            </button>
            {children}
        </div>
		
	</>
);

export default FilterBox