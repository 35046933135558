import { call, put, select } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import { createSubscription } from './api'
import errorParser from '@app/util/laravel_error_parser';

const readFromStore = (state) => state.register

function* sagaCreateSubscription({item_price_id, callback}){
    yield put(generalActions.isLoading())
    try{
      const { 
        email,
        first_name,
        last_name,
        company_name,
        phone_number
      } = yield select(readFromStore)
      const response = yield call(createSubscription, {
        item_price_id,
        email,
        first_name,
        last_name,
        company_name,
        phone_number
      })
      
      if(callback){
        callback(response)
      }
            
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default sagaCreateSubscription;