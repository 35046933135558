/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from 'react'
import {  Transition } from '@headlessui/react'

import { dispatch, adminSubscriptionActions } from '@app/store'

import { useForm } from "react-hook-form";
import {  ActionButton, FormSelect } from '@app/components/form';

export default function FreeUsers({
  subscription,
  close
}) {

  
    const cancelButtonRef = useRef(null)    
    const options = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,20,25,30,40,50]
    const { name, free_users, free_companies, companies } = subscription

    const {
      register,
      handleSubmit,    
      formState: { 
        errors,
        isValid
      },
      setValue
    } = useForm({ mode: 'onChange' });

    const closeModal = ()=>{  
      close()
    }

    const onSubmit = ({ free_users, free_companies }) => {      
      dispatch(adminSubscriptionActions.sagaPutFreeUsers(subscription.id, free_users, free_companies, closeModal));
    }

  return (
    <Transition.Root show={ subscription ? true:false } as={Fragment}>
      
      <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:max-w-4xl-- sm:w-full">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    
                    <div className="mt-3 mb-3 text-left  ">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">{name}</h3>
                    </div>

                    <FormSelect
                      field="free_users"
                      errors={errors}
                      register={register}
                      required={true}
                      options={options}
                      setValue={setValue}
                      value={free_users}
                      >
                      Free users
                  </FormSelect>

                  <div className="mt-4"></div>

                  <FormSelect
                      field="free_companies"
                      errors={errors}
                      register={register}
                      required={true}
                      options={options}
                      setValue={setValue}
                      value={free_companies}
                      >
                      Free network locations
                  </FormSelect>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <ActionButton
                    type="submit"
                    isValid={isValid}
                    testid='continue-single-session'                        
                  >Update</ActionButton>
                  
                  <button
                    type="button"
                    className="mt-3 mr-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeModal}
                    ref={cancelButtonRef}
                    data-testid='cancel-single-session'
                  >
                    Cancel
                  </button>

                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      
      </div>
    </Transition.Root>
  )
}
