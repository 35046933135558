import { call } from 'redux-saga/effects'
import { getAdminPing } from './api'


function* adminPing({ callback }){    
    try{              
        yield call(getAdminPing);
    }catch(err){
      if(callback){
        callback()
      }
    }    
}

export default adminPing;