import types from './types'

const reset = () => {
    return {
        type: types.ADMIN_PARAMETERS_RESET,
    }
}

const sagaGet = () => {
    return {
        type: types.ADMIN_PARAMETERS_SAGA_GET,
    }
}

const set = (parameters) => {
    return {
        type: types.ADMIN_PARAMETERS_SET,
        parameters
    }
}

const sagaUpdate = (payload) => {
    return {
        type: types.ADMIN_PARAMETERS_SAGA_UPDATE,
        payload
    }
}

const actions = {        
    reset,
    sagaGet,
    set,
    sagaUpdate
};

export default actions;