import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import authActions from '@app/store/auth/actions'
import { getQboErrors } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* sagaGetQboErrors({company_id, callback }){
    yield put(generalActions.isLoading())
    try{              
      const response = yield call(getQboErrors, company_id);            
      if(callback) callback(response.data.messages)
      //yield put(generalActions.setSuccess('Quickbooks sync process is starting.'))
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default sagaGetQboErrors;