import { useHistory } from "react-router"
import { useForm } from "react-hook-form";
import { FormInput, ActionButton } from '@app/components/form';
import { dispatch, registerActions } from "@app/store";
import { useSelector } from "react-redux";
import { useRegisterSteps } from "@app/hooks";
import Notification from '@app/components/modals/Notification';

export default function Step_01({
    subscription_id
}) {

    useRegisterSteps(subscription_id);
    const history= useHistory()
    const onSubmit = (payload) => {

        const callback = ()=>{
            dispatch(registerActions.setUser(payload))
            if(subscription_id){
                history.push(`/panel/admin/subscription/${subscription_id}/new_company/step_2`)
            }else{
                history.push('/register/business')
            }            
        }

        dispatch(registerActions.validateUser(payload, callback))
    }

    const {
        email,
        first_name,
        last_name,
        phone_number,
        password,
        password_confirmation
    } = useSelector(state=> state.register)

    const {
        register,
        handleSubmit,    
        formState: { 
          errors,
          isValid
        },
        setValue,
        watch
    } = useForm({ mode: 'onChange' });

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="md:w-6/12">

                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 ">
                        <FormInput
                            field="email"
                            errors={errors}
                            register={register}
                            required={true}
                            value={email}
                            setValue={setValue}
                            >
                            Email address
                        </FormInput>
                    </div>
                    <div className="col-span-6 ">
                        <FormInput
                            field="first_name"
                            errors={errors}
                            register={register}
                            required={true}
                            value={first_name}
                            setValue={setValue}
                            >
                            First name
                        </FormInput>
                    </div>

                    <div className="col-span-6 ">
                        <FormInput
                            field="last_name"
                            errors={errors}
                            register={register}
                            required={true}
                            value={last_name}
                            setValue={setValue}
                            >
                            Last name
                        </FormInput>
                    </div>

                    <div className="col-span-6 ">
                        <FormInput
                            field="phone_number"
                            errors={errors}
                            register={register}
                            required={true}
                            value={phone_number}
                            setValue={setValue}
                            >
                            Phone number
                        </FormInput>
                    </div>

                    <div className="col-span-6 ">
                        <FormInput
                            field="password"
                            errors={errors}
                            register={register}
                            required={true}
                            value={password}
                            setValue={setValue}
                            >
                            Password
                        </FormInput>
                    </div>

                    <div className="col-span-6 ">
                        <FormInput
                            field="password_confirmation"
                            errors={errors}
                            register={register}
                            required={true}
                            watch={watch}
                            value={password_confirmation}
                            setValue={setValue}
                            >
                            Password confirmation 
                        </FormInput>
                    </div>


                    <div className="col-span-6 ">
                        <ActionButton
                            type="submit"
                            isValid={isValid}
                            testid="update-your-information"
                        >
                            Next
                        </ActionButton>
                    </div>
                </div>
            </form>
        
            <Notification />
        
        </>
    )
}