/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { dispatch, authActions } from "@app/store";

export default function ModalQboErrors({
    open,
    setOpen,    
    company_id
}) {
  

  const cancelButtonRef = useRef(null)
  const handleAccept = ()=>{    
    setOpen(false)
  }

  const [message, setMessage] = useState('')

  /*const message = `Message A
Message B
Message C
Message Message Message Message
Second Second Second Second Second
Third Third Third Third Third Third
 
`*/

useEffect(()=>{

  if(open===true){
    const callback = (messages)=>{
      setMessage(messages)
    }
    dispatch(authActions.sagaQboErrors(company_id, callback))
  }

},[open])
  

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={handleAccept}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              
              <div className="sm:flex sm:items-start">
                
                <div className="mt-3 text-center sm:mt-0  sm:text-left w-full">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 mb-5">
                    Quickbooks Online Sync Errors
                  </Dialog.Title>
                  {/*<textarea
                    rows={4}
                    name="comment"
                    id="comment"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    defaultValue={message}
                    readOnly
                  />*/}
                  <div dangerouslySetInnerHTML={{ __html: message.replace('\\n', '<br />') }} />
                  
                </div>
              </div>

                <div className="flex justify-center">
                    <button
                      type="button"
                      className={`mt-10 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-2 sm:text-sm`}
                      onClick={handleAccept}
                    >
                    Accept
                    </button>
                </div>
                            
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
