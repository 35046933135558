import { useHistory } from "react-router"
import { useEffect } from "react"

export default function Home() {

  const history = useHistory()

  useEffect(()=>{
    history.push('/panel/profile')
  },[])
  
  return (
    <></>
  )
}