import FormErrors from '@app/components/form/FormErrors';
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";


const FormSelect = ({
    children,
    options=[],
    field,
    errors,
    required,
    dataTestid=false,
    value,
    setValue,
    register,
    control,
    multiple
}) => {

    const renderOptions = options.map((item, i) => {

        let id, name
        if(typeof item === 'object'){
            id = item.id
            name = item.name
        }else{
            id = item
            name = item
        }

        if(id==='optgroup'){
            return <optgroup label={name}></optgroup>
        }else{
            return <option value={id} key={i} >{name}</option>
        }
        
    })
    const selectOptions = {}
    
    if(required){
        selectOptions.required=required
    }

    useEffect(()=>{
        if(setValue && value){
            setValue(field, value)
        }
    },[setValue, value, field])

    return (
        <>
          
            <label htmlFor={field} className="block text-sm font-medium text-gray-700">
                {required ? (<span className="mr-1 text-red-600">*</span>) : null} 
                {children}
            </label>
            { multiple ? (
                <Controller
                    control={control}
                    defaultValue={[]}
                    name={field}
                    render={({ field: { onChange, value, ref }}) => {
                                                

                        return <Select
                            inputRef={ref}
                            defaultValue={ value.map(v=> options.find(o=>v===o.value)) }
                            value={options.find(c => c.value === value)}
                            onChange={ val => onChange(val.map(e=>e.value))}
                            options={options}
                            isMulti
                        />
                            
                    }}
                />
            ) : (
                <select                
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-trxio-500 focus:border-trxio-500 sm:text-sm rounded-md"                
                    data-testid={dataTestid ? dataTestid : field}
                    {...register(field, selectOptions)}
                >
                    {renderOptions}
                </select>
            )}
            
            <FormErrors errors={errors} field={field} label={children} />
        </>
      )

}

export default FormSelect