import { ActionButton } from '@app/components/form';
import { dispatch, authActions } from '@app/store'

const SubscriptionTable = ({
    subscription
})=>{


  const handleUpdatePaymentMethod = ()=>{
    const callback = (response) => {
      window.location.href = response.url
    }

    dispatch(authActions.paymentUpdateMethod(subscription.id, callback))
  }

    return (
        <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-3">{subscription.name}</h3>

            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Subscription
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Plan Items
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Next billing at
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Payment Method
                    </th>
                    
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                {
                  subscription && subscription.id ? (

                    <tbody className="divide-y divide-gray-200 bg-white">
                      {subscription.json_data.map(({id, next_billing_at, subscription_items, currency_code, status}) => (
                        <tr key={id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium- text-gray-900 sm:pl-6">
                            <div>Id: {id}</div>
                            <div>Status: <span className={`inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium ${status==='active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'} mr-2`}>{status} </span></div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {
                              subscription_items.map(item=>
                                <>
                                <span key={item.item_price_id} className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mr-2">{item.item_price_id} </span>
                                <span>$ {item.unit_price} {currency_code}</span>
                                </>
                              )
                            }                    
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{next_billing_at}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><ActionButton onClick={handleUpdatePaymentMethod}>Update</ActionButton></td>
                          
                        </tr>
                      ))}
                    </tbody>

                  ) : null
                }
                
              </table>             
                 
          </div>
    )
}

export default SubscriptionTable