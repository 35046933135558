import types from './types'

const INITIAL_STATE = {
    plans: [],
    current_plan_index: false,
    subscriptions:[],
    companies: [],
    log: []
}

const authReducer = (state = INITIAL_STATE, action) =>{    
    switch (action.type){
        case types.ADMIN_SUBSCRIPTION_RESET: { return INITIAL_STATE }        

        case types.ADMIN_SUBSCRIPTION_SET_PLANS: {
            return {
                ...state,
                plans: action.plans
            }
        }

        case types.ADMIN_SUBSCRIPTION_SET_SUBSCRIPTIONS: {
            return {
                ...state,
                subscriptions: action.subscriptions
            }
        }

        case types.ADMIN_SUBSCRIPTION_MODAL_ALLOWED_USERS: {
            const { current_plan_index } = action
            return {
                ...state,
                current_plan_index
            }
        }

        case types.ADMIN_SUBSCRIPTION_UPDATE_ALLOWED_USERS: {
            
            const { current_plan_index } = state
            const { allowed_users, allowed_companies, qbo, qbd } = action.plan.metadata

            const plans = [...state.plans.filter(plan=> plan.status==='active')]
            plans[current_plan_index].allowed_users = allowed_users
            plans[current_plan_index].allowed_companies = allowed_companies
            plans[current_plan_index].qbo = qbo
            plans[current_plan_index].qbd = qbd
            plans[current_plan_index].meta.plan = action.plan

            return {
                ...state,
                current_plan_index: false,
                plans
            }
        }

        case types.ADMIN_SUBSCRIPTION_SET_FREE_USERS: {

            const { free_users, free_companies, id } = action
            const subscriptions = [...state.subscriptions]
            const index = subscriptions.findIndex( e => e.id === id)            
            subscriptions[index]['free_users'] = free_users
            subscriptions[index]['free_companies'] = free_companies

            return {
                ...state,
                subscriptions
            }
        }
        
        case types.ADMIN_SUBSCRIPTION_SET_COMPANIES: {
            return {
                ...state,
                companies: action.companies
            }
        }

        case types.ADMIN_SUBSCRIPTION_UPDATE: {
            
            const subscriptions = [...state.subscriptions]
            const index = subscriptions.findIndex(e=>e.id===action.subscription.id)
            subscriptions[index] = action.subscription
            return {
                ...state,
                subscriptions
            }
        }

        case types.ADMIN_SET_LOG: {
            return {
                ...state,
                log: action.log
            }
        }

        case types.ADMIN_SUBSCRIPTION_UPDATE_COMPANY: {

            const companies = [...state.companies]
            const index = companies.findIndex( e => e.id === action.company.id)
            companies[index] = action.company

            return {
                ...state,
                companies
            }
        }

        default: return state
    }
}


export default authReducer;