/* This example requires Tailwind CSS v2.0+ */
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'
import { useSelector } from 'react-redux';
import {dispatch, generalActions} from '@app/store'

export default function Message() {

    const { 
        error_message=false,
        success_message=false,        
    } = useSelector(state => state.general)

    const messages_skipped = [
        '403'
    ]

    let htmlContent = ''

    const result = messages_skipped.reduce((prev, current)=>{
        let value = 0;        
        if(error_message && error_message.search(current)>-1){
            value =1
        }
        return value
    },0)
    
        
    if (result>0) return null

    if(error_message){
        htmlContent = (
            <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">{error_message}</h3>
                    </div>
                </div>
            </div>
        )
        hiddeMessage()
    }

    if(success_message){
        htmlContent = (
            <div className="rounded-md bg-green-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">{success_message}</h3>
                    
                   
                    </div>
                </div>
            </div>
        )
        hiddeMessage()
    }
  return htmlContent
}


const hiddeMessage = ()=>{
    setTimeout(()=>{
        dispatch(generalActions.setError(false))
        dispatch(generalActions.setSuccess(false))
    },8000)
}