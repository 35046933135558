import { call, put, select } from 'redux-saga/effects'
//import registerActions from '../actions'
import generalActions from '@app/store/general/actions'
import { postConfirm } from './api'
import errorParser from '@app/util/laravel_error_parser';

const readFromStore = (state) => state.register
const readFromStoreAuth = (state) => state.auth

function* postConfirmSubscription({ callback, subscription_id }){

    const register = yield select(readFromStore)
    const { user } = yield select(readFromStoreAuth)
    const payload = { ...register, ...{ countries: undefined, usa_states: undefined, step_1_valid: undefined, step_2_valid: undefined}}
    
    let prefix=''
    if(user && user.id) prefix='/profile'

    if(subscription_id){
      payload.subscription_id = parseInt(subscription_id)
    }

    yield put(generalActions.cleanError())
    yield put(generalActions.cleanSuccess())
    try{                    

      const response = yield call(postConfirm,payload, prefix)
      yield put(generalActions.setSuccess(response.data.message))
      if(callback) callback();

    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }
}

export default postConfirmSubscription;