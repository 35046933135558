
import CompaniesTable from "@app/containers/common/CompaniesTable"
import { dispatch, adminSubscriptionActions } from '@app/store'
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from 'react'
import { useCheckIfAdmin } from "@app/hooks";

const Companies = () => {

    useCheckIfAdmin();
    const { companies } = useSelector(state=> state.admin_subscription)
    useEffect(()=>{
        dispatch(adminSubscriptionActions.getCompanies())
    },[])

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <CompaniesTable
                            companies={companies}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Companies