/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/solid'
import { dispatch, authActions, subscriptionActions } from '@app/store'
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ActionButton } from '@app/components/form'
import { useConfig } from "@app/hooks";

export default function Plan({
  marginTop='py-24'
}) {
  
  const {
    theme 
} = useConfig()

  useEffect(()=>{
    dispatch(authActions.sagaProfileGetPlans())
  },[])
  
  const [tab, setTab] = useState('monthly')
  const { plans } = useSelector(state=> state.admin_subscription)  
  let tiers = [], meterded_plans=[]
  const shorts = {
    'yearly': 'yr',
    'monthly': 'mo'
  }

  const periods_unit = {
    'yearly': 'year',
    'monthly': 'month'
  }
  
  if(plans){

    tiers = 
      plans
        .filter(e=>e.type==='plan' && e.meta.plan.enabled_for_checkout && ( (e[`${tab}_price`]>0 && e.allowed_users>0 ) || (e.metered===false && theme==='mortrack' )    ))
        .map(e=>{
          return {
            ...e,
            includedFeatures: [              
              `Users: ${e.allowed_users}`,                                        
            ],
          }
        })

        meterded_plans = plans.filter(e=>e.metered)
  }

  const handleSubscribe = (plan)=>{
    const callback = (response)=>{      
      window.location.href=response.data.hosted_page.url
    }
    const price = plan.meta.prices.filter(e=>e.period_unit===periods_unit[tab])[0]

    dispatch(subscriptionActions.create(price.id, callback))
  }
  
  return (
    <div className="bg-white">
      <div className={`max-w-7xl mx-auto ${marginTop} px-4 sm:px-6 lg:px-8`}>
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h1>
          <p className="mt-5 text-xl text-gray-500 sm:text-center">
            Start building for free, then add a site plan to go live. Account plans unlock additional features.
          </p>
          <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
            <button
              type="button"
              className={`relative w-1/2 ${tab==='monthly' ? 'bg-white border-gray-200' :  'border border-transparent'} rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8`}
              onClick={()=>setTab('monthly')}
            >
              Monthly billing
            </button>
            <button
              type="button"
              className={`ml-0.5 relative w-1/2 ${tab==='yearly' ? 'bg-white border-gray-200' :  'border border-transparent'}  rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8`}
              onClick={()=>setTab('yearly')}
            >
              Yearly billing
            </button>
          </div>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
          {tiers.map((tier) => (
            <div key={tier.name} className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
              <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>

                {tier[`${tab}_price`] ? (
                  <p className="mt-8">
                    <span className="text-4xl font-extrabold text-gray-900">${tier[`${tab}_price`]}</span>{' '}
                    <span className="text-base font-medium text-gray-500">/{shorts[tab]}</span>
                  </p>
                ): null}

              {tier[`${tab}_price_volume`] ? (
                  <p className="mt-8">
                    <div className="text-md font-extrabold text-gray-900">{tier[`${tab}_price_volume`]}</div>
                    
                  </p>
                ): null}
                
                
                <ActionButton                  
                  onClick={()=>handleSubscribe(tier)}
                >Buy {tier.name}</ActionButton>
              </div>

              { meterded_plans.length >0 ? (

                  <div className="pt-6 pb-8 px-6">
                    <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">Mandatory subscriptions plans:</h3>
                    <ul className="mt-6 space-y-4">
                      {
                        meterded_plans.filter(e=>e.meta.plan.enabled_for_checkout).map(e=>{

                          return (
                            <li key={e.id} className="flex space-x-3">
                              <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                              <div className="text-sm text-gray-500">
                                {e.name} <br/>
                                {e.monthly_price_volume ? e.monthly_price_volume +' '+e.currency : e.monthly_price +' '+e.currency }
                              </div>                              
                            </li>
                          )
                        })
                      }
                      {/* 
                      tier.meta.prices[0].tiers.map(({ starting_unit, ending_unit, price}) => (
                        <li key={starting_unit} className="flex space-x-3">
                          <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                          <span className="text-sm text-gray-500">{starting_unit} - {ending_unit ? ending_unit : 'Above'}:</span> <span className="text-sm text-gray-600">${price}</span>
                        </li>
                      ))
                      */}
                    </ul>
                  </div>
              ) :(
                <div className="pt-6 pb-8 px-6">
                  <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
                  <ul className="mt-6 space-y-4">
                    {tier.includedFeatures.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
