
import Nav from './Nav'
import { useHistory, useLocation } from 'react-router-dom';
import {  useEffect } from 'react'
import { useBroadcast } from '@app/hooks';
import { useSelector } from 'react-redux';
import Notification from '@app/components/modals/Notification';

import ProfileLayout from "@app/components/layout/ProfileLayout";
import AdminLayout from "@app/components/layout/AdminLayout";
import PrivateRoute from "@app/components/util/PrivateRoute";

import { useConfig } from "@app/hooks";

export default function PrivateLayout(){

    const location = useLocation()
    const history = useHistory()
    const { user } = useSelector(state => state.auth);

    const config = useConfig()
    
    useEffect(()=>{
        if(!user){
          history.push('/login')
        }
    },[user,history])

    useBroadcast()

    let title

    if(location.pathname.includes('profile')){
        title = 'Profile'
    }

    if(location.pathname.includes('admin')){
        title = 'Super Admin'
    }
    

    return (
        <div>
            <div className={`bg-gray-800--- pb-32 bg-${config.theme}-logo`}>
                <Nav config={config} />
                <header className="py-10">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-left">
                        <h1 className={`text-3xl font-bold text-${config.theme}-logo-over`}>{title}</h1>
                    </div>
                </header>
            </div>

            <main className="-mt-32">
                <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
                {/* Replace with your content */}

                {/*
                    noBorder ? children : (
                        <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
                            <div className="border-4- border-dashed- border-gray-200 rounded-lg min-h-96">
                                {children}
                            </div>                                    
                        </div>
                    )
                */}
                
                <PrivateRoute path="/panel/profile">
                    <ProfileLayout />
                </PrivateRoute>

                <PrivateRoute path="/panel/admin">
                    <AdminLayout />
                </PrivateRoute>
                {/* /End replace */}
                </div>
            </main>
            <Notification  />
        </div>
    )

}