import { call, put } from 'redux-saga/effects'
import { getGoogle2FA} from './api'

import generalActions from '@app/store/general/actions'
import errorParser from '@app/util/laravel_error_parser';
import axios from 'axios'

function* sagaVerify2FA({ otp_code, callback }){    
    yield put(generalActions.isLoading())
    try{              
        const response = yield call(getGoogle2FA, otp_code);
        axios.defaults.headers.common['x-v2f'] = true;
        if(callback) callback()
    }catch(err){
        const parsed_message = errorParser(err)
        yield put(generalActions.setError(parsed_message))
    }finally{
        yield put(generalActions.isNotLoading())
    }
}

export default sagaVerify2FA;