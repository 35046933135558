/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { dispatch, authActions } from "@app/store";
import { ActionButton } from "@app/components/form";

export default function ModalQboPurchaseOrder({
    open,
    closeModal,    
    company
}) {
  

  const cancelButtonRef = useRef(null)

  const handleExport = () => {
    dispatch(authActions.sagaQboSync(company.id, true, false, closeModal ))
  }

  const handleImport = () => {
    dispatch(authActions.sagaQboSync(company.id, false, true, closeModal ))
  }
  
  

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4- pb-20- text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              
              <div className="sm:flex sm:items-start bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                
                <div className="mt-3 text-center sm:mt-0  sm:text-left w-full">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 mb-5">
                    QuickBooks Purchase Order Sync Options
                  </Dialog.Title>

                  <div className='text-md text-justify font-thin'>
                  By clicking on either of the Purchase Order syncs below, we will automatically run the Vendor, Customer, and Item syncs before we run the Purchase Order sync.
                  </div>

                  <div className='mt-5 text-md text-justify font-thin'>
                  In order to insure that a Purchase Order Sync is successful, we want you to understand all of its components. If your Purchase Orders fail to show up in either, TRXio(PO Import) or in QuickBooks Online(PO Export) please READ guidelines closely and try again. If the problem continues, please contact support.
                  </div>

                  <div className='mt-5 text-md text-justify font-thin'>
                  A Purchase Order Sync involves 4 different syncs. First a Vendor sync, second a Customer sync, third an Item sync, and finally the Purchase Order sync. So in order to sync a Purchase Order, you will need to have already Synced the Vendor, the Customer, and the Item(s), called Products in TRXio, involved in that Purchase Order.
                  </div>


                  <div className='p-8 bg-white flex items-center justify-center'>
                  <span className="relative z-0 inline-flex shadow-sm rounded-md ">
                    {
                      company.is_qbo_sync_running ? (
                        <div className="font-medium text-yellow-600">Sync process is running</div>
                      ) : (
                        <>
                          <ActionButton
                            onClick={handleExport}
                            className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            classNameDisabled="text-gray-600"
                          >
                            Queue Purchase Order Export
                          </ActionButton>
                          
                          <ActionButton
                            onClick={handleImport}
                            className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            classNameDisabled="text-gray-600"
                          >
                            Queue Purchase Order Import
                          </ActionButton>
                        </>
                      )
                    }
                    
                    
                  </span>
                  </div>
                  
                  
                </div>
              </div>

              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="mt-3 mr-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={closeModal}
                    >
                    Close
                    </button>
                </div>
                            
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
