

import { dispatch, adminSubscriptionActions } from '@app/store'
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from 'react'
import { useCheckIfAdmin } from "@app/hooks";
import DataTable from 'react-data-table-component';
import FilterBox from '@app/components/util/FilterBox';

const Log = () => {

    useCheckIfAdmin();
    const { log } = useSelector(state=> state.admin_subscription)
    useEffect(()=>{
        dispatch(adminSubscriptionActions.sagaGetLog())
    },[])

    

    const columns = [
        
        {
            name: 'Date',
            width: '150px',
            selector: row => row.id,
            sortable: false,
            cell: (row, index, column, id) => {
                return <div className="cursor-pointer py-4 whitespace-nowrap-- text-md text-gray-500 text-left">{row.created_at}</div>
            }
        },

        /*{
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            cell: (row, index, column, id) => {
              return <div className="cursor-pointer py-4 whitespace-nowrap-- text-xs text-gray-500 text-left">{row.type}</div>
            }
        },*/                      
        {
            name: 'Change Made',
            selector: row => row.type,
            sortable: false,
            cell: (row, index, column, id) => {
              return <div className="cursor-pointer py-4 whitespace-nowrap-- text-md text-gray-500 text-left">{row.description}</div>
            }
        },                      
        {
            name: 'Change Details',
            selector: row => row.type,
            sortable: false,
            cell: (row, index, column, id) => {
                const details = []
                Object.keys(row.json_detail).forEach(key => {
                    details.push(`${key}: ${row.json_detail[key]}`)
                })
              return <div className='row'>
                  {details.map(d=><div key={d} className="capitalize py-1 whitespace-nowrap-- text-md text-gray-500 text-left">{d}</div>)}
              </div>
            }
        },                      

        {
          name: 'User',
          selector: row => row.name,
          sortable: false,
          cell: (row, index, column, id) => {
            return <div className='text-gray-500 text-left'>
                {row.name}<br />
                {row.email}
            </div>
          }
        },

        
    ]


    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredLog = log.filter(
		  ({ name, email, description, json_detail={} }) => {

            
            const details = []
            Object.keys(json_detail).forEach(key => {
                details.push(`${key}: ${json_detail[key]}`)
            })

            return  (name && name.toLowerCase().includes(filterText.toLowerCase())) 
            ||(email && email.toLowerCase().includes(filterText.toLowerCase())) 
            ||(description && description.toLowerCase().includes(filterText.toLowerCase())) 
            || ( details.filter( text => {
                console.debug(text)
                return text.toLowerCase().includes(filterText.toLowerCase())
            } ) ).length >0
        
          });

    const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterBox
                onFilter={e => setFilterText(e.target.value)} 
                onClear={handleClear}
                filterText={filterText}                 
                />
		);
	}, [filterText, resetPaginationToggle]);

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <DataTable
                            columns={columns}
                            data={filteredLog}
                            pagination
                            striped
                            defaultSortAsc={false}
                            
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            noHeader={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Log