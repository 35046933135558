import { call, put, select } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import adminSubscriptionActions from '@app/store/admin_subscription/actions'
import { putFreeInformation } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* sagaPutFreeUsers({id, free_users, free_companies, callback}){
    
    yield put(generalActions.isLoading())
    try{              
      const response = yield call(putFreeInformation, {id, free_users, free_companies})      
      yield put(adminSubscriptionActions.setFreeUsers(id, free_users, free_companies))

      if(callback) callback()
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default sagaPutFreeUsers;