import { call, put, select } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import adminSubscriptionActions from '@app/store/admin_subscription/actions'
import { putPlan } from './api'
import errorParser from '@app/util/laravel_error_parser';

const readFromStore = (state) => state.admin_subscription

function* sagaPutPlans({ allowed_users,  allowed_companies, qbd, qbo}){
    const { current_plan_index, plans } = yield select(readFromStore)
    const plan = plans.filter(plan=> plan.status==='active')[current_plan_index]
    yield put(generalActions.isLoading())
    try{              
      const response = yield call(putPlan, plan.id, allowed_users, allowed_companies, qbd, qbo)
      //yield put(generalActions.setSuccess(response.data.message))      
      yield put(adminSubscriptionActions.updateAllowedUsers(response.data))
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default sagaPutPlans;