import { Route, Switch } from "react-router-dom";

import PrivateLayout from "@app/components/layout/main";
import RegisterLayout from "@app/components/layout/RegisterLayout";
import Login from "@app/containers/auth/Login";
import Logout from "@app/containers/auth/Logout";
import Missing from "@app/containers/auth/Missing";
import Home from "@app/containers/common/Home";
import ForgotPasswordSendLink from "@app/containers/auth/ForgotPasswordSendLink";
import ResetPassword from "@app/containers/auth/ResetPassword";
import NotFound from "@app/containers/common/NotFound";
import PrivateRoute from "@app/components/util/PrivateRoute";
import VerifyGoogle2Fa from "@app/containers/auth/VerifyGoogle2Fa";
// import { useGoogle2Fa } from "@app/hooks";


export default function Routes() {
  
  // useGoogle2Fa()

  return (
    <Switch>
      
      <Route exact path="/login">
        <Login />
      </Route>

      <Route exact path="/forgot-password">
        <ForgotPasswordSendLink />
      </Route>

      <Route exact path="/:token/password-reset">
        <ResetPassword />
      </Route>

      <Route path="/register">
        <RegisterLayout />
      </Route>

      <PrivateRoute path="/panel">
        <PrivateLayout />
      </PrivateRoute>

      <PrivateRoute exact path="/">
        <Home />
      </PrivateRoute>

      <PrivateRoute path="/logout">
        <Logout />
      </PrivateRoute>

      <PrivateRoute path="/settings">
        <Missing />
      </PrivateRoute>

      <PrivateRoute path="/2-factor-authentication">        
        <VerifyGoogle2Fa />
      </PrivateRoute>

      
      
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
