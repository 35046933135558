import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import authActions from '@app/store/auth/actions'
import { getCompanies } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* readCompanies(){
    yield put(generalActions.isLoading())
    try{              
      const response = yield call(getCompanies);
      yield put(generalActions.setSuccess(response.data.message))
      yield put(authActions.setCompanies(response.data.data))
            
      const companyDefault = response.data.data.filter(c => c.is_default===true)
      if(companyDefault.length>0){
        yield put(authActions.setCompanyDefault(companyDefault[0]))          
      }else{

      }        
      
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default readCompanies;