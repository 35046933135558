import types from './types'
//import Immutable from require('immutable')
//const { fromJS ,updateIn, setIn, Map, List } = require('immutable')

const INITIAL_STATE = {
    is_loading:     false,
    error_message:  false,
    success_message:  false,
    alert_message: false,
    config: {
        theme: false,
        login_background_image: false,
        logo: false,
        is_ready: false,
        auth_s: false
    }
}

const generalReducer = (state = INITIAL_STATE, action) =>{

    
    switch (action.type){

        case types.RESET_GENERAL: { 
            const x = {...INITIAL_STATE}
            x.config = state.config
            return x 
        }

        case types.IS_LOADING:{
            const { clear_success_message } = action
            let success_message = state.success_message
            if(clear_success_message) success_message=false
            return {
                ...state,
                is_loading: true,
                error_message: false,
                success_message,
            }
        }

        case types.IS_NOT_LOADING:{
            return {
                ...state,
                is_loading: false,
            }
        }

        case types.GENERAL_SET_CONFIG:{
            action.config.is_ready=true
            return {
                ...state,
                config: action.config,
            }
        }

        case types.SET_ERROR:{
            return {
                ...state,
                is_loading: false,
                error_message: action.error_message,
                success_message: false,
            }
        }

        case types.CLEAN_ERROR:{
            return {
                ...state,                
                error_message: false,                
            }
        }

        case types.CLEAN_SUCCESS:{
            return {
                ...state,                
                success_message: false,                
            }
        }

        case types.SET_SUCCESS:{
            return {
                ...state,
                is_loading: false,
                error_message: false,
                success_message: action.success_message,
            }
        }

        case types.SET_ALERT:{
            return {
                ...state,                
                alert_message: action.alert_message,
            }
        }
        

        default: return state
    }
}


export default generalReducer;