import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";


const Datepicker = ({
    control,
    required=false,
    children,
    field: fieldName,
    value
})=>{

    let parsedValue= new Date()
    if(value) parsedValue = new Date(value)

    return (
        <>
            <label className="mt-5 block text-sm font-medium text-gray-700">
                {required ? <span className="mr-1 text-red-600">*</span> : null}
                {children}
            </label>
            <Controller
                defaultValue={parsedValue}
                control={control}
                name={fieldName}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                    />
            )}
            />  
        </>
    )

}

export default Datepicker