
import { Disclosure } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'

import ProfileDropdown from './ProfileDropdown';
import ProfileDropdownMobile from './ProfileDropdownMobile';

import { Fragment } from 'react'
import { dispatch, authActions } from '@app/store'
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { goToApplication } from '@app/util/functions';


const Nav = ({
  config
})=>{

  
  const { session_id, user, companies } = useSelector(state=>state.auth)  
  const user_image = user && user.image ? user.image : 'https://cdn.landesa.org/wp-content/uploads/default-user-image.png'      
  const history = useHistory()
  const profile = [
      {
        label: 'Your Profile',
        onClick: ()=>{
          history.push('/panel/profile')
        }
      },
      /*{
        label: 'Settings',
        onClick: ()=>{}
      },*/
      {
        label: 'Sign out',
        onClick: ()=>{
          dispatch(authActions.sagaLogout())
        }
      },      
  ];

  const navigation = []
  const default_company = companies.filter(c=>c.has_valid_subscription && c.is_default)
  const can_return_to_application = default_company.length>0 ? true: false
  

  if(!user.isa && can_return_to_application){
    navigation.push({
      title: 'Return to Application',
      onClick: ()=>goToApplication(session_id)
    })
  }
  
  navigation.push({
    title: 'Profile',
    onClick: ()=>history.push('/panel/profile')
  })

  if(user.isa){
    navigation.push({
      title: 'Super Admin',
      onClick: ()=>history.push('/panel/admin/subscription/customers')
    })
  }  
  

    return (
        <Disclosure as="nav" className={`bg-gray-800-- bg-${config.theme}-logo`}>
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="border-b border-gray-700">
                  <div className="flex items-center justify-between h-16 px-4 sm:px-0">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        
                      <img
                        className="h-12 w-auto"
                        src={process.env.PUBLIC_URL +  `/${config.theme}.png`}
                        alt="Cairnstack"
                      />
                        
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                          {navigation.map((item, itemIdx) =>
                            itemIdx === 50000 ? null : (
                              <span
                                key={item.title}
                                onClick={item.onClick}
                                className={`cursor-pointer text-${config.theme}-logo-over hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}
                              >
                                {item.title}
                              </span>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-4 flex items-center md:ml-6">
                        {/*<button className={`bg-${config.theme}-logo p-1 text-gray-400 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}>
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                            </button>*/}

                        {/* Profile dropdown */}
                        <ProfileDropdown 
                          profile={profile} 
                          name={user.first_name + ' ' + user.last_name}
                          image={user_image}
                           />
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className={`bg-${config.theme}-logo inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}>
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="border-b border-gray-700 md:hidden">
                <div className="px-2 py-3 space-y-1 sm:px-3">
                  {navigation.map((item, itemIdx) =>
                    itemIdx === 5000 ? null : (
                      <span
                        key={item.title}
                        onClick={item.onClick}
                        className={`curstor-pointer text-${config.theme}-logo-over hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium`}
                      >
                        {item.title}
                      </span>
                    )
                  )}
                </div>
                <div className="pt-4 pb-3 border-t border-gray-700">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user_image}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">Tom Cook</div>
                      <div className="text-sm font-medium leading-none text-gray-400">tom@example.com</div>
                    </div>
                    <button className={`ml-auto bg-${config.theme}-logo flex-shrink-0 p-1 text-${config.theme}-logo-over rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}>
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    <ProfileDropdownMobile 
                      profile={profile} 
                      config={config} />
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
    )
}

export default Nav