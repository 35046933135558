import { combineReducers } from "redux";
import generalReducer from "@app/store/general";
import authReducer from "@app/store/auth";
import adminSubscriptionReducer from "@app/store/admin_subscription";
import adminParametersReducer from "@app/store/admin_parameters";
import subscriptionReducer from "@app/store/subscription";
import registerReducer from "@app/store/register";

const rootReducer = combineReducers({
  general: generalReducer,  
  auth: authReducer,
  admin_subscription: adminSubscriptionReducer,
  admin_parameters: adminParametersReducer,
  subscription: subscriptionReducer,
  register: registerReducer
});

export default rootReducer;
