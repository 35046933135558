import { takeLatest, all } from "redux-saga/effects";
import types from "../types";

import sagaGetPlans from "./sagaGetPlans";
import sagaPutPlans from "./sagaPutPlans";
import sagaGetSubscriptions from "./sagaGetSubscriptions";
import sagaPutFreeUsers from "./sagaPutFreeUsers";
import sagaGetCompanies from "./sagaGetCompanies";
import sagaGetSyncSubscription from "./sagaGetSyncSubscription";
import sagaPostPause from "./sagaPostPause";
import sagaPostRemoveScheduledPause from "./sagaPostRemoveScheduledPause";
import sagaPostResume from "./sagaPostResume";
import sagaPostCancel from "./sagaPostCancel";
import sagaPostRemoveScheduledCancellation from "./sagaPostRemoveScheduledCancellation";
import sagaPostReactivate from "./sagaPostReactivate";
import sagaPostBind from "./sagaPostBind";
import sagaGetLog from "./sagaGetLog";

//Watchers
function* watchGetList() {
    yield takeLatest(types.ADMIN_SUBSCRIPTION_SAGA_GET_PLANS, sagaGetPlans);
    yield takeLatest(types.ADMIN_SUBSCRIPTION_SAGA_ALLOWED_USERS, sagaPutPlans);
    yield takeLatest(types.ADMIN_SUBSCRIPTION_SAGA_GET_SUBSCRIPTIONS, sagaGetSubscriptions);
    yield takeLatest(types.ADMIN_SUBSCRIPTION_SAGA_PUT_FREE_USERS, sagaPutFreeUsers);
    yield takeLatest(types.ADMIN_SUBSCRIPTION_SAGA_GET_COMPANIES, sagaGetCompanies);
    yield takeLatest(types.ADMIN_SUBSCRIPTION_SAGA_SYNC, sagaGetSyncSubscription);
    yield takeLatest(types.ADMIN_SUBSCRIPTION_SAGA_PAUSE, sagaPostPause);
    yield takeLatest(types.ADMIN_SUBSCRIPTION_SAGA_REMOVE_SCHEDULED_PAUSE, sagaPostRemoveScheduledPause);
    yield takeLatest(types.ADMIN_SUBSCRIPTION_SAGA_RESUME, sagaPostResume);
    yield takeLatest(types.ADMIN_SUBSCRIPTION_SAGA_CANCEL, sagaPostCancel);
    yield takeLatest(types.ADMIN_SUBSCRIPTION_SAGA_REMOVE_SCHEDULED_CANCELLATION, sagaPostRemoveScheduledCancellation);
    yield takeLatest(types.ADMIN_SUBSCRIPTION_SAGA_REACTIVATE, sagaPostReactivate);
    yield takeLatest(types.ADMIN_SUBSCRIPTION_SAGA_BIND, sagaPostBind);
    yield takeLatest(types.ADMIN_SAGA_GET_LOG, sagaGetLog);
    
}
  
  /**
   * Exporting all watchers
   */
export default function* rootSaga() {
    yield all([watchGetList()]);
}