import { call, put } from 'redux-saga/effects'
import { getSubscription } from './api'
import authActions from '@app/store/auth/actions'
import generalActions from '@app/store/general/actions'
import errorParser from '@app/util/laravel_error_parser';

function* sagaGetSubscription({ company_id }){    
    yield put(generalActions.isLoading())
    yield put(authActions.resetSubscription())
    try{              
        const response = yield call(getSubscription, company_id );
        yield put(authActions.setSubscription(response.data.data))
        
    }catch(err){
        const parsed_message = errorParser(err)
        yield put(generalActions.setError(parsed_message))
    }finally{
        yield put(generalActions.isNotLoading())
    }
}

export default sagaGetSubscription;