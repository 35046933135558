import { handleImpersonate } from '@app/util/functions'
import { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component';
import FilterBox from '@app/components/util/FilterBox';
import { useHistory } from 'react-router';
import { ActionButton } from "@app/components/form";
import { dispatch, authActions } from "@app/store";
import { useSelector } from "react-redux";

const CompaniesTable = ({
    companies=[],
    show_chargebee=true,
    from_profile=false,
    set_default=false,
    callback_after_set_default
})=>{

  const history=useHistory()
  const handleDefaultCompany = (company_id)=>{    
    dispatch(authActions.sagaPutCompany(company_id, callback_after_set_default))
  }
  const handleEmptyDefaultCompany = (company_id)=>{    
    dispatch(authActions.sagaPutCompanyNull(company_id, callback_after_set_default))
  }
  const { user } = useSelector(state=> state.auth)

  const columns = [
        
        {
            name: 'ID',
            width: '100px',
            selector: row => row.id,
            sortable: true,
            cell: (row, index, column, id) => {
                return <div className="cursor-pointer py-4 whitespace-nowrap-- text-md text-gray-500 text-left">{row.id}</div>
            }
        },

        {
          name: 'NAME',
          selector: row => row.name,
          sortable: true,
          cell: (row, index, column, id) => {
            return user.gid === 3 ?  <button
                    type="button"
                    className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={()=>history.push(`/panel/profile/${row.id}/company`)}
                    >
                    {row.name}
                  </button>

                  : row.name
          }
        },

        {
            name: 'TYPE',
            selector: row => row.type,
            sortable: true,
            cell: (row, index, column, id) => {

              return row.has_valid_subscription ? (
                <div className="cursor-pointer- py-4 whitespace-nowrap-- text-md text-gray-500 text-left">{row.type}</div>
              ) : (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">{'No valid subscription'}</span>
              )

              
            }
        },                      
    ]

    if(set_default){
      columns.push({
        name: 'IS DEFAULT',
        selector: row => row.id,
        sortable: true,
        width: '130px',
        cell: (row, index, column, id) => {
          return <div className="cursor-pointer py-4 whitespace-nowrap-- text-md text-gray-500 text-left">

              {
                row.is_default ? (
                  

                  <ActionButton
                  className="inline-flex cursor-pointer items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 bg-green-600 hover:bg-green-700 "
                  classNameEnabled="focus:ring-red-500 bg-red-600 hover:bg-red-700"
                  classNameDisabled="focus:ring-gray-200 bg-gray-300 hover:bg-gray-200"
                  onClick={()=> handleEmptyDefaultCompany(row.id) }
                  >Yes</ActionButton>
                ) : (
                  <ActionButton
                    className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                    classNameEnabled="focus:ring-red-500 bg-red-600 hover:bg-red-700"
                    classNameDisabled="focus:ring-gray-200 bg-gray-300 hover:bg-gray-200"
                    onClick={()=> handleDefaultCompany(row.id) }
                  >No</ActionButton>
                )
              }
            </div>
        }
      })
    }

    if(show_chargebee){
      columns.push({
        name: 'CHARGEBEE',
        selector: row => row.type,
        sortable: true,
        width: '130px',
        cell: (row, index, column, id) => {
          return <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${row.type ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>{row.type ? 'YES' : 'NO'}</span>
        }
      })
    }


    columns.push({
      name: '',
      selector: row => row.id,
      width: '100px',
      
      sortable: true,
      cell: (row, index, column, id) => {

        return row.has_valid_subscription || user.isa ? (
          <div className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <button
            type="button"
            className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={()=>handleImpersonate(row.id, from_profile)}
            >
            Login
            </button>
          </div>
        ) : null      
      }
  })

    
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredCompanies = companies.filter(
		  ({ id, name, license }) => 
          (id && String(id).toLowerCase().includes(filterText.toLowerCase())) 
        ||(name && name.toLowerCase().includes(filterText.toLowerCase())) 
        ||(license && license.toLowerCase().includes(filterText.toLowerCase())) 
        ,
	  );

    const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		}

    const onKeyDown = (e)=>{
      if (e.key === 'Enter') {
        if(filteredCompanies.length>0){
          //console.log('Enter with value', e.target.value );
          //console.log(filteredCompanies)
          //console.log('fer', filteredCompanies[0].id, from_profile)
          handleImpersonate(filteredCompanies[0].id, from_profile)
        }
        
      }
    }

		return (
			<FilterBox
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        onKeyDown={onKeyDown}
      />
		);
	}, [filterText, resetPaginationToggle, filteredCompanies]);

    return (
      <DataTable
          columns={columns}
          data={filteredCompanies}
          pagination
          striped
          defaultSortAsc={false}
          defaultSortField={'name'}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          noHeader={true}
      />
    )
}

export default CompaniesTable