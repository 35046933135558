import { call, put, all } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
//import adminParametersActions from '@app/store/admin_parameters/actions'
import { putParameter } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* sagaPutParameter({ payload }){
    
    yield put(generalActions.isLoading())
    try{              
      
      const calls=[]
      for (const property in payload) {
        calls.push(call(putParameter, property, payload[property]))
      }
      yield all(calls)
      //const response = yield call(putParameter, key, value)

      yield put(generalActions.setSuccess('Updated successfully'))
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default sagaPutParameter;