import { call, put } from 'redux-saga/effects'
import { getUsers } from './api'
import authActions from '@app/store/auth/actions'
import generalActions from '@app/store/general/actions'

function* sagaGetUsers({ company_id }){    
    yield put(generalActions.isLoading())
    try{              
        const response = yield call(getUsers, company_id);
        yield put(authActions.setUsers(response.data.data))
    }catch(err){
      
    }finally{
        yield put(generalActions.isNotLoading())
    }
}

export default sagaGetUsers;