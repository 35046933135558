
import { useHistory } from 'react-router'
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CompaniesTable from '../../common/CompaniesTable';
import { ExclamationIcon } from '@heroicons/react/solid'
import { dispatch, adminSubscriptionActions } from '@app/store'
import { Loading } from '@app/components/util';
import SubscriptionResume from '@app/components/modals/SubscriptionResume';
import SubscriptionPause from '@app/components/modals/SubscriptionPause';
import SubscriptionCancel from '@app/components/modals/SubscriptionCancel';
import { useState } from 'react';
import SubscriptionTable from '@app/components/subscription/SubscriptionTable';


const Subscription = () => {

  const history = useHistory()
  const { id } = useParams();  
  const { subscriptions } = useSelector(state=> state.admin_subscription)

  const subscription = subscriptions.filter( e => e.provider_customer_id === id )[0]    
  const is_scheduled_pause = subscription.provider_status==='active' && subscription.json_data.pause_date ? subscription.json_data.pause_date : false
  const is_scheduled_resume = subscription.provider_status==='paused' && subscription.json_data.resume_date ? subscription.json_data.resume_date : false
  
  const handleSync = ()=>{
    dispatch(adminSubscriptionActions.sagaSync(subscription.id))
  }

  const [openResume, setOpenResume] = useState(false)
  const [openPause, setOpenPause] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)

  const handleRemoveScheduledPause = () => {
    dispatch(adminSubscriptionActions.sagaRemoveScheduledPause(subscription.id))
  }

  const handleRemoveScheduledCancellation = () => {    
    dispatch(adminSubscriptionActions.sagaRemoveScheduledCancellation(subscription.id))
  }

  const handleReactivate = () => {    
    dispatch(adminSubscriptionActions.sagaReactivate(subscription.id))
  }  

  let pauseButton = null
  if(is_scheduled_pause){
    pauseButton = (
      <button
        type="button"
        onClick={handleRemoveScheduledPause }
        className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Remove scheduled pause
      </button>
    ) 
  }else if(subscription.provider_status==='active'){
    pauseButton = (
      <button
        type="button"
        onClick={()=> setOpenPause(true) }
        className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Pause
      </button>
    )
  }else if(subscription.provider_status==='paused'){
    pauseButton = (
      <button
        type="button"
        onClick={()=> setOpenResume(true) }
        className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Resume
      </button>
    )
  }
  
  let cancelButton
  if(subscription.provider_status==='non_renewing'){
    cancelButton = (
      <button
        type="button"
        className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={ handleRemoveScheduledCancellation }
      >
        Remove scheduled cancellation
      </button>
    )
  }else if(subscription.provider_status==='cancelled'){
    cancelButton = (
      <button
        type="button"
        className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={ handleReactivate }
      >
        Reactivate
      </button>
    )
  }else{
    cancelButton = (
      <button
        type="button"
        className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={()=> setOpenCancel(true) }
      >
        Cancel
      </button>
    )
  }

    return (
      <>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">

            
          <SubscriptionTable subscription={subscription} />

            {is_scheduled_pause ? (
              <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">Scheduled pause subscription</h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>
                        This subscription will be paused on {is_scheduled_pause}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

          {subscription.provider_status==='non_renewing' ? (
              <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">Scheduled cancel subscription</h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>
                        This subscription is scheduled to be cancelled on {subscription.json_data.cancelled_at}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {subscription.provider_status==='cancelled' ? (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">Subscription cancelled</h3>
                    <div className="mt-2 text-sm text-red-700">
                      <p>
                        This subscription was cancelled on {subscription.json_data.cancelled_at}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {subscription.provider_status==='paused' && !is_scheduled_resume ? (
              <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">Subscription paused</h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>
                      This subscription is paused since  {subscription.json_data.pause_date}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {is_scheduled_resume ? (
              <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">Subscription paused</h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>
                      This subscription will resume on  {subscription.json_data.pause_date}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          

      <div className="mt-5 border-t border-gray-200">
        <dl className="divide-y divide-gray-200">
                  
          <CompaniesTable
            companies={subscription.companies}
          />
                    
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500 flex justify-center"><Loading /></dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  
                  <div className="ml-4 flex-shrink-0 flex space-x-4">
                    
                    <button
                      type="button"
                      onClick={handleSync}
                      className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Sync
                    </button>
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={()=>history.push(`/panel/admin/subscription/${subscription.id}/new_company`)}
                    >
                      Create new company
                    </button>
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>                    
                    { pauseButton }                    
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    { cancelButton }
                  </div>
                </li>
                
              </ul>
            </dd>
          </div>
        </dl>
      </div>
      </div>
      </div>

        <SubscriptionResume
          subscription={subscription}
          open={openResume}
          close={()=>{ setOpenResume(false)}}
         />

        <SubscriptionPause
          subscription={subscription}
          open={openPause}
          close={()=>{ setOpenPause(false)}}
         />

        <SubscriptionCancel
          subscription={subscription}
          open={openCancel}
          close={()=>{ setOpenCancel(false)}}
         />
      </>
    )
}

export default Subscription