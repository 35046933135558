import types from './types'

const INITIAL_STATE = {
    countries: [],
    usa_states: [],

    step_1_valid: false,
    step_2_valid: false,
    hosted_page_id: false,
    
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    password: '',
    password_confirmation: '',

    company_name: '',
    country: '840',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    province: '',
    zip: '',
    company_email: '',
    company_phone: '',
    url: '',
    ein: '',
    gs1_prefix: '',

}

const registerReducer = (state = INITIAL_STATE, action) =>{    
    switch (action.type){        
        case types.REGISTER_RESET: { return INITIAL_STATE }

        case types.REGISTER_SET_COUNTRIES: { 

            const {countries} = action

            return {
                ...state,
                countries,
            }
        }

        case types.REGISTER_SET_USA_STATES: { 

            const {usa_states} = action

            return {
                ...state,
                usa_states,
            }
        }

        case types.REGISTER_SET_USER: { 

            const { user } = action

            return {
                ...state,
                ...user,
                step_1_valid: true
            }
        }

        case types.REGISTER_STEP_1_INVALID: { 
            
            return {
                ...state,                
                step_1_valid: false
            }
        }

        case types.REGISTER_SET_COMPANY: { 

            const { company } = action

            return {
                ...state,
                ...company,
                step_2_valid: true
            }
        }

        case types.REGISTER_STEP_2_INVALID: { 
            
            return {
                ...state,                
                step_2_valid: false
            }
        }

        case types.REGISTER_SET_HOSTED_PAGE: { 
            const { hosted_page_id } = action
            
            return {
                ...state,                
                hosted_page_id
            }
        }

        default: return state        
    }
}


export default registerReducer;