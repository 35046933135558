import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import authActions from '@app/store/auth/actions'
import { postQboSyncOptions } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* sagaPostQboSyncOptions({company_id, callback, payload }){
    yield put(generalActions.isLoading())
    try{              
      const response = yield call(postQboSyncOptions, company_id, payload);      
      yield put(authActions.updateCompany(response.data.data))
      if(callback) callback(response.data.data)
      yield put(generalActions.setSuccess('Quickbooks sync options successfully updated.'))
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default sagaPostQboSyncOptions;