import types from './types'

const INITIAL_STATE = {
    parameters: [],
}

const parametersReducer = (state = INITIAL_STATE, action) =>{    
    switch (action.type){
        
        case types.ADMIN_PARAMETERS_RESET: { return INITIAL_STATE }
        default: return state

        case types.ADMIN_PARAMETERS_SET: {
            return {
                ...state,
                parameters: action.parameters
            }
        }
    }
}


export default parametersReducer;