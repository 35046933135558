import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import { postGoogle2FA } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* Google2FA({ google_2fa_code }){
    yield put(generalActions.isLoading())
    try{              
      yield call(postGoogle2FA, google_2fa_code);
      window.location.href='/logout'
    }catch(err){
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default Google2FA;