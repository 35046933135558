import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import adminSubscriptionActions from '@app/store/admin_subscription/actions'
import { getSubscriptions } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* sagaGetSubscriptions(){
    yield put(generalActions.isLoading())
    try{              
      const response = yield call(getSubscriptions)      
      yield put(adminSubscriptionActions.setSubscriptions(response.data.data))
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default sagaGetSubscriptions;