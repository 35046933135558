import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import authActions from '@app/store/auth/actions'
import errorParser from '@app/util/laravel_error_parser';
import { getUser } from './api'
import axios from 'axios'

function* sagaGetUserWithToken({ token, session_id }){
    yield put(generalActions.isLoading())
    try{      
        yield put(authActions.hideGoogleOneTap());
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        axios.defaults.headers.common['x-v2f'] = false;
        const response = yield call(getUser)
        const user = response.data.data        
        yield put(authActions.setUser(user,token, session_id));
        
    }catch(err){
      yield put(authActions.showGoogleOneTap());
      const parsed_message = errorParser(err)      
      if(parsed_message.search('403')>-1){
        yield put(generalActions.isNotLoading())
        yield put(authActions.withSingleSessionWarning())
      }else{
        yield put(generalActions.setError(parsed_message))
      }
      
    }
}

export default sagaGetUserWithToken;