import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import { postForgotPassword } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* forgotPassword(action){    
    yield put(generalActions.isLoading())
    try{              
      const response = yield call(postForgotPassword, action);      
      yield put(generalActions.setSuccess(response.data.message))
      if(action.callback){
        action.callback()
      }
    }catch(err){
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default forgotPassword;