import Echo from 'laravel-echo';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Pusher from 'pusher-js'
import {dispatch, authActions} from '@app/store'

const useBroadcast =  () => {
    const { token, user} = useSelector(state=>state.auth)

    useEffect(()=>{        
        if(token && user && user.email){            
            doPusher(token, user)
        }
        return ()=>{
            if(window.Echo){                
                window.Echo.leave(getChannelName(user))
                window.Echo.disconnect()
            }            
        }
    },[token, user])
}



const doPusher = (token, user)=>{
    let pusher = {}

    switch(process.env.REACT_APP_ENV){
        default:
            pusher.key = process.env.REACT_APP_PUSHER_KEY_LOCAL
            pusher.cluster = process.env.REACT_APP_PUSHER_CLUSTER_LOCAL
            pusher.tls = process.env.REACT_APP_PUSHER_FORCE_TLS_LOCAL === 'true' ? true : false
            pusher.authEndpoint = process.env.REACT_APP_PUSHER_AUTH_END_POINT_LOCAL
            pusher.authHost = process.env.REACT_APP_PUSHER_AUTH_HOST_LOCAL
            pusher.wsHost = process.env.REACT_APP_PUSHER_WS_HOST_LOCAL
            pusher.wsPort = process.env.REACT_APP_PUSHER_WS_PORT_LOCAL        
    }
    

    const options = {
        broadcaster: 'pusher',
        key: pusher.key,
        cluster: pusher.cluster,
        forceTLS: false,        
        authEndpoint: pusher.authEndpoint,
        wsHost: pusher.wsHost,
        wsPort: pusher.wsPort,    
        auth: {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            },
        },
    };

    window.Echo = new Echo(options)

    window.Echo
        .private(getChannelName(user))
        .notification(({
            type
        }) => {            
            switch(type){
                case 'close-previous-sessions':
                    dispatch(authActions.sagaPing())
                    break;
            }
        })        
}


const getChannelName = (_user) =>{
    return `users.${_user.id}`
}


export default useBroadcast