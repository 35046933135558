import types from './types'
import { PURGE, REHYDRATE } from 'redux-persist';
import axios from 'axios'

const INITIAL_STATE = {
    user: false,
    token: false,
    single_session_warning: false,
    session_id: false,
    companies: [],
    company_default: {},
    subscription: {
        json_data: [{ 
            
            id: '', 
            status: '', 
            trial_start: '', 
            trial_end: '', 
            subscription_items: '', 
            meta_data: {
                allowed_users: 0
            }, 
            currency_code: '',
            subscription_items: []
        }]
    },
    addons: [],
    users:[],
    show_google_one_tap: true
    
}

const authReducer = (state = INITIAL_STATE, action) =>{
    
    switch (action.type){

        case types.RESET: { return INITIAL_STATE }

        case REHYDRATE:    {// This added just to show that this action type also exists, can be omitted. 
            if(action.payload){
                axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.auth.token}`;
                if(!action.payload.auth.user.google_2fa_ask_otp) axios.defaults.headers.common['x-v2f'] = true;
            }
            
            //console.log("REHYDRATING!!!!");            
            return state;
        }

        case PURGE: {
            //console.log("PURGING!!!!"); 
            return INITIAL_STATE;    // Return the initial state of this reducer to 'reset' the app 
        }

        case types.SET_USER: {
            const { user, token=state.token, session_id=state.session_id } = action
            return {
                ...state,               
                user,
                token,
                session_id,
                show_google_one_tap: false
            }
        }

        case types.WITH_SINGLE_SESSION_WARNING: {
            return {
                ...state,
                single_session_warning: true
            }
        }

        case types.WITHOUT_SINGLE_SESSION_WARNING: {
            return {
                ...state,
                single_session_warning: false
            }
        }

        case types.SET_COMPANIES: {
            return {
                ...state,
                companies: action.companies
            }
        }

        case types.SET_COMPANY_DEFAULT: {
            return {
                ...state,
                company_default: action.company_default
            }
        }

        case types.PROFILE_SET_SUBSCRIPTIONS: {
            return {
                ...state,
                subscription: action.subscription
            }
        }

        case types.PROFILE_SET_ADDONS: {
            return {
                ...state,
                addons: action.addons
            }
        }

        case types.PROFILE_SET_USERS: {
            return {
                ...state,
                users: action.users
            }
        }

        case types.PROFILE_RESET_SUBSCRIPTION: {
            return {
                ...state,
                subscription: INITIAL_STATE.subscription
            }
        }

        case types.PROFILE_PUT_USER: {

            const users = [...state.users]
            const index = users.findIndex( e => e.user_id === action.user.user_id)            
            users[index] = action.user

            return {
                ...state,
                users
            }
        }

        case types.PROFILE_HIDE_GOOGLE_ONE_TAP: {

            return {
                ...state,
                show_google_one_tap: false
            }
        }

        case types.PROFILE_SHOW_GOOGLE_ONE_TAP: {

            return {
                ...state,
                show_google_one_tap: true
            }
        }

        case types.PROFILE_UPDATE_COMPANY: {

            const companies = [...state.companies]
            const index = companies.findIndex( e => e.id === action.company.id)
            companies[index] = action.company

            return {
                ...state,
                companies
            }
        }

        
        default: return state
    }
}


export default authReducer;