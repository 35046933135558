import types from './types'

const reset = () => {
    return {
        type: types.SUBSCRIPTION_RESET,
    }
}

const create = (item_price_id, callback) => {
    return {
        type: types.SUBSCRIPTION_SAGA_CREATE,
        item_price_id,
        callback
    }
}



const actions = {        
    reset,
    create
};

export default actions;