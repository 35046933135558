import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import adminSubscriptionActions from '@app/store/admin_subscription/actions'
import { syncSubscription } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* sagaGetSyncSubscription({subscription_id}){
    yield put(generalActions.isLoading())
    try{              
      const response = yield call(syncSubscription, subscription_id)
      //yield put(generalActions.setSuccess(response.data.message))            
      yield put(adminSubscriptionActions.updateSubscription(response.data.data))
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }finally{
      yield put(generalActions.isNotLoading())
    }
}

export default sagaGetSyncSubscription;