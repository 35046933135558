import { takeLatest, all } from "redux-saga/effects";
import types from "../types";

import sagaGetParameters from "./sagaGetParameters";
import sagaPutParameter from "./sagaPutParameter";

//Watchers
function* watchGetList() {
    yield takeLatest(types.ADMIN_PARAMETERS_SAGA_GET, sagaGetParameters);
    yield takeLatest(types.ADMIN_PARAMETERS_SAGA_UPDATE, sagaPutParameter);
}
  
  /**
   * Exporting all watchers
   */
export default function* rootSaga() {
    yield all([watchGetList()]);
}