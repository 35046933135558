
//import { Loading } from "@app/components/util"
import { dispatch, adminSubscriptionActions } from '@app/store'
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from 'react'
import { PlusSmIcon as PlusSmIcon, LinkIcon } from '@heroicons/react/solid'


import FreeUsers from "@app/components/modals/FreeUsers";
import { useCheckIfAdmin } from "@app/hooks";
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router'
import FilterBox from '@app/components/util/FilterBox';
import SubscriptionStatus from '@app/components/util/SubscriptionStatus';
import BindCompany from '@app/components/modals/BindCompany';

const Customers = () => {

    const history = useHistory()
     
    const [openModal, setOpenModal] = useState(false)
    const [openBind, setOpenBind] = useState(false)

    const handleModal=(index) => {
        dispatch(adminSubscriptionActions.modalAllowedUsers(index))
    }

    const { subscriptions=[] } = useSelector(state=> state.admin_subscription)

    useCheckIfAdmin();

    useEffect(()=>{
        dispatch(adminSubscriptionActions.sagaGetSubscriptions())
    },[])


    

    const columns = [
        /*{
            name: 'ID',
            selector: row => row.provider_customer_id,
            sortable: true,
            cell: (row, index, column, id) => {
                return <div className="py-4 whitespace-nowrap-- text-xs text-gray-500 text-left">{row.provider_customer_id}</div>
            }
        },*/

        {
            name: 'CUSTOMER',
            selector: row => row.name,
            width: '200px',
            sortable: true,
            cell: (row, index, column, id) => {
                return (
                    <>
                        <div className="grid-rows-1">
                            <div onClick={()=>history.push(`/panel/admin/subscription/${row.provider_customer_id}/edit`)} className="cursor-pointer py-4 whitespace-nowrap-- text-md text-blue-500 text-left">{row.name}</div>
                            {/*<SubscriptionStatus status={row.provider_status} />*/}
                            {
                                row.json_data.map(subscription=><div key={subscription.id}>
                                    {
                                        subscription.subscription_items.map(item=> <div key={item.id} className="mt-2 mb-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-200 text-gray-800">{item.item_price_id}</div>)
                                    }
                                </div>)
                            }

                            {/*<div>{row.json_data[0].subscription_items.map(({item_price_id}) => <div key={item_price_id} className="mt-2 mb-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-800">{item_price_id}</div>)}</div>*/}
                        </div>
                        
                    </>
                )
            }
        },
        

        /*{
            name: 'PLAN ITEMS',
            selector: row => row.json_data.subscription_items,
            sortable: true,
            cell: (row, index, column, id) => {
                return <div>{row.json_data.subscription_items.map(({item_price_id}) => <div key={item_price_id} className="mt-2 mb-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-800">{item_price_id}</div>)}</div>
            }
        },*/

        {
            name: 'LOCATIONS',
            selector: row => row.companies,
            sortable: true,
            cell: (row, index, column, id) => {
                return <div>
                    {row.companies.map(({id, name}) => <div key={id} onClick={()=>history.push(`/panel/admin/subscription/${row.id}/${id}/company`)} className="cursor-pointer mt-2 mb-2 inline-flex- items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-blue-100 text-green-800">{name} ({id})</div>)}
                    <div>
                    <button
                        type="button"
                        onClick={()=>history.push(`/panel/admin/subscription/${row.id}/new_company`)}
                        className="mb-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        <PlusSmIcon className="h-4 w-4" aria-hidden="true" />
                    </button>

                    <button
                        type="button"
                        onClick={()=>setOpenBind(row)}
                        className="ml-1 mb-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        <LinkIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                    </div>
                </div>
            }
        },

        {
            name: 'PLAN',
            selector: row => row.subscription_users,
            sortable: true,
            width: '150px',
            cell: (row, index, column, id) => {
                return ( <div className="grid-rows-1">
                    <div className="py-0 whitespace-nowrap-- text-xs text-gray-500 text-left w-full">Users: {row.subscription_users}</div>
                    <div className="py-4 whitespace-nowrap-- text-xs text-gray-500 text-left w-full">Locations: {row.subscription_companies}</div>
                </div>)
            }
        },

        {
            name: 'FREE',
            selector: row => row.free_users,
            sortable: true,
            width: '150px',
            cell: (row, index, column, id) => {
                return (<div className="grid-rows-1">
                    <div onClick={()=>setOpenModal(row)} className="cursor-pointer py-0 whitespace-nowrap-- text-xs text-blue-500 text-left w-full">Users: {row.free_users}</div>
                    <div onClick={()=>setOpenModal(row)} className="cursor-pointer py-4 whitespace-nowrap-- text-xs text-blue-500 text-left w-full">Locations: {row.free_companies}</div>
                </div>)
            }
        },

        
        {
            name: 'CREATED',
            selector: row => row.created_at,
            sortable: true,
            cell: (row, index, column, id) => {
                return <div className="py-4 whitespace-nowrap-- text-xs text-gray-500 text-left">{row.created_at}</div>
            }
        },
        
    ]
    
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredSubscriptions = subscriptions.filter(
		({ name: subscription_name, companies=[], json_data }) => (subscription_name && subscription_name.toLowerCase().includes(filterText.toLowerCase())) 
                              || ( companies.filter(({ name: company_name})=> company_name.toLowerCase().includes(filterText.toLowerCase()) ) ).length >0
                              //|| ( json_data && json_data.subscription_items && json_data.subscription_items.filter(({ item_price_id })=> item_price_id.toLowerCase().includes(filterText.toLowerCase()) ) ).length >0
        ,
	);

    const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterBox
                onFilter={e => setFilterText(e.target.value)} 
                onClear={handleClear}
                filterText={filterText}                 
                />
		);
	}, [filterText, resetPaginationToggle]);

    return (
        <>
            
            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <DataTable
                            columns={columns}
                            data={filteredSubscriptions}
                            pagination
                            striped
                            defaultSortAsc={false}
                            defaultSortField={'name'}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            noHeader={true}
                        />
                    </div>
                    </div>
                </div>
            </div>
            <FreeUsers
                subscription={openModal}
                close={()=>setOpenModal(false)}
             />
             <BindCompany
                subscription={openBind}
                closeModal={()=>setOpenBind(false)}
             />
        </>    
    )

}

export default Customers