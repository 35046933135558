import ForgotPasswordLayout from "@app/components/layout/ForgotPasswordLayout";
import { useForm } from "react-hook-form";
import { FormInput, ActionButton } from '@app/components/form';
import { Message } from '@app/components/util';
import { dispatch, authActions } from '@app/store'

const ForgotPasswordSendLink = () => {

  const {
    register,
    handleSubmit,    
    formState: { 
      errors,
      isValid,      
    },
    reset
  } = useForm({ mode: 'onChange' });  

  const onSubmit = ({email}) => {
    const callback = ()=>{
      reset()
    }
    dispatch(authActions.sagaSendRecoverLink(email, callback));    
  }

    return (
        <ForgotPasswordLayout title='Recover your access'>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>            
              <div>
                <FormInput
                    field="email"
                    errors={errors}
                    register={register}
                    required={true}
                    >
                    Email address
                </FormInput>
              </div>
  
              <div>
                <ActionButton
                    type="submit"
                    isValid={isValid}
                    testid="send-recover-link"
                >
                    Send recover link
                </ActionButton>
              </div>

              <Message />

            </form>
        </ForgotPasswordLayout>
    )
}

export default ForgotPasswordSendLink