import types from './types'

const reset = () => {
    return {
        type: types.ADMIN_SUBSCRIPTION_RESET,
    }
}

const sagaGetPlans = ()=>{
    return {
        type: types.ADMIN_SUBSCRIPTION_SAGA_GET_PLANS,        
    }
}

const setPlans = (plans)=>{
    return {
        type: types.ADMIN_SUBSCRIPTION_SET_PLANS,
        plans
    }
}

const modalAllowedUsers = (current_plan_index)=>{
    return {
        type: types.ADMIN_SUBSCRIPTION_MODAL_ALLOWED_USERS,
        current_plan_index
    }
}

const sagaAllowedUsers = (allowed_users, allowed_companies, qbo=false, qbd=false)=>{
    return {
        type: types.ADMIN_SUBSCRIPTION_SAGA_ALLOWED_USERS,
        allowed_users,
        allowed_companies,
        qbo,
        qbd
    }
}

const updateAllowedUsers = (plan)=>{
    return {
        type: types.ADMIN_SUBSCRIPTION_UPDATE_ALLOWED_USERS,
        plan
    }
}

const sagaGetSubscriptions = () => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SAGA_GET_SUBSCRIPTIONS,        
    }
}

const setSubscriptions = (subscriptions) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SET_SUBSCRIPTIONS,
        subscriptions
    }
}

const sagaPutFreeUsers = (id, free_users, free_companies, callback) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SAGA_PUT_FREE_USERS,
        id,
        free_users,
        free_companies,
        callback
    }
}

const setFreeUsers = (id, free_users, free_companies) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SET_FREE_USERS,
        id,
        free_users,
        free_companies
    }
}

const getCompanies = () => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SAGA_GET_COMPANIES,        
    }
}

const setCompanies = (companies) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SET_COMPANIES,
        companies
    }
}

const sagaSync = (subscription_id) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SAGA_SYNC,
        subscription_id
    }
}

const updateSubscription = (subscription) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_UPDATE,
        subscription
    }
}

const sagaPause = (subscription_id, payload, callback) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SAGA_PAUSE,
        subscription_id,
        payload,
        callback
    }
}

const sagaRemoveScheduledPause = (subscription_id) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SAGA_REMOVE_SCHEDULED_PAUSE,
        subscription_id
    }
}

const sagaResume = (subscription_id, payload, callback) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SAGA_RESUME,
        subscription_id,
        payload,
        callback
    }
}

const sagaCancel = (subscription_id, payload, callback) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SAGA_CANCEL,
        subscription_id,
        payload,
        callback
    }
}

const sagaRemoveScheduledCancellation = (subscription_id) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SAGA_REMOVE_SCHEDULED_CANCELLATION,
        subscription_id
    }
}

const sagaReactivate = (subscription_id) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SAGA_REACTIVATE,
        subscription_id
    }
}

const sagaBind = (subscription_id, company_id, callback) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_SAGA_BIND,
        subscription_id,
        company_id,
        callback
    }
}

const sagaGetLog = () => {
    return {
        type: types.ADMIN_SAGA_GET_LOG,        
    }
}

const setLog = (log) => {
    return {
        type: types.ADMIN_SET_LOG,
        log
    }
}

const updateCompany = (company) => {
    return {
        type: types.ADMIN_SUBSCRIPTION_UPDATE_COMPANY,
        company
    }
}

const actions = {    
    sagaGetPlans,
    setPlans,
    reset,
    modalAllowedUsers,
    sagaAllowedUsers,
    updateAllowedUsers,
    sagaGetSubscriptions,
    setSubscriptions,
    sagaPutFreeUsers,
    setFreeUsers,
    getCompanies,
    setCompanies,
    sagaSync,
    updateSubscription,
    sagaPause,
    sagaRemoveScheduledPause,
    sagaResume,
    sagaCancel,
    sagaRemoveScheduledCancellation,
    sagaReactivate,
    sagaBind,
    sagaGetLog,
    setLog,
    updateCompany
};

export default actions;