import Plan from "@app/containers/profile/Plan"
import { useRegisterSteps } from "@app/hooks";
import { useEffect, useState } from "react";
import ModalMessage from "@app/components/modals/ModalMessage";
import { dispatch, registerActions } from "@app/store";
import { useQuery } from "@app/hooks";

export default function Step_03() {
    useRegisterSteps();    
    const query = useQuery();
    const [open, setOpen] = useState(false)    

    useEffect(()=>{
        const id = query.get('id')
        const state = query.get('state')
        
        if(id && state==='succeeded'){
            dispatch(registerActions.setHostedPage(id))
        }

        if(id && state!=='succeeded'){
            setOpen(true)
        }

    },[query])

    return (
        <>
            <Plan
                marginTop=''
            />

            <ModalMessage 
                open={open}
                setOpen={setOpen}
                type='error'
                title='Subscription failed'
                message={(<>There was an issue with your subscription process or your payment. Please try it again. If you continue with problems please send us an email: <span className="text-blue-500">support@carinstack.com</span></>)}
            />
        </>
    )
}