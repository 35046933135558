import types from './types'

const reset = () => {
    return {
        type: types.REGISTER_RESET,
    }
}

const getCountries = () => {
    return {
        type: types.REGISTER_SAGA_GET_COUNTRIES,        
    }
}

const setCountries = (countries) => {
    return {
        type: types.REGISTER_SET_COUNTRIES,
        countries
    }
}

const getUsaStates = () => {
    return {
        type: types.REGISTER_SAGA_GET_USA_STATES,        
    }
}

const setUsaStates = (usa_states) => {
    return {
        type: types.REGISTER_SET_USA_STATES,
        usa_states
    }
}

const setUser = (user) => {
    return {
        type: types.REGISTER_SET_USER,
        user
    }
}

const setCompany = (company) => {
    return {
        type: types.REGISTER_SET_COMPANY,
        company
    }
}

const step1Invalid = () => {
    return {
        type: types.REGISTER_STEP_1_INVALID,
    }
}

const step2Invalid = () => {
    return {
        type: types.REGISTER_STEP_2_INVALID,
    }
}

const step3Invalid = () => {
    return {
        type: types.REGISTER_STEP_3_INVALID,
    }
}

const validateUser = (payload, callback) => {
    return {
        type: types.REGISTER_SAGA_VALIDATE_USER,
        payload,
        callback
    }
}

const validateCompany = (payload, callback) => {
    return {
        type: types.REGISTER_SAGA_VALIDATE_COMPANY,
        payload,
        callback
    }
}

const setHostedPage = (hosted_page_id) => {
    return {
        type: types.REGISTER_SET_HOSTED_PAGE,
        hosted_page_id
    }
}

const confirmSubscription = (subscription_id) => {
    return {
        type: types.REGISTER_SAGA_CONFIRM_SUBSCRIPTION,
        subscription_id
    }
}



const actions = {        
    reset,
    getCountries,
    setCountries,
    getUsaStates,
    setUsaStates,
    setUser,
    setCompany,
    step1Invalid,
    step2Invalid,
    step3Invalid,
    validateUser,
    validateCompany,
    setHostedPage,
    confirmSubscription
};

export default actions;