import Link from "@app/components/util/Link";
import { useConfig } from "@app/hooks";

export default function ForgotPasswordLayout({
  children,
  title
}) {

  const {     
    logo,
    theme 
  } = useConfig()
  
  return (
    <div className={`min-h-screen bg-${theme}-logo flex flex-col justify-center py-12 sm:px-6 lg:px-8`}>
      <div className="sm:mx-auto sm:w-full sm:max-w-md ">
        <div className="flex flex-col justify-center items-center">
          <Link endpoint='/'>

          <img
            className={`h-24 w-auto`}
            src={process.env.PUBLIC_URL + `/${theme}.png`}
            alt="Cairnstack"
          />

          </Link>
        </div>
        
        <h2 className={`mt-6 text-center text-3xl font-extrabold text-${theme}-logo-over`}>{title}</h2>
        <p className={`mt-2 text-center text-sm text-${theme}-logo-over`}>
          Or{' '}
          <Link endpoint='/'>sign in</Link>
          
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {children}                      
        </div>
      </div>
    </div>
  )
}
  