
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage_session from "redux-persist/lib/storage/session";
import storage_local from "redux-persist/lib/storage"; /*Local storage */
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import rootReducer from "@app/store/root-reducer";
import { generalActions, generalSagas } from "@app/store/general";
import { authActions, authSagas } from "@app/store/auth";
import { adminSubscriptionActions, adminSubscriptionSagas } from "@app/store/admin_subscription";
import { adminParametersActions, adminParametersSagas } from "@app/store/admin_parameters";
import { subscriptionActions, subscriptionSagas } from "@app/store/subscription";
import { registerActions, registerSagas } from "@app/store/register";


const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

let storage = storage_local;
if(process.env.REACT_APP_STORAGE ==='session') storage = storage_session;

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['general']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  {},
  composeWithDevTools(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);
//persistor.purge();
sagaMiddleware.run(generalSagas);
sagaMiddleware.run(authSagas);
sagaMiddleware.run(adminSubscriptionSagas);
sagaMiddleware.run(adminParametersSagas);
sagaMiddleware.run(subscriptionSagas);
sagaMiddleware.run(registerSagas);
//sagaMiddleware.run(ratesSagas);
/**
 * run more sagas here
*/

/**
 * Dispatch without instance a hook dispatch
*/
const dispatch = (callback)=>{
  store.dispatch(callback)
}


export default store;

export {
  dispatch,
  persistor,  
  generalActions,
  authActions,
  adminSubscriptionActions,
  adminParametersActions,
  subscriptionActions,
  registerActions
};

