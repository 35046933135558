import { goToApplication } from '@app/util/functions'
import { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component';
import FilterBox from '@app/components/util/FilterBox';
import { useHistory } from 'react-router';
import { ActionButton } from "@app/components/form";
import { dispatch, authActions } from "@app/store";

const UsersTable = ({
    users=[],
    company_id,
    session_id,
    has_valid_subscription=false
})=>{

  const history=useHistory()

  const columns = [
        
        {
          name: 'NAME',
          selector: row => row.user ? row.user.first_name : '',
          sortable: true,          
          cell: (row, index, column, id) => {
            return <div className="cursor-pointer py-4 whitespace-nowrap-- text-md text-gray-500 text-left">{row.user ? row.user.first_name : ''} {row.user ? row.user.last_name : ''}</div>
          }
        },

        {
          name: 'EMAIL',
          selector: row => row.user ? row.user.email : '',
          sortable: true,
          cell: (row, index, column, id) => {
            return <div className="cursor-pointer py-4 whitespace-nowrap-- text-md text-gray-500 text-left">{row.user ? row.user.email : ''}</div>
          }
        },   
        

        {
          name: 'STATUS',
          selector: row => row.active,
          width:'140px',
          sortable: true,
          cell: (row, index, column, id) => {
            return <div className="cursor-pointer py-4 whitespace-nowrap-- text-md text-gray-500 text-left">

              {
                row.active ? (
                  <ActionButton
                    className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                    classNameEnabled="focus:ring-green-500 bg-green-600 hover:bg-green-700"
                    classNameDisabled="focus:ring-gray-200 bg-gray-300 hover:bg-gray-200"
                    onClick={()=> dispatch(authActions.disableUser(company_id, row.user_id)) }
                  >Enabled</ActionButton>
                ) : (
                  <ActionButton
                    className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                    classNameEnabled="focus:ring-red-500 bg-red-600 hover:bg-red-700"
                    classNameDisabled="focus:ring-gray-200 bg-gray-300 hover:bg-gray-200"
                    onClick={()=> dispatch(authActions.enableUser(company_id, row.user_id)) }
                  >Disabled</ActionButton>
                )
              }
            </div>
          }
        },

        {
          name: 'PERMISSIONS',
          width: '130px',
          selector: row => row.license,
          sortable: false,
          cell: (row, index, column, id) => {

            return has_valid_subscription ? (
              <div 
                onClick={()=> goToApplication(session_id, company_id, row.user_id)}
                className="cursor-pointer py-4 whitespace-nowrap-- text-md text-blue-500 w-full justify-center content-center flex">
                
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </div>
            ) : null
            
          }
        },   

                           
    ]

    


    /*columns.push({
      name: '',
      selector: row => row.id,
      width: '100px',
      
      sortable: true,
      cell: (row, index, column, id) => {
        return  <div className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    type="button"
                    className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={()=>handleImpersonate(row.id)}
                    >
                    Login
                    </button>
                  </div>
      }
  })*/

    
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredUsers = users.filter(
		  ({ user_id, user }) => 
          (user_id && String(user_id).toLowerCase().includes(filterText.toLowerCase())) 
        ||(user && user.first_name.toLowerCase().includes(filterText.toLowerCase())) 
        ||(user && user.last_name.toLowerCase().includes(filterText.toLowerCase())) 
        ,
	);

    const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterBox
                onFilter={e => setFilterText(e.target.value)} 
                onClear={handleClear}
                filterText={filterText}                 
                />
		);
	}, [filterText, resetPaginationToggle]);

    return (
      <DataTable
          columns={columns}
          data={filteredUsers}
          pagination
          striped
          defaultSortAsc={false}
          defaultSortField={'name'}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          noHeader={true}
      />
    )
}

export default UsersTable