import { call, put } from 'redux-saga/effects'
import generalActions from '@app/store/general/actions'
import { postValidateCompany } from './api'
import errorParser from '@app/util/laravel_error_parser';

function* validateCompany({payload, callback}){    
    try{                    
      yield call(postValidateCompany,payload)
      if(callback){
        callback()
      }
      
    }catch(err){      
      const parsed_message = errorParser(err)
      yield put(generalActions.setError(parsed_message))
    }
}

export default validateCompany;